/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useEffect, useState } from 'react';
import type { $MixedElement } from 'types';
import { MAIN_ABI, MAIN_ADDRESS } from 'utils/clagg';
import { isAddress } from 'viem';
import { useReadContract, useWriteContract } from 'wagmi';

export function AddAdapter(): $MixedElement {
    const [error, setError] = useState<string | null>(null);
    const [status, setStatus] = useState<string | null>(null);
    const [adapterToCheck, setAdapterToCheck] = useState<`0x${string}` | null>(
        null,
    );
    const [inputValue, setInputValue] = useState('');
    const [isValidAddress, setIsValidAddress] = useState(false);

    const {
        data: requestDate,
        isError: isReadError,
        isLoading: isReadLoading,
    } = useReadContract({
        address: MAIN_ADDRESS as `0x${string}`,
        abi: MAIN_ABI,
        functionName: 'addRequestDate',
        args: adapterToCheck ? [adapterToCheck] : undefined,
        query: {
            enabled: !!adapterToCheck,
        },
    });

    const {
        data: hash,
        writeContract,
        isPending,
        isError: isWriteError,
    } = useWriteContract({
        mutation: {
            onError(error) {
                console.error(error);
                setError(error.message);
                setStatus(null);
            },
            onSuccess() {
                setError(null);
                setInputValue('');
            },
        },
    });

    useEffect(() => {
        if (isReadError) {
            setError('Failed to check adapter status. Please try again.');
            setStatus(null);
        }
    }, [isReadError]);

    useEffect(() => {
        if (isWriteError) {
            setError('Transaction failed. Please try again.');
            setStatus(null);
        }
    }, [isWriteError]);

    useEffect(() => {
        if (!adapterToCheck) return;

        if (isReadLoading) {
            setStatus('Checking adapter status...');
            return;
        }

        if (requestDate !== undefined) {
            const currentTimestamp = BigInt(Math.floor(Date.now() / 1000));

            if (requestDate === 0n) {
                setStatus('Adapter needs to be requested first');
            } else if (requestDate < currentTimestamp) {
                setStatus('Adapter can be added now');
            } else {
                const timeLeft = Number(requestDate - currentTimestamp);
                const hours = Math.floor(timeLeft / 3600);
                const minutes = Math.floor((timeLeft % 3600) / 60);
                setStatus(`Please wait ${hours}h ${minutes}m before adding`);
            }
        }
    }, [adapterToCheck, requestDate, isReadLoading]);

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        setInputValue(value);
        setError(null);
        setStatus(null);

        if (value && !isAddress(value)) {
            setError('Invalid Ethereum address format');
            setIsValidAddress(false);
        } else if (value) {
            setIsValidAddress(true);
        } else {
            setIsValidAddress(false);
        }
    }

    async function submit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        setError(null);

        if (!isAddress(inputValue)) {
            setError('Invalid Ethereum address format');
            return;
        }

        const adapterAddress = inputValue as `0x${string}`;
        setAdapterToCheck(adapterAddress);

        if (requestDate === undefined) {
            setStatus('Checking adapter status...');
            return;
        }

        const currentTimestamp = BigInt(Math.floor(Date.now() / 1000));
        try {
            if (requestDate === 0n) {
                setStatus('Requesting adapter...');
                writeContract({
                    address: MAIN_ADDRESS as `0x${string}`,
                    abi: MAIN_ABI,
                    functionName: 'requestAddAdapter',
                    args: [adapterAddress],
                });
            } else if (requestDate <= currentTimestamp) {
                setStatus('Adding adapter...');
                writeContract({
                    address: MAIN_ADDRESS as `0x${string}`,
                    abi: MAIN_ABI,
                    functionName: 'addAdapter',
                    args: [adapterAddress],
                });
            } else {
                const timeLeft = Number(requestDate - currentTimestamp);
                const hours = Math.floor(timeLeft / 3600);
                const minutes = Math.floor((timeLeft % 3600) / 60);
                setError(
                    `Please wait ${hours}h ${minutes}m before adding the adapter`,
                );
                setStatus(null);
            }
        } catch (err) {
            console.error(err);
            setError('Failed to process request. Please try again.');
            setStatus(null);
        }
    }

    return (
        <form onSubmit={submit} className="space-y-4">
            <div className="relative group">
                <input
                    name="address"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Enter adapter address (0x...)"
                    required
                    className={`w-full pl-10 pr-3 py-2 border rounded-lg transition-all duration-200 ${
                        error
                            ? 'border-red-300 bg-red-50 focus:ring-red-500 focus:border-red-500'
                            : isValidAddress
                            ? 'border-green-300 bg-green-50 focus:ring-green-500 focus:border-green-500'
                            : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                    } text-sm`}
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                        className={`h-4 w-4 transition-colors duration-200 ${
                            error
                                ? 'text-red-400'
                                : isValidAddress
                                ? 'text-green-400'
                                : 'text-gray-400'
                        }`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                        />
                    </svg>
                </div>
                {inputValue && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                        {error ? (
                            <svg
                                className="h-4 w-4 text-red-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        ) : isValidAddress ? (
                            <svg
                                className="h-4 w-4 text-green-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M5 13l4 4L19 7"
                                />
                            </svg>
                        ) : null}
                    </div>
                )}
            </div>

            {status && (
                <div className="p-4 bg-blue-50 text-blue-700 rounded-lg text-sm transform transition-all duration-200 hover:scale-[1.01]">
                    <div className="flex items-center">
                        <svg
                            className="w-5 h-5 mr-2 animate-pulse"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                        {status}
                    </div>
                </div>
            )}

            {error && (
                <div className="p-4 bg-red-50 text-red-700 rounded-lg text-sm transform transition-all duration-200 hover:scale-[1.01]">
                    <div className="flex items-center">
                        <svg
                            className="w-5 h-5 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                        {error}
                    </div>
                </div>
            )}

            <button
                type="submit"
                disabled={
                    isPending || isReadLoading || !!error || !isValidAddress
                }
                className={`w-full flex items-center justify-center px-4 py-2 rounded-lg transition-all duration-200 ${
                    isPending || isReadLoading || !!error || !isValidAddress
                        ? 'bg-blue-400 cursor-not-allowed'
                        : 'bg-blue-600 hover:bg-blue-700 transform hover:scale-[1.02]'
                } text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
            >
                {isPending || isReadLoading ? (
                    <>
                        <svg
                            className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            />
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                        </svg>
                        {status || 'Processing...'}
                    </>
                ) : (
                    <>
                        <svg
                            className="w-4 h-4 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                            />
                        </svg>
                        Add Adapter
                    </>
                )}
            </button>

            {hash && (
                <div className="flex items-start p-4 bg-green-50 text-green-700 rounded-lg break-all text-sm transform transition-all duration-200 hover:scale-[1.01]">
                    <svg
                        className="w-5 h-5 mr-2 flex-shrink-0 mt-0.5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    <div>
                        <div className="font-medium mb-1">
                            Transaction Successful
                        </div>
                        <div
                            className="text-green-600 text-xs font-mono hover:text-green-700 cursor-pointer"
                            onClick={async () =>
                                navigator.clipboard.writeText(hash)
                            }
                        >
                            {hash}
                            <span className="ml-2 text-xs text-green-500">
                                (Click to copy)
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </form>
    );
}
