/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { StatsData } from 'api/query/graph/useStatsQuery';
import { Buffer } from 'buffer';

export function bytesToDate(bytes: string): string {
    const timestamp = bytesToNumber(bytes);

    return new Date(timestamp * 1000).toDateString().slice(4);
}

export function bytesToNumber(bytes: string): number {
    // Ensure the hex string length is even for correct parsing
    if (bytes.length % 2 !== 0) {
        bytes = '0' + bytes;
    }

    const buffer = Buffer.from(bytes.slice(2), 'hex');
    return buffer.readUInt32LE(0);
}

export function getDays(
    days: Array<StatsData>,
    dateRange: [number, number],
): Array<StatsData> {
    return days.filter((day) => {
        return (
            bytesToNumber(day.id) >= dateRange[0] &&
            bytesToNumber(day.id) <= dateRange[1] + 86400
        );
    });
}
