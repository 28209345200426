/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { SelectProps } from 'antd';

export const PAGE_SIZE = 50;

export const getTagColor = (type: string): string => {
    const colors: Record<string, string> = {
        transfer: '#64748B', // Slate-500: Neutral, professional gray
        transfer_in: '#22C55E', // Green-500: Clear, positive green
        transfer_out: '#EF4444', // Red-500: Clear warning red
        invest: '#16A34A', // Green-600: Strong, trustworthy green
        swap: '#7C3AED', // Violet-600: Rich, engaging purple
        layerswap: '#0EA5E9', // Sky-500: Technical, modern blue
        guardian_change: '#EA580C', // Orange-600: Important, attention-grabbing
        onramp: '#D946EF', // Fuchsia-500: Distinct, vibrant pink
        offramp: '#F97316', // Orange-500: Warm, accessible orange
        peanut: '#EC4899', // Pink-500: Fun, playful pink
        referral: '#BE123C', // Rose-700: Deep, premium red
        unknown: '#CBD5E1', // Slate-300: Subtle, unobtrusive gray
    };
    return colors[type] || colors.unknown;
};

export const txTypeOptions: SelectProps['options'] = [
    { value: 'transfer', label: 'Transfers', color: getTagColor('transfer') },
    { value: 'invest', label: 'Investments', color: getTagColor('invest') },
    { value: 'swap', label: 'Swaps', color: getTagColor('swap') },
    { value: 'layerswap', label: 'Layerswap', color: getTagColor('layerswap') },
    {
        value: 'guardian_change',
        label: 'Guardian Changes',
        color: getTagColor('guardian_change'),
    },
    {
        value: 'onramp',
        label: '(On-Off)ramps',
        color: getTagColor('onramp'),
    },
    // { value: 'peanut', label: 'Peanut', color: getTagColor('peanut') }, broken
    // { value: 'referral', label: 'Referrals', color: getTagColor('referral') },
];

export const typeToTable = (type: string): string => {
    switch (type) {
        case 'transfer':
        case 'transfer_in':
        case 'transfer_out':
            return 'history_token_transfer';
        case 'invest':
            return 'history_invest';
        case 'swap':
            return 'history_swap';
        case 'layerswap':
            return 'history_layerswap';
        case 'guardian_change':
            return 'history_guardian_change';
        case 'onramp':
            return 'history_onramp';
        case 'peanut':
            return 'history_peanut';
        case 'referral':
            return 'history_referral';
        default:
            return '';
    }
};
