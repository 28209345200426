/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { CustomQueryResult } from 'api/types';
import { useEffect, useState } from 'react';
import { GraphQLClient } from 'utils/graphql';
import { claggDepositsQuery, claggWithdrawsQuery } from 'utils/graphql/queries';

export type ClaggTx = {
    id: string;
    user: string;
    pool: string;
    amount: string;
    shares: string;
    timestamp: string;
    type: 'deposit' | 'withdraw';
};

type ClaggDepositsResponse = {
    Clagg_Deposit: Array<Omit<ClaggTx, 'type'>>;
};

type ClaggWithdrawsResponse = {
    Clagg_Withdraw: Array<Omit<ClaggTx, 'type'>>;
};

export type ClaggTxsResult = {
    transactions: Array<ClaggTx>;
};

const defaultData: ClaggTxsResult = {
    transactions: [],
};

const client = new GraphQLClient<ClaggDepositsResponse>({
    endpoint: 'https://indexer.dev.hyperindex.xyz/3dea3d1/v1/graphql',
    pagination: {
        enabled: true,
    },
    defaultData: { Clagg_Deposit: [] },
});

const clientWithdraws = new GraphQLClient<ClaggWithdrawsResponse>({
    endpoint: 'https://indexer.dev.hyperindex.xyz/3dea3d1/v1/graphql',
    pagination: {
        enabled: true,
    },
    defaultData: { Clagg_Withdraw: [] },
});

export const useClaggTxs = (): CustomQueryResult<ClaggTxsResult> => {
    const [result, setResult] = useState<ClaggTxsResult>(defaultData);

    const queryResult = client.useQuery({
        gql: claggDepositsQuery,
        gqlVariables: {},
        queryKey: ['clagg-deposits'],
    });

    const queryResultWithdraws = clientWithdraws.useQuery({
        gql: claggWithdrawsQuery,
        gqlVariables: {},
        queryKey: ['clagg-withdraws'],
    });

    useEffect(() => {
        const deposits = (queryResult.data.Clagg_Deposit ?? []).map((tx) => ({
            ...tx,
            type: 'deposit' as const,
        }));
        const withdraws = (queryResultWithdraws.data.Clagg_Withdraw ?? []).map(
            (tx) => ({
                ...tx,
                type: 'withdraw' as const,
            }),
        );

        const transactions = [...deposits, ...withdraws].sort((a, b) => {
            const timestampA = BigInt(a.timestamp);
            const timestampB = BigInt(b.timestamp);
            return timestampB > timestampA ? 1 : -1;
        });

        setResult({ transactions });
    }, [queryResult.data, queryResultWithdraws.data]);

    return {
        ...queryResult,
        data: result,
        promise: queryResult.promise.then(() => result),
    };
};
