/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export const claggDepositsQuery = `
  query ClaggDeposits($limit: Int, $lastID: String) {
    Clagg_Deposit(
      limit: $limit
      where: { id: { _gt: $lastID } }
      order_by: { id: asc }
    ) {
      id
      user
      pool
      amount
      shares
      timestamp
    }
  }
`;

export const claggWithdrawsQuery = `
  query ClaggWithdraws($limit: Int, $lastID: String) {
    Clagg_Withdraw(
      limit: $limit
      where: { id: { _gt: $lastID } }
      order_by: { id: asc }
    ) {
      id
      user
      pool
      amount
      shares
      timestamp
    }
  }
`;

export const claggSharesQuery = `
  query ClaggShares($limit: Int, $lastID: String) {
    AccountPosition(
      limit: $limit
      where: { id: { _gt: $lastID } }
      order_by: { id: asc }
    ) {
      id
      account
      deposited
      withdrawn
      shares
      pool
    }
  }
`;

export const IdleBalancesQuery = `
  query IdleBalances($limit: Int, $lastID: String, $offset: Int) {
    Account(
      limit: $limit
      where: { id: { _gt: $lastID } }
      order_by: { id: asc }
      offset: $offset
    ) {
      id
      address
      balance
      token {
        id
      }
    }
  }
`;

export const allUsersStatsQuery = `
    query AllUsersStats($limit: Int!, $lastID: Bytes, $offset: Int) {
        claveAccounts(first: $limit, where: { id_gt: $lastID }, skip: $offset) {
            id
            creationDate
            deployDate
            hasRecovery
            txCount
            transactions {
                gasCost
            }
            activeDays(first: $limit) {
                id
            }
            activeWeeks {
                id
            }
            activeMonths {
                id
            }
            earnPositions {
                pool
                token
                protocol
                invested
                compoundGain
                normalGain
            }
            referralFees {
                erc20
                amount
            }
            cashbacks {
                erc20
                amount
            }
        }
    }
`;

export const allSwapsQuery = `
    query AllSwaps($limit: Int!, $lastID: Bytes, $offset: Int) {
        inAppSwaps(first: $limit, where: { id_gt: $lastID }, skip: $offset) {
            id
            account {
                id
            }
            amountOut
            tokenIn
            tokenOut
            date
        }
    }
`;

export const statsQuery = `
    query Stats($limit: Int!, $lastID: Bytes) {
        days(first: $limit, where: { id_gt: $lastID }) {
            id
            createdAccounts
            deployedAccounts
            activeAccounts
            transactions
            gasSponsored
            swappedTo {
                erc20
                amount
            }
            investFlow {
                erc20
                protocol
                amountIn
                amountOut
                claimedGain
            }
        }
        weeks(first: $limit) {
            id
            createdAccounts
            deployedAccounts
            activeAccounts
            transactions
            gasSponsored
            swappedTo {
                erc20
                amount
            }
            investFlow {
                erc20
                protocol
                amountIn
                amountOut
                claimedGain
            }
        }
        months(first: $limit) {
            id
            activeAccounts
            createdAccounts
            deployedAccounts
            transactions
            gasSponsored
            swappedTo {
                erc20
                amount
            }
            investFlow {
                erc20
                protocol
                amountIn
                amountOut
                claimedGain
            }
        }
        total(id: "0x746f74616c") {
            createdAccounts
            deployedAccounts
            gasSponsored
            transactions
            backedUp
        }
    }
`;
