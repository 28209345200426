/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
    DeleteOutlined,
    DownOutlined,
    FilterOutlined,
    PlusOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Dropdown,
    Input,
    Select,
    Switch,
    Tooltip,
    Typography,
} from 'antd';
import { type ModalController } from 'hooks/useModalController';
import debounce from 'lodash/debounce';
import {
    type Dispatch,
    type SetStateAction,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import type { $MixedElement, AllStatsFilter } from 'types';
import { UserColumns } from 'utils';

// Memoize static options
const operatorOptions = [
    { value: 'eq', label: '=' },
    { value: 'gt', label: '>' },
    { value: 'lt', label: '<' },
    { value: 'neq', label: '!=' },
    { value: 'contains', label: 'contains' },
    { value: 'notContains', label: 'notContains' },
];

const columnOptions = UserColumns.map(({ key, title }) => ({
    value: key,
    label: title,
}));

const DEBOUNCE_DELAY = 300;

// Memoized header component to prevent re-renders
const FilterHeader = memo(
    ({
        onAddFilter,
        onConditionChange,
        onSave,
        showSave,
    }: {
        onAddFilter: (e: React.MouseEvent) => void;
        onConditionChange: (checked: boolean) => void;
        onSave: () => void;
        showSave: boolean;
    }) => (
        <div className="p-4 border-b flex justify-between space-x-4 items-center border-gray-200">
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={onAddFilter}
            >
                Add Filter
            </Button>
            <div className="flex items-center space-x-4">
                <Typography.Text>Condition:</Typography.Text>
                <Switch
                    checkedChildren="AND"
                    unCheckedChildren="OR"
                    defaultChecked
                    onChange={onConditionChange}
                    onClick={(_checked, e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                />
            </div>
            {showSave && (
                <Button onClick={onSave} icon={<SaveOutlined />} type="default">
                    Save Filters
                </Button>
            )}
        </div>
    ),
);

type FilterItemProps = {
    filter: AllStatsFilter;
    index: number;
    onUpdate: (
        index: number,
        field: keyof AllStatsFilter,
        value: string,
    ) => void;
    onDelete: (index: number) => void;
};

const FilterItem = memo(
    ({ filter, index, onUpdate, onDelete }: FilterItemProps) => {
        // Local state for input value to prevent re-renders of parent
        const [inputValue, setInputValue] = useState(filter.value);

        const debouncedUpdate = useRef(
            debounce((value: string) => {
                onUpdate(index, 'value', value);
            }, DEBOUNCE_DELAY),
        ).current;

        // Proper cleanup
        useEffect(() => {
            return (): void => {
                debouncedUpdate.cancel();
            };
        }, [debouncedUpdate]);

        const handleColumnChange = useCallback(
            (value: string) => onUpdate(index, 'column', value),
            [index, onUpdate],
        );

        const handleOperatorChange = useCallback(
            (value: string) => onUpdate(index, 'operator', value),
            [index, onUpdate],
        );

        const handleValueChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = e.target.value;
                setInputValue(newValue); // Update local state immediately
                debouncedUpdate(newValue); // Debounce the update to parent
            },
            [debouncedUpdate],
        );

        const handleDelete = useCallback(
            (e: React.MouseEvent) => {
                e.stopPropagation();
                onDelete(index);
            },
            [index, onDelete],
        );

        return (
            <div
                className="p-4 flex w-[100%] space-x-4 hover:bg-gray-50"
                onClick={(e) => e.stopPropagation()}
            >
                <Select
                    className="w-[100%]"
                    value={filter.column}
                    onChange={handleColumnChange}
                    options={columnOptions}
                />
                <Select
                    className="w-[100%]"
                    value={filter.operator}
                    onChange={handleOperatorChange}
                    options={operatorOptions}
                />
                <Input
                    className="w-[100%]"
                    value={inputValue}
                    onChange={handleValueChange}
                    placeholder="Value"
                />
                <Tooltip title="Remove filter">
                    <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={handleDelete}
                    />
                </Tooltip>
            </div>
        );
    },
);

type Props = {
    filters: Array<AllStatsFilter>;
    setFilters: Dispatch<SetStateAction<Array<AllStatsFilter>>>;
    setFilterCondition: Dispatch<SetStateAction<'AND' | 'OR'>>;
    saveFiltersController: ModalController;
};

export const AllStatsFilters = ({
    filters,
    setFilters,
    setFilterCondition,
    saveFiltersController,
}: Props): $MixedElement => {
    const handleAddFilter = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        const newFilter: AllStatsFilter = {
            column: 'username',
            value: '',
            operator: 'eq',
        };
        setFilters((prev) => [...prev, newFilter]);
    }, []);

    const handleFilterUpdate = useCallback(
        (index: number, field: keyof AllStatsFilter, value: string) => {
            setFilters((prev) => {
                const newFilters = [...prev];
                newFilters[index] = { ...newFilters[index], [field]: value };
                return newFilters;
            });
        },
        [],
    );

    const handleFilterDelete = useCallback((index: number) => {
        setFilters((prev) => prev.filter((_, i) => i !== index));
    }, []);

    const handleConditionChange = useCallback((checked: boolean) => {
        setFilterCondition(checked ? 'AND' : 'OR');
    }, []);

    // Memoize the filter items separately from the header
    const filterItems = useMemo(
        () =>
            filters.map((filter, index) => ({
                key: `filter-${index}`,
                label: (
                    <FilterItem
                        filter={filter}
                        index={index}
                        onUpdate={handleFilterUpdate}
                        onDelete={handleFilterDelete}
                    />
                ),
            })),
        [filters, handleFilterUpdate, handleFilterDelete],
    );

    const menuItems = [
        {
            key: 'addFilter',
            label: (
                <FilterHeader
                    onAddFilter={handleAddFilter}
                    onConditionChange={handleConditionChange}
                    onSave={saveFiltersController.open}
                    showSave={filters.length > 0}
                />
            ),
        },
        ...filterItems,
    ];

    return (
        <Col span={6}>
            <Typography.Text strong>Filters:</Typography.Text>
            <Dropdown
                menu={{
                    className: 'max-h-[400px] overflow-auto min-w-[768px]',
                    items: menuItems,
                }}
                trigger={['click']}
            >
                <Button
                    style={{ width: '100%', marginTop: 4 }}
                    icon={<FilterOutlined />}
                >
                    Manage Filters ({filters.length}) <DownOutlined />
                </Button>
            </Dropdown>
        </Col>
    );
};
