/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Segmented, Space, Spin } from 'antd';
import type { StatsResponse } from 'api/query/graph/useStatsQuery';
import { useGetUserStatsQuery } from 'api/query/useGetUserStatsQuery';
import { BarChart } from 'components/charts/BarChart';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    type BarChartData,
    type BarChartProps,
    ChartInterval,
    ChartMonth,
    ChartType,
    type LineChartData,
    type LineChartProps,
} from 'utils';

import { LineChart } from '../charts/LineChart';

export const NewAccounts = ({
    defaultChartInterval = ChartInterval.Weekly,
    isPreview = false,
}: {
    data: StatsResponse;
    isLoading: boolean;
    defaultChartInterval?: ChartInterval;
    isPreview?: boolean;
}): $MixedElement => {
    const [dateRange, setDateRange] = useState<[number, number]>([0, 0]);
    const [chartInterval, setChartInterval] =
        useState<ChartInterval>(defaultChartInterval);
    const [chartType, setChartType] = useState<ChartType>(ChartType.Line);
    const [month, setMonth] = useState<ChartMonth>(ChartMonth.THREE_MONTHS);

    const { data, isLoading } = useGetUserStatsQuery();

    const chartData = useMemo(() => {
        const monthsAgo = new Date();
        const monthNumber = Number(month.split(' ')[0]);
        monthsAgo.setMonth(monthsAgo.getMonth() - monthNumber);

        const oldUsers = data.users_by_date.filter(
            (item) => new Date(item.date) < monthsAgo,
        );

        const usersByDate = data.users_by_date.filter(
            (item) => new Date(item.date) >= monthsAgo,
        );

        const usersByWeek = usersByDate.reduce((acc, item) => {
            const date = new Date(item.date);
            const weekStart = new Date(
                date.setDate(date.getDate() - date.getDay()),
            );
            const weekKey = weekStart.toISOString().split('T')[0];

            if (!acc[weekKey]) {
                acc[weekKey] = { count: 0, date: weekStart.toString() };
            }
            acc[weekKey].count += Number(item.count);
            return acc;
        }, {} as Record<string, { count: number; date: string }>);

        const usersByMonth = usersByDate.reduce((acc, item) => {
            const date = new Date(item.date);
            const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
            const monthKey = monthStart.toISOString().split('T')[0];

            if (!acc[monthKey]) {
                acc[monthKey] = { count: 0, date: monthStart.toString() };
            }
            acc[monthKey].count += Number(item.count);
            return acc;
        }, {} as Record<string, { count: number; date: string }>);

        const formatDate = (date: string): string => {
            const pad = (n: number): string => (n < 10 ? `0${n}` : `${n}`);
            const _date = new Date(date);
            return `${pad(_date.getDate())}/${pad(_date.getMonth() + 1)}`;
        };

        const totalCountBeforeSixMonths = oldUsers.reduce(
            (acc, item) => acc + Number(item.count),
            0,
        );
        let totalCount = totalCountBeforeSixMonths;

        const cumulativeData = usersByDate.map((item, index) => {
            totalCount = totalCount + Number(item.count);
            return {
                x: formatDate(item.date),
                y: totalCount,
                key: index,
            };
        });

        const dailyData = usersByDate.map((item, index) => {
            return {
                x: formatDate(item.date),
                y: item.count,
                key: index,
            };
        });

        const weeklyData = Object.values(usersByWeek).map((item, index) => {
            return {
                x: formatDate(item.date),
                y: item.count,
                key: index,
            };
        });

        const monthlyData = Object.values(usersByMonth).map((item, index) => {
            return {
                x: formatDate(item.date),
                y: item.count,
                key: index,
            };
        });

        return {
            [ChartInterval.Cumulative]: [
                {
                    id: 'users',
                    color: 'hsl(184, 70%, 50%)',
                    data: cumulativeData,
                },
            ],
            [ChartInterval.Daily]: [
                {
                    id: 'users',
                    data: dailyData,
                },
            ],
            [ChartInterval.Weekly]: [
                {
                    id: 'users',
                    data: weeklyData,
                },
            ],
            [ChartInterval.Monthly]: [
                {
                    id: 'users',
                    data: monthlyData,
                },
            ],
        };
    }, [data.users_by_date, month]);

    const lineProps: LineChartProps = {
        axisBottomLegend: 'Date',
        axisLeftLegend: 'Count',
        axisLeftTickValues: 20,
        axisLeftFormat: undefined,
        yFormat: undefined,
        margin: isPreview
            ? { top: 20, right: 50, bottom: 50, left: 50 }
            : { top: 40, right: 50, bottom: 100, left: 100 },
    };

    const barProps: BarChartProps<'count'> = {
        keys: ['count'],
        indexBy: 'date',
        axisBottomLegend: 'Date',
        axisLeftLegend: 'Count',
        enableTotals: false,
    };

    if (isLoading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }
    return (
        <div className="max-w-[100%] min-h-[40vh] max-h-[100vh]">
            {!isPreview && (
                <>
                    <Segmented
                        options={[
                            ChartInterval.Daily,
                            ChartInterval.Weekly,
                            ChartInterval.Monthly,
                            ChartInterval.Cumulative,
                        ]}
                        value={chartInterval}
                        defaultValue={defaultChartInterval}
                        onChange={(value): void => {
                            if (
                                value === ChartInterval.Daily &&
                                dateRange[0] === 0
                            ) {
                                setDateRange([
                                    dayjs().subtract(1, 'week').unix() - 80000,
                                    dayjs().unix(),
                                ]);
                            }
                            setChartInterval(value as ChartInterval);
                        }}
                        style={{ marginBottom: 12, marginLeft: 12 }}
                    />
                    <Segmented
                        options={[ChartType.Line, ChartType.Bar]}
                        onChange={(value): void => {
                            setChartType(value as ChartType);
                        }}
                        style={{ marginBottom: 12, marginLeft: 24 }}
                    />
                    <Segmented
                        defaultValue={ChartMonth.THREE_MONTHS}
                        options={Object.values(ChartMonth)}
                        onChange={(value): void => {
                            setMonth(value as ChartMonth);
                        }}
                        style={{ marginBottom: 12, marginLeft: 24 }}
                    />
                    <Space className="ml-4">
                        <div>
                            <span>Total Users: </span>
                            <span>{data.count}</span>
                        </div>
                        <div>
                            <span>Deployed Users: </span>
                            <span>{data.deployed_count}</span>
                        </div>
                    </Space>
                </>
            )}
            {chartType === ChartType.Line ? (
                <LineChart
                    data={chartData[chartInterval] as LineChartData}
                    props={lineProps}
                />
            ) : (
                <BarChart
                    data={
                        lineDataToBarData(
                            chartData[chartInterval] as LineChartData,
                        ) as Array<BarChartData<'count'>>
                    }
                    props={barProps}
                />
            )}
        </div>
    );
};

const lineDataToBarData = (
    data: LineChartData,
): Array<BarChartData<'count'>> => {
    return data[0].data.map((item) => ({
        date: item.x,
        count: item.y,
    }));
};
