/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import type { CustomAxiosError, CustomQueryResult } from 'api/types';
import axios from 'axios';
import { ACCESS_TOKEN } from 'const';
import { useEffect, useState } from 'react';

import type {
    GraphQLClientConfig,
    GraphQLVariables,
    GraphResponse,
    PaginationConfig,
} from './types';

export class GraphQLClient<TData> {
    private endpoint: string;
    private pagination: PaginationConfig;
    private defaultData: TData;
    private staleTime: number;
    private gcTime: number;
    private accessToken: string | null;
    private axiosInstance;
    private sizeHint: number | null;
    private maxParallelRequests: number;

    constructor(config: GraphQLClientConfig<TData>) {
        this.endpoint = config.endpoint;
        this.pagination = {
            enabled: config.pagination?.enabled ?? false,
            limit: config.pagination?.limit ?? 1000,
        };
        this.defaultData = config.defaultData;
        this.staleTime = config.staleTime ?? Infinity;
        this.gcTime = config.gcTime ?? Infinity;
        this.accessToken = config.accessToken ?? null;
        this.sizeHint = config.sizeHint ?? null;
        this.maxParallelRequests = 6;

        this.axiosInstance = axios.create();

        this.axiosInstance.interceptors.request.use((config) => {
            if (this.accessToken) {
                config.headers.authorization = `Bearer ${this.accessToken}`;
            }
            return config;
        });

        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            async function (error: CustomAxiosError) {
                if (error.response?.status === 401) {
                    localStorage.removeItem(ACCESS_TOKEN);
                    alert(
                        'Your session is ended. Please make sure you saved your work somewhere and log in again.',
                    );
                }
                return Promise.reject(error);
            },
        );
    }

    private async fetchData(
        gql: string,
        variables: GraphQLVariables,
    ): Promise<TData> {
        if (!this.sizeHint || !this.pagination.limit) {
            try {
                const { data } = await this.axiosInstance.post<
                    GraphResponse<TData>
                >(this.endpoint, {
                    query: gql,
                    variables,
                });
                return data.data ?? this.defaultData;
            } catch (error) {
                console.error('Error fetching GraphQL data:', error);
                return this.defaultData;
            }
        }

        try {
            const totalRequests = Math.ceil(
                this.sizeHint / this.pagination.limit,
            );
            const batchSize = Math.min(this.maxParallelRequests, totalRequests);

            const requests = Array.from({ length: batchSize }, async (_, i) => {
                const offset = i * this.pagination.limit!;
                return this.axiosInstance.post<GraphResponse<TData>>(
                    this.endpoint,
                    {
                        query: gql,
                        variables: {
                            ...variables,
                            offset,
                            limit: this.pagination.limit,
                        },
                    },
                );
            });

            const responses = await Promise.all(requests);

            const firstResponseData = responses[0].data.data;
            if (!firstResponseData) {
                throw new Error('Invalid response data structure');
            }

            const key = Object.keys(firstResponseData as object)[0];
            const mergedData: Array<unknown> = [];

            for (const response of responses) {
                const responseData = response.data.data;
                if (!responseData) {
                    throw new Error('Invalid response data structure');
                }

                const items = (responseData as Record<string, Array<unknown>>)[
                    key
                ];
                if (!Array.isArray(items)) {
                    throw new Error(
                        'Invalid response data structure: expected array',
                    );
                }
                mergedData.push(...items);
            }

            const actualSize = mergedData.length;
            this.sizeHint = Math.max(0, this.sizeHint - actualSize);

            if (
                mergedData.length > 0 &&
                responses.length > 1 &&
                typeof (mergedData[0] as { id?: string }).id === 'string'
            ) {
                mergedData.sort((a, b) => {
                    const aId = (a as { id: string }).id;
                    const bId = (b as { id: string }).id;
                    return aId.localeCompare(bId);
                });
            }

            return { [key]: mergedData } as TData;
        } catch (error) {
            console.error('Error fetching paginated GraphQL data:', error);
            return this.defaultData;
        }
    }

    useQuery(params: {
        gql: string;
        gqlVariables: GraphQLVariables;
        queryKey: Array<string | number>;
    }): CustomQueryResult<TData> {
        const [allData, setAllData] = useState<TData>(this.defaultData);
        const [lastID, setLastID] = useState('');
        const [hasMore, setHasMore] = useState(this.pagination.enabled);
        const [isFetching, setIsFetching] = useState(this.pagination.enabled);
        const [isFirstFetch, setIsFirstFetch] = useState(true);

        const { data, refetch, ...rest } = useQuery({
            queryKey: [...params.queryKey, lastID],
            queryFn: async () =>
                this.fetchData(params.gql, {
                    ...params.gqlVariables,
                    ...(this.pagination.enabled && {
                        limit: this.pagination.limit,
                        lastID,
                    }),
                }),
            staleTime: this.staleTime,
            gcTime: this.gcTime,
            enabled: isFetching && hasMore,
            experimental_prefetchInRender: true,
        });

        useEffect(() => {
            if (!this.pagination.enabled) {
                setAllData(data as TData);
                return;
            }

            if (data) {
                const dataArray = data as Record<
                    string,
                    Array<{ id: string }> | unknown
                >;
                const firstKey = Object.keys(dataArray)[0];
                const firstValue = dataArray[firstKey];

                let shouldContinue = false;
                if (Array.isArray(firstValue) && firstValue.length > 0) {
                    shouldContinue =
                        firstValue.length >= (this.pagination.limit ?? 0);
                    if (shouldContinue) {
                        setLastID(firstValue[firstValue.length - 1].id);
                    }
                }

                setAllData((prevData) => {
                    const prevDataArray = prevData as Record<
                        string,
                        Array<{ id: string }> | unknown
                    >;
                    const newData = { ...prevDataArray };

                    // Handle each key in the response
                    Object.entries(dataArray).forEach(([key, value]) => {
                        if (
                            Array.isArray(value) &&
                            value.length > 0 &&
                            value[0].id
                        ) {
                            if (key === firstKey) {
                                newData[key] = [
                                    ...((prevDataArray[key] as Array<{
                                        id: string;
                                    }>) || []),
                                    ...value,
                                ];
                            } else if (isFirstFetch) {
                                newData[key] = value;
                            }
                        } else if (isFirstFetch) {
                            newData[key] = value;
                        }
                    });

                    return newData as TData;
                });

                if (!shouldContinue) {
                    setHasMore(false);
                    setIsFetching(false);
                }

                if (isFirstFetch) {
                    setIsFirstFetch(false);
                }
            }
        }, [data]);

        useEffect(() => {
            if (isFetching && hasMore) {
                refetch();
            }
        }, [lastID, isFetching, hasMore, refetch]);

        const restSafe = {
            ...rest,
            isFetching,
            hasMore,
        };

        return {
            data: allData,
            refetch,
            ...restSafe,
        };
    }
}
