/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { CustomQueryResult } from 'api/types';
import { ACCESS_TOKEN } from 'const';
import { useEffect, useMemo, useState } from 'react';
import { getSanitizedApiUrl } from 'utils';
import { GraphQLClient } from 'utils/graphql';
import { IdleBalancesQuery } from 'utils/graphql/queries';

export type IdleBalance = {
    id: string;
    address: string;
    token: {
        id: string;
    };
    balance: string;
};

type IdleBalancesResponse = {
    Account: Array<IdleBalance>;
};

export type IdleBalancesResult = {
    balances: Array<IdleBalance>;
};

const defaultData: IdleBalancesResult = {
    balances: [],
};

const idleBalancesEndpoint = `${getSanitizedApiUrl()}/internal/idle-balances`;

const queryOptions = {
    gql: IdleBalancesQuery,
    gqlVariables: {},
    queryKey: ['idle-balances'],
};

export const useIdleBalances = (): CustomQueryResult<IdleBalancesResult> => {
    const [result, setResult] = useState<IdleBalancesResult>(defaultData);
    const accessToken = localStorage.getItem(ACCESS_TOKEN);

    const client = useMemo(
        () =>
            new GraphQLClient<IdleBalancesResponse>({
                endpoint: idleBalancesEndpoint,
                pagination: {
                    enabled: true,
                },
                accessToken: accessToken || undefined,
                defaultData: { Account: [] },
                sizeHint: 23000,
            }),
        [accessToken],
    );

    const queryResult = client.useQuery(queryOptions);

    useEffect(() => {
        if (queryResult.data) {
            setResult({
                balances: queryResult.data.Account ?? [],
            });
        }
    }, [queryResult.data]);

    return {
        ...queryResult,
        data: result,
        promise: queryResult.promise.then(() => result),
    };
};
