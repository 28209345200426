/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useGetTokensQuery } from 'api';
import { Sidebar } from 'components';
import {
    Account,
    AddAdapter,
    GetAdapters,
    GetPools,
    SetIncentive,
    SetPoolConfig,
    SetRewardConfigs,
} from 'components/clagg';
import type { $MixedElement } from 'types';
import { MAIN_ABI, MAIN_ADDRESS } from 'utils/clagg';
import { useAccount, useReadContract } from 'wagmi';

export const ClaggPanel = (): $MixedElement => {
    const { isConnected } = useAccount();
    const { data: tokens } = useGetTokensQuery();
    const { data: adapters = [] } = useReadContract({
        address: MAIN_ADDRESS as `0x${string}`,
        abi: MAIN_ABI,
        functionName: 'listAdapters',
    });

    const scrollToOverview = (): void => {
        const overviewSection = document.getElementById('pool-overview');
        if (overviewSection) {
            overviewSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="flex h-screen">
            <Sidebar />
            <div className="flex-1 overflow-auto bg-gray-50">
                <Account />
                <div className="min-h-screen">
                    {/* Quick Actions Bar */}
                    <div className="sticky top-0 z-20 bg-white/95 backdrop-blur-sm border-b border-gray-200 shadow-sm">
                        <div className="max-w-[90rem] mx-auto px-8 py-4">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h1 className="text-2xl font-bold text-gray-900">
                                        Pool Management
                                    </h1>
                                    <p className="text-sm text-gray-500 mt-1">
                                        {isConnected
                                            ? 'Manage your pools, rewards, and incentives'
                                            : 'Connect wallet to manage pools and view statistics'}
                                    </p>
                                </div>
                                <div className="flex items-center gap-6">
                                    <div className="flex items-center gap-2">
                                        <div className="h-8 w-8 rounded-lg bg-purple-100 flex items-center justify-center">
                                            <svg
                                                className="w-4 h-4 text-purple-600"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                                                />
                                            </svg>
                                        </div>
                                        <div className="text-sm">
                                            <div className="font-medium text-gray-700">
                                                Active Adapters
                                            </div>
                                            <div className="text-purple-600 font-semibold">
                                                {adapters.length}
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        onClick={scrollToOverview}
                                        className="flex items-center gap-2 px-4 py-2 bg-purple-50 hover:bg-purple-100 text-purple-700 rounded-lg transition-all duration-200 font-medium"
                                    >
                                        <span>View Pools</span>
                                        <svg
                                            className="w-4 h-4"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M19 9l-7 7-7-7"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="max-w-[90rem] mx-auto px-8 py-8 space-y-8">
                        {/* Adapter Management Section */}
                        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
                            <div className="p-6 border-b border-gray-100 bg-gradient-to-r from-blue-50 to-purple-50">
                                <h2 className="text-lg font-semibold text-gray-900">
                                    Adapter Management
                                </h2>
                                <p className="text-sm text-gray-600 mt-1">
                                    {isConnected
                                        ? 'Add and manage protocol adapters'
                                        : 'View protocol adapters'}
                                </p>
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-2 divide-y xl:divide-y-0 xl:divide-x divide-gray-100">
                                {isConnected && (
                                    <div className="p-8">
                                        <AddAdapter />
                                    </div>
                                )}
                                <div
                                    className={`p-8 ${
                                        !isConnected ? 'xl:col-span-2' : ''
                                    }`}
                                >
                                    <GetAdapters />
                                </div>
                            </div>
                        </div>

                        {isConnected && adapters.length > 0 && (
                            /* Configuration Tabs */
                            <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
                                <div className="p-6 border-b border-gray-100 bg-gradient-to-r from-purple-50 to-indigo-50">
                                    <h2 className="text-lg font-semibold text-gray-900">
                                        Pool Configuration
                                    </h2>
                                    <p className="text-sm text-gray-600 mt-1">
                                        Configure pools, rewards, and incentives
                                    </p>
                                </div>
                                <div className="p-8 space-y-12">
                                    {/* Pool Config */}
                                    <div>
                                        <div className="flex items-center gap-3 mb-6">
                                            <div className="h-10 w-10 rounded-xl bg-blue-100 flex items-center justify-center">
                                                <svg
                                                    className="w-5 h-5 text-blue-600"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                    />
                                                </svg>
                                            </div>
                                            <div>
                                                <h3 className="text-lg font-semibold text-gray-900">
                                                    Basic Settings
                                                </h3>
                                                <p className="text-sm text-gray-600">
                                                    Configure pool tokens and
                                                    fees
                                                </p>
                                            </div>
                                        </div>
                                        <SetPoolConfig
                                            adapters={
                                                adapters as Array<`0x${string}`>
                                            }
                                            tokens={tokens}
                                        />
                                    </div>

                                    {/* Reward Config */}
                                    <div>
                                        <div className="flex items-center gap-3 mb-6">
                                            <div className="h-10 w-10 rounded-xl bg-green-100 flex items-center justify-center">
                                                <svg
                                                    className="w-5 h-5 text-green-600"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>
                                            </div>
                                            <div>
                                                <h3 className="text-lg font-semibold text-gray-900">
                                                    Reward Settings
                                                </h3>
                                                <p className="text-sm text-gray-600">
                                                    Configure reward tokens and
                                                    rates
                                                </p>
                                            </div>
                                        </div>
                                        <SetRewardConfigs
                                            adapters={
                                                adapters as Array<`0x${string}`>
                                            }
                                            tokens={tokens}
                                        />
                                    </div>

                                    {/* Incentive Config */}
                                    <div>
                                        <div className="flex items-center gap-3 mb-6">
                                            <div className="h-10 w-10 rounded-xl bg-purple-100 flex items-center justify-center">
                                                <svg
                                                    className="w-5 h-5 text-purple-600"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M13 10V3L4 14h7v7l9-11h-7z"
                                                    />
                                                </svg>
                                            </div>
                                            <div>
                                                <h3 className="text-lg font-semibold text-gray-900">
                                                    Incentive Program
                                                </h3>
                                                <p className="text-sm text-gray-600">
                                                    Set up liquidity provider
                                                    incentives
                                                </p>
                                            </div>
                                        </div>
                                        <SetIncentive
                                            adapters={
                                                adapters as Array<`0x${string}`>
                                            }
                                            tokens={tokens}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Overview Section - Always visible */}
                        <div
                            id="pool-overview"
                            className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden scroll-mt-24"
                        >
                            <div className="p-6 border-b border-gray-100 bg-gradient-to-r from-indigo-50 to-blue-50">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h2 className="text-lg font-semibold text-gray-900">
                                            Pool Overview
                                        </h2>
                                        <p className="text-sm text-gray-600 mt-1">
                                            {isConnected
                                                ? 'Monitor and manage all active pools'
                                                : 'View all active pools'}
                                        </p>
                                    </div>
                                    {!isConnected && (
                                        <div className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-700 rounded-lg">
                                            <svg
                                                className="w-5 h-5"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                            <span className="text-sm font-medium">
                                                Read Only Mode
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="p-8">
                                <GetPools tokens={tokens} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
