/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { zeroAddress } from 'viem';

import type { AdapterAddress, RewardConfig } from './types';

export const MAIN_ADDRESS = '0x7f73934F333a25B456Dc9B8b62A19f211c991f1c';
export const SYNC_ADAPTER_ADDRESS =
    '0x10fE20Aa6fe593D8Ea83378b6D9158FC5046e560';
export const ZTAKE_ADAPTER_ADDRESS =
    '0x1c4BbE8C7d28AB66625c941A86660165b910798f';
export const VENUS_ADAPTER_ADDRESS =
    '0xCd75A7DdF5C1e1a8f28e2f3fB2C357890bAED618';
export const AAVE_ADAPTER_ADDRESS =
    '0xD0F831b20Be269733ea0fe3b32828b0B5807Dfb6';
export const MERKL_ADAPTER_ADDRESS =
    '0xbc9309Fd0d271c6DC34A22fE1c35e611EC466655';

export type AdapterName = 'SyncSwap' | 'Ztake' | 'Venus' | 'Aave' | 'Merkl';

export const adapterToName: Record<AdapterAddress, AdapterName> = {
    [SYNC_ADAPTER_ADDRESS]: 'SyncSwap',
    [ZTAKE_ADAPTER_ADDRESS]: 'Ztake',
    [VENUS_ADAPTER_ADDRESS]: 'Venus',
    [AAVE_ADAPTER_ADDRESS]: 'Aave',
    [MERKL_ADAPTER_ADDRESS]: 'Merkl',
};

type PoolConfig = {
    token: `0x${string}`;
    decimals: number;
    adapter: AdapterName;
    description?: string;
    otherToken?: `0x${string}`;
    isToken0?: boolean;
};

export const poolConfigs: Record<string, PoolConfig> = {
    '0xA93472C1B88243793E145B237b7172F1EE547836': {
        token: '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4',
        decimals: 18,
        adapter: 'SyncSwap',
        otherToken: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
        isToken0: true,
        description: 'SyncSwap USDC Pool',
    },
    // Venus Pools
    '0x1aF23bD57c62A99C59aD48236553D0Dd11e49D2D': {
        token: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
        decimals: 8,
        adapter: 'Venus',
        description: 'Venus USDC.e Pool',
    },
    '0x84064c058F2EFea4AB648bB6Bd7e40f83fFDe39a': {
        token: '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4',
        decimals: 8,
        adapter: 'Venus',
        description: 'Venus USDC Pool',
    },
    '0x1Fa916C27c7C2c4602124A14C77Dbb40a5FF1BE8': {
        token: '0x000000000000000000000000000000000000800A',
        decimals: 8,
        adapter: 'Venus',
        description: 'Venus ETH Pool',
    },
    '0x69cDA960E3b20DFD480866fFfd377Ebe40bd0A46': {
        token: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
        decimals: 8,
        adapter: 'Venus',
        description: 'Venus USDT Pool',
    },
    '0x697a70779C1A03Ba2BD28b7627a902BFf831b616': {
        token: '0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E',
        decimals: 8,
        adapter: 'Venus',
        description: 'Venus ZK Pool',
    },
    // Ztake Pools
    '0x9248f1ee8cbd029f3d22a92eb270333a39846fb2': {
        token: '0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E',
        decimals: 18,
        adapter: 'Ztake',
        description: 'Ztake ZK Pool',
    },
    // Aave Pools
    '0xd6cD2c0fC55936498726CacC497832052A9B2D1B': {
        token: '0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E',
        decimals: 18,
        adapter: 'Aave',
        description: 'Aave ZK Pool',
    },
};

export const poolToToken: Record<string, string> = Object.entries(
    poolConfigs,
).reduce((acc, [pool, config]) => {
    acc[pool] = config.token;
    return acc;
}, {} as Record<string, string>);

export const adapterToPools: Record<AdapterAddress, Array<`0x${string}`>> = {
    [SYNC_ADAPTER_ADDRESS]: ['0xA93472C1B88243793E145B237b7172F1EE547836'],
    [ZTAKE_ADAPTER_ADDRESS]: ['0x9248f1ee8cbd029f3d22a92eb270333a39846fb2'],
    [VENUS_ADAPTER_ADDRESS]: [
        '0x1aF23bD57c62A99C59aD48236553D0Dd11e49D2D',
        '0x1Fa916C27c7C2c4602124A14C77Dbb40a5FF1BE8',
        '0x69cDA960E3b20DFD480866fFfd377Ebe40bd0A46',
        '0x697a70779C1A03Ba2BD28b7627a902BFf831b616',
        '0x84064c058F2EFea4AB648bB6Bd7e40f83fFDe39a',
    ],
    [AAVE_ADAPTER_ADDRESS]: ['0xd6cD2c0fC55936498726CacC497832052A9B2D1B'],
    [MERKL_ADAPTER_ADDRESS]: [],
};

export const poolToAdapterName: Record<string, AdapterName> = Object.entries(
    adapterToPools,
).reduce((acc, [adapter, pools]) => {
    pools.forEach((pool) => {
        acc[pool] = adapterToName[adapter as AdapterAddress];
    });
    return acc;
}, {} as Record<string, AdapterName>);

export const poolToAdapterSuffix: Record<string, string> = Object.entries(
    adapterToPools,
).reduce((acc, [adapter, pools]) => {
    pools.forEach((pool) => {
        acc[pool] = adapter;
    });
    return acc;
}, {} as Record<string, string>);

export const poolRewardConfigs: Record<string, Array<RewardConfig>> = {
    '0x9248f1ee8cbd029f3d22a92eb270333a39846fb2': [
        {
            reward: '0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E',
            intermediateToken: zeroAddress,
            swapPool1: zeroAddress,
            swapPool2: zeroAddress,
            minAmountOut: 0n,
        },
    ],
    '0x84064c058F2EFea4AB648bB6Bd7e40f83fFDe39a': [
        {
            reward: '0xD78ABD81a3D57712a3af080dc4185b698Fe9ac5A',
            intermediateToken: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
            swapPool1: '0xf489085B9a020750c582bC9b97350b7bA22c67a4',
            swapPool2: '0x7936538Ace0c3859Ce5fD66C009e4F94aB1b3C45',
            minAmountOut: 10n,
        },
    ],
    '0x1aF23bD57c62A99C59aD48236553D0Dd11e49D2D': [
        {
            reward: '0xD78ABD81a3D57712a3af080dc4185b698Fe9ac5A',
            intermediateToken: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
            swapPool1: '0xf489085B9a020750c582bC9b97350b7bA22c67a4',
            swapPool2: '0x80115c708E12eDd42E504c1cD52Aea96C547c05c',
            minAmountOut: 10n,
        },
    ],
    '0x1Fa916C27c7C2c4602124A14C77Dbb40a5FF1BE8': [
        {
            reward: '0xD78ABD81a3D57712a3af080dc4185b698Fe9ac5A',
            intermediateToken: zeroAddress,
            swapPool1: '0xf489085B9a020750c582bC9b97350b7bA22c67a4',
            swapPool2: zeroAddress,
            minAmountOut: 100n,
        },
    ],
    '0x69cDA960E3b20DFD480866fFfd377Ebe40bd0A46': [
        {
            reward: '0xD78ABD81a3D57712a3af080dc4185b698Fe9ac5A',
            intermediateToken: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
            swapPool1: '0xf489085B9a020750c582bC9b97350b7bA22c67a4',
            swapPool2: '0xd3D91634Cf4C04aD1B76cE2c06F7385A897F54D3',
            minAmountOut: 10n,
        },
    ],
    '0x697a70779C1A03Ba2BD28b7627a902BFf831b616': [
        {
            reward: '0xD78ABD81a3D57712a3af080dc4185b698Fe9ac5A',
            intermediateToken: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
            swapPool1: '0xf489085B9a020750c582bC9b97350b7bA22c67a4',
            swapPool2: '0x45856bD6Bb9f076F4C558A4D5932c6c8d832b0d0',
            minAmountOut: 100n,
        },
    ],
    '0x12e7a9423d9128287e63017ee6d1f20e1c237f15': [
        {
            reward: '0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E',
            intermediateToken: zeroAddress,
            swapPool1: '0x45856bD6Bb9f076F4C558A4D5932c6c8d832b0d0',
            swapPool2: zeroAddress,
            minAmountOut: 100n,
        },
    ],
};

// Export pool decimals and tokens
export const poolDecimals: Record<string, number> = Object.entries(
    poolConfigs,
).reduce(
    (acc, [pool, config]) => ({
        ...acc,
        [pool]: config.decimals,
    }),
    {},
);

export const poolTokens: Record<string, string> = Object.entries(
    poolConfigs,
).reduce(
    (acc, [pool, config]) => ({
        ...acc,
        [pool]: config.token,
    }),
    {},
);
