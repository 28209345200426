/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Col, Row, Segmented, Spin } from 'antd';
import { useGetOnrampStatsQuery } from 'api/query/useGetOnrampStats';
import { BarChart } from 'components/charts/BarChart';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    type BarChartData,
    type BarChartProps,
    ChartInterval, //RangePicker,
    //dateFormat,
} from 'utils';

export const OnrampStats = ({
    defaultChartInterval = ChartInterval.Weekly,
    isPreview = false,
}: {
    defaultChartInterval?: ChartInterval;
    isPreview?: boolean;
}): $MixedElement => {
    const [dateRange, setDateRange] = useState<[number, number]>([0, 0]);
    const [isAmounts, setIsAmounts] = useState<boolean>(true);
    const [chartInterval, setChartInterval] =
        useState<ChartInterval>(defaultChartInterval);
    const { data: onrampStats, isLoading } = useGetOnrampStatsQuery();

    // const onDateChange: TimeRangePickerProps['onChange'] = (date) => {
    //     if (date && date[0] && date[1]) {
    //         setDateRange([date[0].unix(), date[1].unix()]);
    //         setChartInterval(ChartInterval.Daily);
    //     }
    // };

    type Key =
        | 'onramp_usdc'
        | 'offramp_usdc'
        | 'onramp_count'
        | 'offramp_count';

    const chartData = useMemo(() => {
        const daily: Array<BarChartData<Key>> = [];
        const weekly: Array<BarChartData<Key>> = [];
        const monthly: Array<BarChartData<Key>> = [];
        const cumulative: Array<BarChartData<Key>> = [];

        if (!onrampStats || onrampStats.length === 0) {
            return {
                [ChartInterval.Daily]: daily,
                [ChartInterval.Weekly]: weekly,
                [ChartInterval.Monthly]: monthly,
                [ChartInterval.Cumulative]: cumulative,
            };
        }

        onrampStats.forEach((stat) => {
            const date = new Date(stat.date).toDateString().slice(4);
            const dailyData: BarChartData<Key> = {
                date,
                onramp_count: 0,
                onramp_usdc: 0,
                offramp_count: 0,
                offramp_usdc: 0,
            };

            if (stat.type === 'onramp') {
                dailyData.onramp_count = stat.count;
                dailyData.onramp_usdc = stat.amount;
            } else {
                dailyData.offramp_count = -stat.count;
                dailyData.offramp_usdc = -stat.amount;
            }

            daily.push(dailyData);
        });

        let currentWeekStart = new Date(daily[0].date);
        let weekData: BarChartData<Key> = {
            date: daily[0].date,
            onramp_usdc: 0,
            onramp_count: 0,
            offramp_usdc: 0,
            offramp_count: 0,
        };

        daily.forEach((day, index) => {
            const currentDate = new Date(day.date);
            if (
                currentDate.getTime() - currentWeekStart.getTime() <
                7 * 24 * 60 * 60 * 1000
            ) {
                weekData.onramp_usdc += day.onramp_usdc;
                weekData.onramp_count += day.onramp_count;
                weekData.offramp_usdc += day.offramp_usdc;
                weekData.offramp_count += day.offramp_count;
            } else {
                weekly.push(weekData);
                currentWeekStart = currentDate;
                weekData = {
                    date: day.date,
                    onramp_usdc: day.onramp_usdc,
                    onramp_count: day.onramp_count,
                    offramp_usdc: day.offramp_usdc,
                    offramp_count: day.offramp_count,
                };
            }

            if (index === daily.length - 1) {
                weekly.push(weekData);
            }
        });

        let currentMonthStart = new Date(daily[0].date);
        let monthData: BarChartData<Key> = {
            date: new Date(daily[0].date).toLocaleString('default', {
                month: 'short',
                year: 'numeric',
            }),
            onramp_usdc: 0,
            onramp_count: 0,
            offramp_usdc: 0,
            offramp_count: 0,
        };

        daily.forEach((day, index) => {
            const currentDate = new Date(day.date);
            if (
                currentDate.getMonth() === currentMonthStart.getMonth() &&
                currentDate.getFullYear() === currentMonthStart.getFullYear()
            ) {
                monthData.onramp_usdc += day.onramp_usdc;
                monthData.onramp_count += day.onramp_count;
                monthData.offramp_usdc += day.offramp_usdc;
                monthData.offramp_count += day.offramp_count;
            } else {
                monthly.push(monthData);
                currentMonthStart = currentDate;
                monthData = {
                    date: new Date(day.date).toLocaleString('default', {
                        month: 'short',
                        year: 'numeric',
                    }),
                    onramp_usdc: day.onramp_usdc,
                    onramp_count: day.onramp_count,
                    offramp_usdc: day.offramp_usdc,
                    offramp_count: day.offramp_count,
                };
            }

            if (index === daily.length - 1) {
                monthly.push(monthData);
            }
        });

        let cumulativeOnrampUsdc = 0;
        let cumulativeOnrampCount = 0;
        let cumulativeOfframpUsdc = 0;
        let cumulativeOfframpCount = 0;
        weekly.forEach((week) => {
            cumulativeOnrampUsdc += week.onramp_usdc;
            cumulativeOnrampCount += week.onramp_count;
            cumulativeOfframpUsdc += week.offramp_usdc;
            cumulativeOfframpCount += week.offramp_count;
            cumulative.push({
                date: week.date,
                onramp_usdc: cumulativeOnrampUsdc,
                onramp_count: cumulativeOnrampCount,
                offramp_usdc: cumulativeOfframpUsdc,
                offramp_count: cumulativeOfframpCount,
            });
        });

        return {
            [ChartInterval.Daily]: daily,
            [ChartInterval.Weekly]: weekly,
            [ChartInterval.Monthly]: monthly,
            [ChartInterval.Cumulative]: cumulative,
        };
    }, [onrampStats, dateRange, chartInterval]);

    const barProps: BarChartProps<Key> = {
        keys: isAmounts
            ? ['onramp_usdc', 'offramp_usdc']
            : ['onramp_count', 'offramp_count'],
        indexBy: 'date',
        axisBottomLegend: 'Date',
        axisLeftLegend: isAmounts ? 'USD' : 'Count',
        enableTotals: true,
        valueFormat: isAmounts ? ' >-$.2f' : ' >-.0f',
        groupMode: 'stacked' as const,
    };

    if (isLoading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }

    return (
        <div className="w-[100%] min-h-[40vh] max-h-[100vh]">
            {!isPreview && (
                <>
                    <Row>
                        <Col span={7}>
                            <Segmented
                                options={[
                                    ChartInterval.Daily,
                                    ChartInterval.Weekly,
                                    ChartInterval.Monthly,
                                    ChartInterval.Cumulative,
                                ]}
                                value={chartInterval}
                                defaultValue={defaultChartInterval}
                                onChange={(value): void => {
                                    if (
                                        value === ChartInterval.Daily &&
                                        dateRange[0] === 0
                                    ) {
                                        setDateRange([
                                            dayjs().subtract(1, 'week').unix() -
                                                80000,
                                            dayjs().unix(),
                                        ]);
                                    }
                                    setChartInterval(value as ChartInterval);
                                }}
                            />
                        </Col>
                        <Col span={7}>
                            <Segmented
                                options={[
                                    { label: 'Amount', value: true },
                                    { label: 'Count', value: false },
                                ]}
                                value={isAmounts}
                                onChange={(value): void => {
                                    setIsAmounts(value as boolean);
                                }}
                            />
                        </Col>
                        {/* <Col span={7}>
                            <RangePicker
                                onChange={onDateChange}
                                value={
                                    dateRange[0] === 0
                                        ? undefined
                                        : [
                                              dayjs.unix(dateRange[0]),
                                              dayjs.unix(dateRange[1]),
                                          ]
                                }
                                minDate={dayjs('2024-01-21', dateFormat)}
                                maxDate={dayjs()}
                            />
                        </Col> */}
                    </Row>
                </>
            )}
            <BarChart data={chartData[chartInterval]} props={barProps} />
        </div>
    );
};
