/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiGetEarnConfig } from 'api';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

export const useGetEarnConfigMutation = (): [
    string,
    Dispatch<SetStateAction<string>>,
    boolean,
] => {
    const [config, setConfig] = useState('');

    const mutation = useMutation({
        mutationFn: async () => {
            const response = await apiGetEarnConfig();
            setConfig(response.data);
            return response.data;
        },
    });

    useEffect(() => {
        mutation.mutate();
    }, []);

    return [config, setConfig, mutation.isPending];
};
