/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { $MixedElement } from 'types';
import { MAIN_ABI, MAIN_ADDRESS, getAdapterName } from 'utils/clagg';
import { useReadContract } from 'wagmi';

export function GetAdapters(): $MixedElement {
    const { data: adapters = [] } = useReadContract({
        address: MAIN_ADDRESS as `0x${string}`,
        abi: MAIN_ABI,
        functionName: 'listAdapters',
    });

    if (adapters.length === 0) {
        return (
            <div className="text-center py-8 bg-gray-50 rounded-lg border border-gray-200">
                <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                    />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No adapters found
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                    Get started by adding a new adapter.
                </p>
            </div>
        );
    }

    return (
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {adapters.map((adapter) => (
                <div
                    key={adapter}
                    className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors"
                >
                    <div className="flex items-center gap-3 mb-2">
                        <div className="h-8 w-8 rounded-full bg-purple-100 flex items-center justify-center">
                            <svg
                                className="w-4 h-4 text-purple-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                                />
                            </svg>
                        </div>
                        <div>
                            <div className="font-medium text-gray-900">
                                {getAdapterName(adapter as `0x${string}`)}
                            </div>
                            <code className="text-xs text-gray-500 font-mono">
                                {`${adapter.slice(0, 6)}...${adapter.slice(
                                    -4,
                                )}`}
                            </code>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
