/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { StatsResponse } from 'api/query/graph/useStatsQuery';

import { bytesToDate, getDays } from './date';
import type { DataKeys, LineData } from './types';

export function getDailyLineData(
    data: StatsResponse,
    key: DataKeys,
    dateRange: [number, number],
): LineData {
    const days = getDays(data.days, dateRange);

    return days.map((day) => {
        return {
            x: bytesToDate(day.id),
            y: Number(day[key]),
        };
    });
}

export function getWeeklyLineData(
    data: StatsResponse,
    key: DataKeys,
): LineData {
    return data.weeks.map((week) => {
        return {
            x: bytesToDate(week.id),
            y: Number(week[key]),
        };
    });
}

export function getMonthlyLineData(
    data: StatsResponse,
    key: DataKeys,
): LineData {
    return data.months.map((month) => {
        return {
            x: bytesToDate(month.id),
            y: Number(month[key]),
        };
    });
}

export function getCumulativeLineData(
    data: StatsResponse,
    key: DataKeys,
): LineData {
    let cumulativeCount = 0;
    return data.weeks.map((week) => {
        cumulativeCount += Number(week[key]);
        return {
            x: bytesToDate(week.id),
            y: cumulativeCount,
        };
    });
}
