/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { ApiTokenDto } from 'api/types';
import { useEffect, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    ADAPTER_ABI,
    MAIN_ADDRESS,
    SYNC_ADAPTER_ADDRESS,
    VENUS_ADAPTER_ADDRESS,
    VTOKEN_ABI,
    adapterToPools,
    getAdapterName,
    getTokenInfo,
    poolConfigs,
    poolTokens,
} from 'utils/clagg';
import type {
    AdapterAddress,
    IncentiveConfig,
    PoolInfo,
    RewardConfig,
} from 'utils/clagg/types';
import {
    decodeFunctionResult,
    encodeFunctionData,
    erc20Abi,
    formatUnits,
    parseUnits,
    zeroAddress,
} from 'viem';
import { useAccount, usePublicClient, useWriteContract } from 'wagmi';

export function SetPoolConfig({
    adapters,
    tokens,
}: {
    adapters: Array<`0x${string}`>;
    tokens: Array<ApiTokenDto>;
}): $MixedElement {
    const publicClient = usePublicClient();
    const [selectedAdapter, setSelectedAdapter] = useState<
        `0x${string}` | undefined
    >(undefined);
    const [pool, setPool] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [performanceFee, setPerformanceFee] = useState<number>(0);
    const [nonClaveFee, setNonClaveFee] = useState<number>(0);
    const [validationErrors, setValidationErrors] = useState<{
        adapter?: string;
        pool?: string;
        token?: string;
        performanceFee?: string;
        nonClaveFee?: string;
    }>({});
    const [suggestedPools, setSuggestedPools] = useState<
        Array<{
            pool: string;
            token: string;
            description?: string;
        }>
    >([]);

    useEffect(() => {
        async function checkPools(): Promise<void> {
            if (!publicClient || !selectedAdapter) return;

            const adapterPools =
                adapterToPools[selectedAdapter as AdapterAddress] || [];
            const unconfiguredPools = [];

            for (const pool of adapterPools) {
                const poolConfigCalldata =
                    encodeFunctionData({
                        abi: ADAPTER_ABI,
                        functionName: 'getPoolConfig',
                        args: [pool],
                    }) + selectedAdapter.slice(2);

                try {
                    const poolConfig = await publicClient.call({
                        to: MAIN_ADDRESS as `0x${string}`,
                        data: poolConfigCalldata as `0x${string}`,
                    });

                    const decodedPoolConfig = decodeFunctionResult({
                        abi: ADAPTER_ABI,
                        functionName: 'getPoolConfig',
                        data: poolConfig.data as `0x${string}`,
                    });

                    if (decodedPoolConfig.token === zeroAddress) {
                        const poolConfig = poolConfigs[pool];
                        unconfiguredPools.push({
                            pool,
                            token: poolTokens[pool] || '',
                            description: poolConfig?.description,
                        });
                    }
                } catch (err) {
                    console.error('Error checking pool config:', err);
                }
            }

            setSuggestedPools(unconfiguredPools);
        }

        checkPools();
    }, [selectedAdapter, publicClient]);

    const { writeContract, isPending } = useWriteContract({
        mutation: {
            onError(error) {
                console.error('Error setting pool config:', error);
            },
            onSuccess() {
                // Reset form on success
                setPool('');
                setToken('');
                setPerformanceFee(0);
                setNonClaveFee(0);
            },
        },
    });

    const validateForm = (): boolean => {
        const errors: typeof validationErrors = {};

        if (!selectedAdapter) {
            errors.adapter = 'Please select an adapter';
        }

        if (!pool) {
            errors.pool = 'Pool address is required';
        } else if (!pool.match(/^0x[a-fA-F0-9]{40}$/)) {
            errors.pool = 'Invalid pool address format';
        }

        if (!token) {
            errors.token = 'Token is required';
        }

        if (performanceFee < 0 || performanceFee > 100) {
            errors.performanceFee = 'Performance fee must be between 0 and 100';
        }

        if (nonClaveFee < 0 || nonClaveFee > 100) {
            errors.nonClaveFee = 'Non-Clave fee must be between 0 and 100';
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        writeContract({
            address: MAIN_ADDRESS as `0x${string}`,
            abi: ADAPTER_ABI,
            functionName: 'setPoolConfig',
            args: [
                pool as `0x${string}`,
                token as `0x${string}`,
                Number(BigInt(Math.floor(performanceFee * 100))),
                Number(BigInt(Math.floor(nonClaveFee * 100))),
            ],
            dataSuffix: selectedAdapter,
        });
    };

    const handleInputChange = (field: string, value: string | number): void => {
        switch (field) {
            case 'adapter':
                setSelectedAdapter(
                    value ? (value as `0x${string}`) : undefined,
                );
                // Reset pool-related fields when adapter changes
                setPool('');
                setSuggestedPools([]);
                break;
            case 'pool':
                setPool(value as string);
                break;
            case 'token':
                setToken(value as string);
                break;
            case 'performanceFee':
                setPerformanceFee(Number(value));
                break;
            case 'nonClaveFee':
                setNonClaveFee(Number(value));
                break;
        }
    };

    return (
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
            <div className="flex items-center justify-between mb-6">
                <div>
                    <h3 className="text-lg font-semibold text-gray-900">
                        Set Pool Config
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                        Configure pool settings for your adapters
                    </p>
                </div>
                <div className="h-10 w-10 rounded-full bg-purple-100 flex items-center justify-center">
                    <svg
                        className="w-5 h-5 text-purple-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                        />
                    </svg>
                </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Select Adapter
                    </label>
                    <select
                        value={selectedAdapter || ''}
                        onChange={(e) =>
                            handleInputChange('adapter', e.target.value)
                        }
                        required
                        className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors duration-200 ${
                            validationErrors.adapter
                                ? 'border-red-300 bg-red-50'
                                : 'border-gray-300'
                        }`}
                    >
                        <option value="">Select an adapter</option>
                        {adapters.map((adapter) => (
                            <option key={adapter} value={adapter}>
                                {getAdapterName(adapter)} ({adapter.slice(0, 6)}
                                ...{adapter.slice(-4)})
                            </option>
                        ))}
                    </select>
                    {validationErrors.adapter && (
                        <p className="mt-1 text-sm text-red-600">
                            {validationErrors.adapter}
                        </p>
                    )}
                </div>

                {selectedAdapter && suggestedPools.length > 0 && (
                    <div className="bg-gray-50 rounded-lg p-4">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">
                            Suggested Unconfigured Pools
                        </h4>
                        <div className="space-y-2">
                            {suggestedPools.map((suggestion) => (
                                <button
                                    key={suggestion.pool}
                                    type="button"
                                    onClick={() => {
                                        setPool(suggestion.pool);
                                        setToken(
                                            suggestion.token.toLowerCase(),
                                        );
                                    }}
                                    className="w-full flex items-center justify-between p-3 bg-white hover:bg-gray-100 rounded-lg transition-all duration-200 transform hover:scale-[1.01] border border-gray-200"
                                >
                                    <div className="flex flex-col items-start gap-1">
                                        <div className="flex items-center gap-2">
                                            <div className="text-sm font-medium text-gray-900">
                                                {suggestion.pool.slice(0, 6)}...
                                                {suggestion.pool.slice(-4)}
                                            </div>
                                            {suggestion.token && (
                                                <div className="text-xs text-gray-500">
                                                    (Token:{' '}
                                                    {suggestion.token.slice(
                                                        0,
                                                        6,
                                                    )}
                                                    ...
                                                    {suggestion.token.slice(-4)}
                                                    )
                                                </div>
                                            )}
                                        </div>
                                        {suggestion.description && (
                                            <div className="text-xs text-gray-500">
                                                {suggestion.description}
                                            </div>
                                        )}
                                    </div>
                                    <div className="text-purple-600">
                                        <svg
                                            className="w-5 h-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                )}

                {/* Rest of the form fields */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Pool Address
                        </label>
                        <input
                            type="text"
                            value={pool}
                            onChange={(e) =>
                                handleInputChange('pool', e.target.value)
                            }
                            placeholder="Enter pool address (0x...)"
                            required
                            className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors duration-200 ${
                                validationErrors.pool
                                    ? 'border-red-300 bg-red-50'
                                    : 'border-gray-300'
                            }`}
                        />
                        {validationErrors.pool && (
                            <p className="mt-1 text-sm text-red-600">
                                {validationErrors.pool}
                            </p>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Token
                        </label>
                        <select
                            value={token}
                            onChange={(e) =>
                                handleInputChange('token', e.target.value)
                            }
                            required
                            className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors duration-200 ${
                                validationErrors.token
                                    ? 'border-red-300 bg-red-50'
                                    : 'border-gray-300'
                            }`}
                        >
                            <option value="">Select Token</option>
                            {tokens.map((token) => (
                                <option
                                    key={token.address}
                                    value={token.address}
                                >
                                    {token.symbol} - {token.name}
                                </option>
                            ))}
                        </select>
                        {validationErrors.token && (
                            <p className="mt-1 text-sm text-red-600">
                                {validationErrors.token}
                            </p>
                        )}
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Performance Fee (%)
                        </label>
                        <input
                            type="number"
                            min="0"
                            max="100"
                            step="0.01"
                            value={performanceFee}
                            onChange={(e) =>
                                handleInputChange(
                                    'performanceFee',
                                    e.target.value,
                                )
                            }
                            required
                            className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors duration-200 ${
                                validationErrors.performanceFee
                                    ? 'border-red-300 bg-red-50'
                                    : 'border-gray-300'
                            }`}
                        />
                        {validationErrors.performanceFee && (
                            <p className="mt-1 text-sm text-red-600">
                                {validationErrors.performanceFee}
                            </p>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Non-Clave Fee (%)
                        </label>
                        <input
                            type="number"
                            min="0"
                            max="100"
                            step="0.01"
                            value={nonClaveFee}
                            onChange={(e) =>
                                handleInputChange('nonClaveFee', e.target.value)
                            }
                            required
                            className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors duration-200 ${
                                validationErrors.nonClaveFee
                                    ? 'border-red-300 bg-red-50'
                                    : 'border-gray-300'
                            }`}
                        />
                        {validationErrors.nonClaveFee && (
                            <p className="mt-1 text-sm text-red-600">
                                {validationErrors.nonClaveFee}
                            </p>
                        )}
                    </div>
                </div>

                <button
                    type="submit"
                    disabled={
                        isPending ||
                        Object.keys(validationErrors).length > 0 ||
                        !selectedAdapter
                    }
                    className={`w-full flex items-center justify-center px-4 py-2 rounded-lg text-white transition-all duration-200 ${
                        isPending ||
                        Object.keys(validationErrors).length > 0 ||
                        !selectedAdapter
                            ? 'bg-purple-400 cursor-not-allowed'
                            : 'bg-purple-600 hover:bg-purple-700 transform hover:scale-[1.02]'
                    } focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2`}
                >
                    {isPending ? (
                        <>
                            <svg
                                className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                />
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                />
                            </svg>
                            Setting Config...
                        </>
                    ) : (
                        'Set Pool Config'
                    )}
                </button>
            </form>
        </div>
    );
}

export function SetRewardConfigs({
    adapters,
    tokens,
}: {
    adapters: Array<`0x${string}`>;
    tokens: Array<ApiTokenDto>;
}): $MixedElement {
    const publicClient = usePublicClient();
    const [selectedAdapter, setSelectedAdapter] = useState<
        `0x${string}` | undefined
    >(undefined);
    const [selectedPool, setSelectedPool] = useState<string>('');
    const [configs, setConfigs] = useState<
        Array<{
            reward: string;
            intermediateToken: string;
            swapPool1: string;
            swapPool2: string;
            minAmountOut: string;
        }>
    >([
        {
            reward: '',
            intermediateToken: zeroAddress,
            swapPool1: zeroAddress,
            swapPool2: zeroAddress,
            minAmountOut: '0',
        },
    ]);
    const [availablePools, setAvailablePools] = useState<
        Array<{
            address: string;
            description?: string;
        }>
    >([]);

    const { writeContract, isPending } = useWriteContract({
        mutation: {
            onError(error) {
                console.error('Error setting reward configs:', error);
            },
            onSuccess() {
                // Reset form on success
                setSelectedPool('');
                setConfigs([
                    {
                        reward: '',
                        intermediateToken: zeroAddress,
                        swapPool1: zeroAddress,
                        swapPool2: zeroAddress,
                        minAmountOut: '0',
                    },
                ]);
            },
        },
    });

    useEffect(() => {
        async function fetchPools(): Promise<void> {
            if (!selectedAdapter || !publicClient) return;

            try {
                const adapterPools =
                    adapterToPools[selectedAdapter as AdapterAddress] || [];
                const poolsWithConfig = [];

                for (const pool of adapterPools) {
                    const poolConfig = poolConfigs[pool];
                    poolsWithConfig.push({
                        address: pool,
                        description: poolConfig?.description,
                    });
                }

                setAvailablePools(poolsWithConfig);
            } catch (err) {
                console.error('Error fetching pools:', err);
            }
        }

        fetchPools();
    }, [selectedAdapter, publicClient]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (!selectedAdapter || !selectedPool) return;

        const formattedConfigs: Array<RewardConfig> = configs.map((config) => ({
            reward: config.reward as `0x${string}`,
            intermediateToken: config.intermediateToken as `0x${string}`,
            swapPool1: config.swapPool1 as `0x${string}`,
            swapPool2: config.swapPool2 as `0x${string}`,
            minAmountOut: BigInt(config.minAmountOut),
        }));

        writeContract({
            address: MAIN_ADDRESS as `0x${string}`,
            abi: ADAPTER_ABI,
            functionName: 'setRewardConfigs',
            args: [selectedPool as `0x${string}`, formattedConfigs],
            dataSuffix: selectedAdapter,
        });
    };

    const addConfig = (): void => {
        setConfigs([
            ...configs,
            {
                reward: '',
                intermediateToken: zeroAddress,
                swapPool1: zeroAddress,
                swapPool2: zeroAddress,
                minAmountOut: '0',
            },
        ]);
    };

    const removeConfig = (index: number): void => {
        setConfigs(configs.filter((_, i) => i !== index));
    };

    const updateConfig = (
        index: number,
        field: string,
        value: string,
    ): void => {
        const newConfigs = [...configs];
        newConfigs[index] = {
            ...newConfigs[index],
            [field]: value,
        };
        setConfigs(newConfigs);
    };

    return (
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
            <div className="flex items-center justify-between mb-6">
                <div>
                    <h3 className="text-lg font-semibold text-gray-900">
                        Set Reward Configs
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">
                        Configure reward settings for your pools
                    </p>
                </div>
                <div className="h-10 w-10 rounded-full bg-green-100 flex items-center justify-center">
                    <svg
                        className="w-5 h-5 text-green-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Select Adapter
                        </label>
                        <select
                            value={selectedAdapter || ''}
                            onChange={(e) => {
                                setSelectedAdapter(
                                    e.target.value
                                        ? (e.target.value as `0x${string}`)
                                        : undefined,
                                );
                                setSelectedPool('');
                            }}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-colors duration-200"
                        >
                            <option value="">Select an adapter</option>
                            {adapters.map((adapter) => (
                                <option key={adapter} value={adapter}>
                                    {getAdapterName(adapter)} (
                                    {adapter.slice(0, 6)}
                                    ...{adapter.slice(-4)})
                                </option>
                            ))}
                        </select>
                    </div>

                    {selectedAdapter && (
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Select Pool
                            </label>
                            <select
                                value={selectedPool}
                                onChange={(e) =>
                                    setSelectedPool(e.target.value)
                                }
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-colors duration-200"
                            >
                                <option value="">Select a pool</option>
                                {availablePools.map((pool) => (
                                    <option
                                        key={pool.address}
                                        value={pool.address}
                                    >
                                        {pool.address.slice(0, 6)}...
                                        {pool.address.slice(-4)}
                                        {pool.description
                                            ? ` - ${pool.description}`
                                            : ''}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>

                {selectedPool && (
                    <div className="space-y-4">
                        {configs.map((config, index) => (
                            <div
                                key={index}
                                className="p-4 bg-gray-50 rounded-lg space-y-4 relative group hover:bg-gray-100 transition-colors duration-200"
                            >
                                <div className="absolute -right-2 -top-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                    {configs.length > 1 && (
                                        <button
                                            type="button"
                                            onClick={() => removeConfig(index)}
                                            className="p-1 bg-red-100 text-red-600 rounded-full hover:bg-red-200 transition-colors duration-200"
                                        >
                                            <svg
                                                className="w-4 h-4"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    )}
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Reward Token
                                        </label>
                                        <select
                                            value={config.reward}
                                            onChange={(e) =>
                                                updateConfig(
                                                    index,
                                                    'reward',
                                                    e.target.value,
                                                )
                                            }
                                            required
                                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-colors duration-200"
                                        >
                                            <option value="">
                                                Select Token
                                            </option>
                                            {tokens.map((token) => (
                                                <option
                                                    key={token.address}
                                                    value={token.address}
                                                >
                                                    {token.symbol} -{' '}
                                                    {token.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Intermediate Token (Optional)
                                        </label>
                                        <select
                                            value={config.intermediateToken}
                                            onChange={(e) =>
                                                updateConfig(
                                                    index,
                                                    'intermediateToken',
                                                    e.target.value,
                                                )
                                            }
                                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-colors duration-200"
                                        >
                                            <option value={zeroAddress}>
                                                None
                                            </option>
                                            {tokens.map((token) => (
                                                <option
                                                    key={token.address}
                                                    value={token.address}
                                                >
                                                    {token.symbol} -{' '}
                                                    {token.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Swap Pool 1 (Optional)
                                        </label>
                                        <input
                                            type="text"
                                            value={config.swapPool1}
                                            onChange={(e) =>
                                                updateConfig(
                                                    index,
                                                    'swapPool1',
                                                    e.target.value,
                                                )
                                            }
                                            placeholder="0x..."
                                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-colors duration-200"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Swap Pool 2 (Optional)
                                        </label>
                                        <input
                                            type="text"
                                            value={config.swapPool2}
                                            onChange={(e) =>
                                                updateConfig(
                                                    index,
                                                    'swapPool2',
                                                    e.target.value,
                                                )
                                            }
                                            placeholder="0x..."
                                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-colors duration-200"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Min Amount Out
                                    </label>
                                    <input
                                        type="number"
                                        min="0"
                                        value={config.minAmountOut}
                                        onChange={(e) =>
                                            updateConfig(
                                                index,
                                                'minAmountOut',
                                                e.target.value,
                                            )
                                        }
                                        required
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-colors duration-200"
                                    />
                                </div>
                            </div>
                        ))}

                        <div className="flex gap-4">
                            <button
                                type="button"
                                onClick={addConfig}
                                className="flex items-center justify-center px-4 py-2 border border-green-600 text-green-600 rounded-lg hover:bg-green-50 transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            >
                                <svg
                                    className="w-4 h-4 mr-2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                    />
                                </svg>
                                Add Reward Config
                            </button>

                            <button
                                type="submit"
                                disabled={isPending}
                                className={`flex-1 flex items-center justify-center px-4 py-2 rounded-lg text-white transition-all duration-200 ${
                                    isPending
                                        ? 'bg-green-400 cursor-not-allowed'
                                        : 'bg-green-600 hover:bg-green-700 transform hover:scale-[1.02]'
                                } focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
                            >
                                {isPending ? (
                                    <>
                                        <svg
                                            className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            />
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            />
                                        </svg>
                                        Setting Configs...
                                    </>
                                ) : (
                                    'Set Reward Configs'
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
}

export function SetIncentive({
    adapters,
    tokens,
}: {
    adapters: Array<`0x${string}`>;
    tokens: Array<ApiTokenDto>;
}): $MixedElement {
    const { address } = useAccount();
    const publicClient = usePublicClient();
    const [selectedAdapter, setSelectedAdapter] = useState<
        `0x${string}` | undefined
    >(undefined);
    const [selectedPool, setSelectedPool] = useState<string>('');
    const [fetching, setFetching] = useState(false);
    const [exchangeRates, setExchangeRates] = useState<Record<string, bigint>>(
        {},
    );
    const [poolInfos, setPoolInfos] = useState<Record<string, PoolInfo>>({});
    const [remainings, setRemainings] = useState<Record<string, bigint>>({});
    const [targetApr, setTargetApr] = useState<number | null>(null);
    const [duration, setDuration] = useState<number | null>(null);
    const [incentive, setIncentive] = useState<{
        pool: string;
        token: string;
        amount: bigint;
        duration: string;
        swapPool: string;
    }>({
        pool: '',
        token: '',
        amount: 0n,
        duration: '0',
        swapPool: zeroAddress,
    });
    const [availablePools, setAvailablePools] = useState<
        Array<{
            address: string;
            description?: string;
            tvl?: string;
            token?: string;
        }>
    >([]);

    const DURATION_OPTIONS = [
        { label: '12 Hours', value: 43200 },
        { label: '24 Hours', value: 86400 },
        { label: '48 Hours', value: 172800 },
        { label: '7 Days', value: 604800 },
        { label: '30 Days', value: 2592000 },
    ];

    const { writeContract, writeContractAsync, isPending } = useWriteContract({
        mutation: {
            onError(error) {
                console.error('Error setting incentive:', error);
            },
            onSuccess() {
                setSelectedPool('');
                setTargetApr(null);
                setDuration(null);
                setIncentive({
                    pool: '',
                    token: '',
                    amount: 0n,
                    duration: '0',
                    swapPool: zeroAddress,
                });
            },
        },
    });

    useEffect(() => {
        async function fetchPools(): Promise<void> {
            if (!selectedAdapter || !publicClient) return;
            setFetching(true);
            try {
                const adapterPools =
                    adapterToPools[selectedAdapter as AdapterAddress] || [];
                const poolsWithConfig = [];
                const newExchangeRates: Record<string, bigint> = {};
                const newPoolInfos: Record<string, PoolInfo> = {};

                for (const pool of adapterPools) {
                    const poolConfig = poolConfigs[pool];
                    if (!poolConfig) continue;

                    const poolInfoCalldata =
                        encodeFunctionData({
                            abi: ADAPTER_ABI,
                            functionName: 'getPoolInfo',
                            args: [pool],
                        }) + selectedAdapter.slice(2);

                    const poolInfoResponse = await publicClient.call({
                        to: MAIN_ADDRESS as `0x${string}`,
                        data: poolInfoCalldata as `0x${string}`,
                    });

                    const decodedPoolInfo = decodeFunctionResult({
                        abi: ADAPTER_ABI,
                        functionName: 'getPoolInfo',
                        data: poolInfoResponse.data as `0x${string}`,
                    }) as PoolInfo;

                    const getIncentiveCalldata =
                        encodeFunctionData({
                            abi: ADAPTER_ABI,
                            functionName: 'getPoolIncentive',
                            args: [pool],
                        }) + selectedAdapter.slice(2);

                    const incentiveResponse = await publicClient.call({
                        to: MAIN_ADDRESS as `0x${string}`,
                        data: getIncentiveCalldata as `0x${string}`,
                    });

                    const decodedIncentive = decodeFunctionResult({
                        abi: ADAPTER_ABI,
                        functionName: 'getPoolIncentive',
                        data: incentiveResponse.data as `0x${string}`,
                    }) as IncentiveConfig;

                    setRemainings((prev) => ({
                        ...prev,
                        [pool]: decodedIncentive.remaining,
                    }));

                    let exchangeRate: bigint;
                    switch (selectedAdapter) {
                        case VENUS_ADAPTER_ADDRESS:
                            exchangeRate = await publicClient.readContract({
                                address: pool as `0x${string}`,
                                abi: VTOKEN_ABI,
                                functionName: 'exchangeRateStored',
                            });
                            break;
                        case SYNC_ADAPTER_ADDRESS:
                            const withdrawableAmountCalldata =
                                encodeFunctionData({
                                    abi: ADAPTER_ABI,
                                    functionName: 'getWithdrawableAmount',
                                    args: [
                                        pool,
                                        parseUnits('1', poolConfig.decimals),
                                    ],
                                }) + selectedAdapter.slice(2);

                            const withdrawableAmountResponse =
                                await publicClient.call({
                                    to: MAIN_ADDRESS as `0x${string}`,
                                    data: withdrawableAmountCalldata as `0x${string}`,
                                });

                            const decodedWithdrawableAmount =
                                decodeFunctionResult({
                                    abi: ADAPTER_ABI,
                                    functionName: 'getWithdrawableAmount',
                                    data: withdrawableAmountResponse.data as `0x${string}`,
                                }) as bigint;

                            exchangeRate = decodedWithdrawableAmount;
                            break;
                        default:
                            exchangeRate = BigInt(1e18);
                    }

                    newExchangeRates[pool] = exchangeRate;
                    newPoolInfos[pool] = decodedPoolInfo;

                    const tokenInfo = getTokenInfo(poolConfig.token, tokens);
                    const tvl = formatUnits(
                        (decodedPoolInfo.totalLiquidity * exchangeRate) /
                            BigInt(1e18),
                        tokenInfo?.decimals ?? 18,
                    );

                    poolsWithConfig.push({
                        address: pool,
                        description: poolConfig.description,
                        tvl: `${Number(tvl).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })} ${tokenInfo?.symbol ?? ''}`,
                        token: tokenInfo?.symbol,
                    });
                }

                setExchangeRates(newExchangeRates);
                setPoolInfos(newPoolInfos);
                setAvailablePools(poolsWithConfig);
                setFetching(false);
            } catch (err) {
                setFetching(false);
                console.error('Error fetching pools:', err);
            }
        }

        fetchPools();
    }, [selectedAdapter, publicClient, tokens]);

    useEffect(() => {
        const calculateIncentiveParams = (): void => {
            if (
                !selectedPool ||
                !exchangeRates[selectedPool] ||
                !duration ||
                !targetApr
            )
                return;

            const poolInfo = poolInfos[selectedPool];
            const exchangeRate = exchangeRates[selectedPool];
            const config = poolConfigs[selectedPool];
            if (!config) return;

            const tokenInfo = getTokenInfo(config.token, tokens);
            if (!tokenInfo) return;

            const totalSupply = poolInfo.totalLiquidity * exchangeRate;
            const aprInBasisPoints = BigInt(Math.floor(targetApr * 100));
            const amount =
                (totalSupply * aprInBasisPoints * BigInt(duration)) /
                (31_536_000n * 10000n * BigInt(1e18));

            const remaining = remainings[selectedPool];

            setIncentive({
                pool: selectedPool,
                token: config.token,
                amount: amount - remaining,
                duration: duration.toString(),
                swapPool: zeroAddress,
            });
        };

        calculateIncentiveParams();
    }, [selectedPool, exchangeRates, duration, targetApr, tokens, poolInfos]);

    const handleSubmit = async (
        e: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        e.preventDefault();

        if (
            !selectedAdapter ||
            !selectedPool ||
            !incentive.token ||
            !incentive.amount
        )
            return;

        try {
            const tokenInfo = getTokenInfo(incentive.token, tokens);
            if (!tokenInfo) throw new Error('Token info not found');

            if (!address || !publicClient) return;

            if (
                incentive.token.toLowerCase() !==
                '0x000000000000000000000000000000000000800A'.toLowerCase()
            ) {
                const allowance = await publicClient.readContract({
                    address: incentive.token as `0x${string}`,
                    abi: erc20Abi,
                    functionName: 'allowance',
                    args: [address, MAIN_ADDRESS],
                });

                if (allowance < incentive.amount) {
                    await writeContractAsync({
                        address: incentive.token as `0x${string}`,
                        abi: erc20Abi,
                        functionName: 'approve',
                        args: [MAIN_ADDRESS as `0x${string}`, incentive.amount],
                    });
                }
            }

            writeContract({
                address: MAIN_ADDRESS as `0x${string}`,
                abi: ADAPTER_ABI,
                functionName: 'setIncentive',
                args: [
                    selectedPool as `0x${string}`,
                    incentive.token as `0x${string}`,
                    incentive.amount,
                    BigInt(incentive.duration),
                    incentive.swapPool as `0x${string}`,
                ],
                value:
                    incentive.token.toLowerCase() ===
                    '0x000000000000000000000000000000000000800A'.toLowerCase()
                        ? BigInt(Math.ceil(Number(incentive.amount)))
                        : 0n,
                dataSuffix: selectedAdapter,
            });
        } catch (error) {
            console.error('Error setting incentive:', error);
        }
    };

    return (
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Select Adapter
                        </label>
                        <select
                            value={selectedAdapter || ''}
                            onChange={(e) => {
                                setSelectedAdapter(
                                    e.target.value
                                        ? (e.target.value as `0x${string}`)
                                        : undefined,
                                );
                                setSelectedPool('');
                                setTargetApr(null);
                                setDuration(null);
                            }}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors duration-200"
                        >
                            <option value="">Select an adapter</option>
                            {adapters.map((adapter) => (
                                <option key={adapter} value={adapter}>
                                    {getAdapterName(adapter)} (
                                    {adapter.slice(0, 6)}
                                    ...{adapter.slice(-4)})
                                </option>
                            ))}
                        </select>
                    </div>

                    {selectedAdapter && (
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Select Pool
                            </label>
                            <select
                                value={selectedPool}
                                onChange={(e) =>
                                    setSelectedPool(e.target.value)
                                }
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors duration-200"
                            >
                                <option value="">Select a pool</option>
                                {fetching ? (
                                    <option value="">Fetching pools...</option>
                                ) : (
                                    availablePools.map((pool) => (
                                        <option
                                            key={pool.address}
                                            value={pool.address}
                                        >
                                            {pool.token} - TVL: {pool.tvl}
                                        </option>
                                    ))
                                )}
                            </select>
                        </div>
                    )}
                </div>

                {selectedPool && (
                    <div className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Target APR (%)
                                </label>
                                <div className="relative">
                                    <input
                                        type="number"
                                        value={targetApr ?? ''}
                                        onChange={(e) => {
                                            const value = parseFloat(
                                                e.target.value,
                                            );
                                            setTargetApr(
                                                isNaN(value) ? null : value,
                                            );
                                        }}
                                        step="0.01"
                                        min="0"
                                        required
                                        className="w-full pl-3 pr-12 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors duration-200"
                                        placeholder="Enter target APR"
                                    />
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                        <span className="text-gray-500">%</span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Duration
                                </label>
                                <select
                                    value={duration ?? ''}
                                    onChange={(e) =>
                                        setDuration(Number(e.target.value))
                                    }
                                    required
                                    className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500 transition-colors duration-200"
                                >
                                    <option value="">Select duration</option>
                                    {DURATION_OPTIONS.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {incentive.amount !== 0n && (
                            <div className="mt-4 p-6 bg-gradient-to-br from-purple-50 to-indigo-50 rounded-xl border border-purple-100">
                                <h4 className="text-base font-semibold text-gray-900 mb-4">
                                    Calculated Incentive Parameters
                                </h4>
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="bg-white p-4 rounded-lg shadow-sm">
                                        <label className="block text-sm font-medium text-gray-600 mb-1">
                                            Token Amount
                                        </label>
                                        <div className="text-lg font-semibold text-gray-900">
                                            {((): string => {
                                                const tokenInfo = getTokenInfo(
                                                    incentive.token,
                                                    tokens,
                                                );
                                                const amount = formatUnits(
                                                    BigInt(
                                                        Math.ceil(
                                                            Number(
                                                                incentive.amount,
                                                            ),
                                                        ),
                                                    ),
                                                    tokenInfo?.decimals ?? 18,
                                                );
                                                return `${Number(
                                                    amount,
                                                ).toLocaleString(undefined, {
                                                    maximumFractionDigits: 6,
                                                })} ${tokenInfo?.symbol ?? ''}`;
                                            })()}
                                        </div>
                                    </div>
                                    <div className="bg-white p-4 rounded-lg shadow-sm">
                                        <label className="block text-sm font-medium text-gray-600 mb-1">
                                            Duration
                                        </label>
                                        <div className="text-lg font-semibold text-gray-900">
                                            {DURATION_OPTIONS.find(
                                                (opt) => opt.value === duration,
                                            )?.label ?? `${duration} seconds`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <button
                            type="submit"
                            disabled={isPending || !targetApr || !duration}
                            className={`w-full flex items-center justify-center px-4 py-3 rounded-xl text-white transition-all duration-200 ${
                                isPending || !targetApr || !duration
                                    ? 'bg-purple-400 cursor-not-allowed'
                                    : 'bg-purple-600 hover:bg-purple-700 transform hover:scale-[1.01] shadow-sm hover:shadow'
                            } focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2`}
                        >
                            {isPending ? (
                                <>
                                    <svg
                                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        />
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        />
                                    </svg>
                                    Setting Incentive...
                                </>
                            ) : (
                                'Set Incentive'
                            )}
                        </button>
                    </div>
                )}
            </form>
        </div>
    );
}
