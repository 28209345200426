/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { ApiTokenDto } from 'api/types';
import { useEffect, useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    ADAPTER_ABI,
    type AdapterAddress,
    MAIN_ABI,
    MAIN_ADDRESS,
    SYNC_ADAPTER_ADDRESS,
    VENUS_ADAPTER_ADDRESS,
    VTOKEN_ABI,
    adapterToPools,
    getAdapterName,
    getTokenInfo,
    poolToAdapterSuffix,
} from 'utils/clagg';
import {
    type Hex,
    decodeFunctionResult,
    encodeFunctionData,
    formatUnits,
    parseUnits,
    zeroAddress,
} from 'viem';
import {
    useAccount,
    usePublicClient,
    useReadContract,
    useWriteContract,
} from 'wagmi';

import type {
    IncentiveConfig,
    PoolConfig,
    PoolInfo,
    RewardConfig,
} from '../../../utils/clagg/types';
import {
    IncentiveConfigCard,
    PoolConfigCard,
    PoolInfoCard,
    RewardConfigCard,
    UserInfoCard,
} from './PoolCards';

const formatTokenAmount = (
    amount: bigint,
    decimals: number = 18,
    symbol?: string,
    maxFractionDigits: number = 2,
): string => {
    const formatted = Number(formatUnits(amount, decimals)).toLocaleString(
        undefined,
        {
            maximumFractionDigits: maxFractionDigits,
            minimumFractionDigits: 2,
        },
    );
    return symbol ? `${formatted} ${symbol}` : formatted;
};

const ErrorCard = ({ message }: { message: string }): $MixedElement => (
    <div className="bg-red-50 rounded-xl p-6 border border-red-100">
        <div className="flex items-center space-x-3">
            <div className="flex-shrink-0">
                <svg
                    className="h-5 w-5 text-red-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            <div className="text-sm text-red-600 font-medium">{message}</div>
        </div>
    </div>
);

const LoadingSkeleton = (): $MixedElement => (
    <div className="space-y-8 animate-pulse">
        {[1, 2].map((i) => (
            <div
                key={i}
                className="bg-white rounded-xl shadow-sm p-6 border border-gray-100"
            >
                <div className="flex items-center gap-4 mb-6">
                    <div className="h-12 w-12 rounded-full bg-gray-200" />
                    <div className="flex-1">
                        <div className="h-5 w-32 bg-gray-200 rounded mb-2" />
                        <div className="h-4 w-48 bg-gray-200 rounded" />
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {[1, 2, 3].map((j) => (
                        <div key={j} className="bg-gray-50 rounded-xl p-6">
                            <div className="flex items-center gap-3 mb-4">
                                <div className="h-8 w-8 rounded-full bg-gray-200" />
                                <div className="h-5 w-24 bg-gray-200 rounded" />
                            </div>
                            <div className="space-y-4">
                                <div className="h-24 bg-gray-200 rounded-lg" />
                                <div className="h-24 bg-gray-200 rounded-lg" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ))}
    </div>
);

export function GetPools({
    tokens,
}: {
    tokens: Array<ApiTokenDto>;
}): $MixedElement {
    const publicClient = usePublicClient();
    const { isConnected } = useAccount();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedAdapter, setSelectedAdapter] = useState<string>('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [poolData, setPoolData] = useState<{
        poolInfos: Array<PoolInfo>;
        poolConfigs: Array<PoolConfig>;
        rewardConfigs: Array<Array<RewardConfig>>;
        incentiveConfigs: Array<IncentiveConfig>;
        poolAddresses: Array<string>;
        poolIndices: Record<string, number>;
        exchangeRates: Record<string, bigint>;
    }>({
        poolInfos: [],
        poolConfigs: [],
        rewardConfigs: [],
        incentiveConfigs: [],
        poolAddresses: [],
        poolIndices: {},
        exchangeRates: {},
    });
    const [apyData, setApyData] = useState<Record<string, bigint>>({});

    const { data: adapters = [] } = useReadContract({
        address: MAIN_ADDRESS as `0x${string}`,
        abi: MAIN_ABI,
        functionName: 'listAdapters',
    });

    const { poolToAdapter, allPools } = useMemo(() => {
        const poolToAdapter = adapters.reduce((acc, adapter) => {
            const adapterPools =
                adapterToPools[adapter as AdapterAddress] || [];
            adapterPools.forEach((pool) => {
                acc[pool] = adapter as `0x${string}`;
            });
            return acc;
        }, {} as Record<string, `0x${string}`>);

        const allPools = adapters.flatMap(
            (adapter) => adapterToPools[adapter as AdapterAddress] || [],
        );

        return { poolToAdapter, allPools };
    }, [adapters]);

    useEffect(() => {
        if (allPools.length === 0 || !publicClient) return;

        let mounted = true;
        const fetchData = async (): Promise<void> => {
            setIsLoading(true);
            setError(null);

            try {
                const indices: Record<string, number> = {};
                const poolInfos: Array<PoolInfo> = [];
                const poolConfigs: Array<PoolConfig> = [];
                const rewardConfigs: Array<Array<RewardConfig>> = [];
                const incentiveConfigs: Array<IncentiveConfig> = [];
                const poolAddresses: Array<string> = [];
                const apys: Record<string, bigint> = {};
                const exchangeRates: Record<string, bigint> = {};

                for (const [, pool] of allPools.entries()) {
                    if (!mounted) return;

                    const adapter = poolToAdapter[pool];
                    const adapterSuffix = adapter.slice(2);

                    try {
                        // Get Pool Config first to check if it's configured
                        const poolConfigCalldata =
                            encodeFunctionData({
                                abi: ADAPTER_ABI,
                                functionName: 'getPoolConfig',
                                args: [pool],
                            }) + adapterSuffix;

                        const poolConfigResponse = await publicClient.call({
                            to: MAIN_ADDRESS as `0x${string}`,
                            data: poolConfigCalldata as `0x${string}`,
                        });

                        const decodedPoolConfig = decodeFunctionResult({
                            abi: ADAPTER_ABI,
                            functionName: 'getPoolConfig',
                            data: poolConfigResponse.data as `0x${string}`,
                        }) as PoolConfig;

                        // Only proceed if the pool is configured
                        if (decodedPoolConfig.token !== zeroAddress) {
                            // Get Pool Info
                            const poolInfoCalldata =
                                encodeFunctionData({
                                    abi: ADAPTER_ABI,
                                    functionName: 'getPoolInfo',
                                    args: [pool],
                                }) + adapterSuffix;

                            const poolInfoResponse = await publicClient.call({
                                to: MAIN_ADDRESS as `0x${string}`,
                                data: poolInfoCalldata as `0x${string}`,
                            });

                            // Get Reward Configs
                            const rewardConfigCalldata =
                                encodeFunctionData({
                                    abi: ADAPTER_ABI,
                                    functionName: 'getPoolRewardConfigs',
                                    args: [pool],
                                }) + adapterSuffix;

                            const rewardConfigResponse =
                                await publicClient.call({
                                    to: MAIN_ADDRESS as `0x${string}`,
                                    data: rewardConfigCalldata as `0x${string}`,
                                });

                            if (!mounted) return;

                            const decodedPoolInfo = decodeFunctionResult({
                                abi: ADAPTER_ABI,
                                functionName: 'getPoolInfo',
                                data: poolInfoResponse.data as `0x${string}`,
                            }) as PoolInfo;

                            const decodedRewardConfig = decodeFunctionResult({
                                abi: ADAPTER_ABI,
                                functionName: 'getPoolRewardConfigs',
                                data: rewardConfigResponse.data as `0x${string}`,
                            }) as Array<RewardConfig>;

                            // Get Incentive Configs
                            const incentiveConfigCalldata =
                                encodeFunctionData({
                                    abi: ADAPTER_ABI,
                                    functionName: 'getPoolIncentive',
                                    args: [pool],
                                }) + adapterSuffix;

                            const incentiveConfigResponse =
                                await publicClient.call({
                                    to: MAIN_ADDRESS as `0x${string}`,
                                    data: incentiveConfigCalldata as `0x${string}`,
                                });

                            const decodedIncentiveConfig = decodeFunctionResult(
                                {
                                    abi: ADAPTER_ABI,
                                    functionName: 'getPoolIncentive',
                                    data: incentiveConfigResponse.data as `0x${string}`,
                                },
                            ) as IncentiveConfig;

                            if (adapter == VENUS_ADAPTER_ADDRESS) {
                                const exchangeRate =
                                    await publicClient.readContract({
                                        address: pool,
                                        abi: VTOKEN_ABI,
                                        functionName: 'exchangeRateStored',
                                    });

                                exchangeRates[pool] = exchangeRate;
                            } else if (adapter === SYNC_ADAPTER_ADDRESS) {
                                const withdrawableAmountCalldata =
                                    encodeFunctionData({
                                        abi: ADAPTER_ABI,
                                        functionName: 'getWithdrawableAmount',
                                        args: [pool, parseUnits('1', 18)],
                                    }) + SYNC_ADAPTER_ADDRESS.slice(2);

                                const withdrawableAmountResponse =
                                    await publicClient.call({
                                        to: MAIN_ADDRESS as `0x${string}`,
                                        data: withdrawableAmountCalldata as `0x${string}`,
                                    });

                                const decodedWithdrawableAmount =
                                    decodeFunctionResult({
                                        abi: ADAPTER_ABI,
                                        functionName: 'getWithdrawableAmount',
                                        data: withdrawableAmountResponse.data as `0x${string}`,
                                    }) as bigint;

                                exchangeRates[pool] = decodedWithdrawableAmount;
                            }

                            indices[pool] = poolAddresses.length;
                            poolInfos.push(decodedPoolInfo);
                            poolConfigs.push(decodedPoolConfig);
                            rewardConfigs.push(decodedRewardConfig);
                            incentiveConfigs.push(decodedIncentiveConfig);
                            poolAddresses.push(pool);
                        }
                    } catch (err) {
                        console.error(
                            `Error fetching data for pool ${pool}:`,
                            err,
                        );
                    }
                }

                if (!mounted) return;

                setApyData(apys);
                setPoolData({
                    poolInfos,
                    poolConfigs,
                    rewardConfigs,
                    incentiveConfigs,
                    poolAddresses,
                    poolIndices: indices,
                    exchangeRates,
                });
            } catch (err) {
                if (mounted) {
                    setError(
                        err instanceof Error
                            ? err.message
                            : 'An error occurred while fetching pool data',
                    );
                }
            } finally {
                if (mounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            mounted = false;
        };
    }, [allPools, publicClient, poolToAdapter]);

    const filteredPools = useMemo(() => {
        let filtered = poolData.poolAddresses;

        if (selectedAdapter !== 'all') {
            filtered = filtered.filter(
                (pool) => poolToAdapter[pool] === selectedAdapter,
            );
        }

        if (searchTerm) {
            const searchLower = searchTerm.toLowerCase();
            filtered = filtered.filter((pool) => {
                const poolIndex = poolData.poolIndices[pool];
                const poolConfig = poolData.poolConfigs[poolIndex];
                const tokenInfo = poolConfig
                    ? getTokenInfo(poolConfig.token, tokens)
                    : undefined;

                return (
                    pool.toLowerCase().includes(searchLower) ||
                    tokenInfo?.symbol.toLowerCase().includes(searchLower) ||
                    tokenInfo?.name.toLowerCase().includes(searchLower)
                );
            });
        }

        return filtered;
    }, [poolData, selectedAdapter, searchTerm, poolToAdapter, tokens]);

    const { writeContract, status } = useWriteContract();
    const isCompounding = status === 'pending';

    const handleCompound = async (pool: string): Promise<void> => {
        writeContract({
            address: MAIN_ADDRESS as `0x${string}`,
            abi: ADAPTER_ABI,
            functionName: 'compound',
            args: [pool as Hex],
            dataSuffix: poolToAdapterSuffix[pool] as `0x${string}`,
        });
    };

    if (allPools.length === 0) {
        return (
            <div className="text-center py-12 bg-white rounded-xl shadow-sm border border-gray-100">
                <div className="h-16 w-16 mx-auto mb-4 rounded-full bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center">
                    <svg
                        className="h-8 w-8 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                        />
                    </svg>
                </div>
                <h3 className="text-lg font-medium text-gray-900 mb-1">
                    No Pools Found
                </h3>
                <p className="text-gray-500">
                    Start by adding a new pool or connecting to a different
                    network.
                </p>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="flex flex-col md:flex-row gap-4 sticky top-0 bg-white z-10 p-4 -m-4 mb-2 border-b border-gray-100 backdrop-blur-sm bg-white/90">
                <div className="flex-1">
                    <div className="relative group">
                        <input
                            type="text"
                            placeholder="Search by pool address or token..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-xl focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200 bg-gray-50 group-hover:bg-white"
                        />
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <svg
                                className="h-5 w-5 text-gray-400 group-hover:text-indigo-500 transition-colors duration-200"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-72">
                    <select
                        value={selectedAdapter}
                        onChange={(e) => setSelectedAdapter(e.target.value)}
                        className="w-full px-3 py-2.5 border border-gray-300 rounded-xl focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200 bg-gray-50 hover:bg-white cursor-pointer"
                    >
                        <option value="all">All Adapters</option>
                        {adapters.map((adapter) => (
                            <option key={adapter} value={adapter}>
                                {getAdapterName(adapter as `0x${string}`)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {isLoading ? (
                <LoadingSkeleton />
            ) : error ? (
                <ErrorCard message={error} />
            ) : filteredPools.length === 0 ? (
                <div className="text-center py-8 bg-gray-50 rounded-xl border border-gray-200">
                    <div className="h-12 w-12 mx-auto mb-4 rounded-full bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center">
                        <svg
                            className="h-6 w-6 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>
                    <h3 className="text-sm font-medium text-gray-900">
                        No matching pools found
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                        Try adjusting your search or filter criteria
                    </p>
                </div>
            ) : (
                <div className="grid grid-cols-1 gap-6">
                    {filteredPools.map((pool) => {
                        const poolIndex = poolData.poolIndices[pool];
                        const poolConfig = poolData.poolConfigs[poolIndex];
                        const tokenInfo = poolConfig
                            ? getTokenInfo(poolConfig.token, tokens)
                            : undefined;
                        const poolAsHex = pool as `0x${string}`;
                        const adapter = poolToAdapter[pool];
                        const adapterName = getAdapterName(
                            adapter as `0x${string}`,
                        );

                        const exchangeRate = poolData.exchangeRates[pool];

                        if (!poolConfig) return null;

                        return (
                            <div
                                key={pool}
                                className="bg-white rounded-2xl shadow-md border border-gray-100 overflow-hidden hover:border-indigo-300 transition-all duration-300 transform hover:scale-[1.02] hover:shadow-xl"
                            >
                                <div className="p-8">
                                    <div className="flex items-center justify-between mb-8">
                                        <div className="flex items-center gap-5">
                                            <div
                                                className={`h-14 w-14 rounded-2xl ${
                                                    tokenInfo?.icon
                                                        ? ''
                                                        : 'bg-gradient-to-br from-indigo-200 to-indigo-300'
                                                } flex items-center justify-center shadow-md transition-transform duration-300 hover:scale-110`}
                                            >
                                                {tokenInfo?.icon ? (
                                                    <img
                                                        src={tokenInfo.icon}
                                                        alt={tokenInfo.symbol}
                                                        className="w-12 h-12 rounded-xl"
                                                    />
                                                ) : (
                                                    <svg
                                                        className="w-7 h-7 text-indigo-600"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                                                        />
                                                    </svg>
                                                )}
                                            </div>
                                            <div>
                                                <h3 className="text-2xl font-bold text-gray-900 mb-2">
                                                    {tokenInfo?.symbol ||
                                                        'Pool'}{' '}
                                                    on {adapterName}
                                                </h3>
                                                <div className="flex items-center gap-3">
                                                    <code
                                                        className="text-sm bg-gray-50 px-3 py-1.5 rounded-lg text-gray-600 font-mono transition-all duration-200 hover:bg-gray-100 hover:shadow-inner cursor-pointer border border-gray-200"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(
                                                                pool,
                                                            );
                                                        }}
                                                        title="Click to copy"
                                                    >
                                                        {`${pool.slice(
                                                            0,
                                                            6,
                                                        )}...${pool.slice(-4)}`}
                                                    </code>
                                                    {tokenInfo?.name && (
                                                        <>
                                                            <span className="text-sm text-gray-400">
                                                                •
                                                            </span>
                                                            <span className="text-sm font-medium text-gray-600">
                                                                {tokenInfo.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {isConnected && (
                                            <div className="flex items-center gap-2">
                                                <button
                                                    className={`inline-flex items-center gap-2 px-3 py-1.5 text-sm font-medium rounded-lg transition-all duration-200
                                                        ${
                                                            isCompounding
                                                                ? 'bg-indigo-100 text-indigo-600 cursor-wait'
                                                                : 'bg-indigo-500 text-white hover:bg-indigo-600'
                                                        } disabled:opacity-50`}
                                                    onClick={async () =>
                                                        handleCompound(pool)
                                                    }
                                                    disabled={isCompounding}
                                                    title="Compound your earned rewards"
                                                >
                                                    {isCompounding ? (
                                                        <>
                                                            <svg
                                                                className="animate-spin h-4 w-4"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <circle
                                                                    className="opacity-25"
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    strokeWidth="4"
                                                                ></circle>
                                                                <path
                                                                    className="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                ></path>
                                                            </svg>
                                                            <span>
                                                                Compounding
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <span>Compound</span>
                                                    )}
                                                </button>
                                            </div>
                                        )}
                                        {apyData[pool]?.toString() && (
                                            <div className="flex items-center bg-gradient-to-br from-green-50 to-emerald-100 px-6 py-3 rounded-2xl shadow-md border border-green-200 transition-all duration-300 hover:shadow-lg hover:scale-105">
                                                <svg
                                                    className="w-6 h-6 text-green-600 mr-3"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                                                    />
                                                </svg>
                                                <div>
                                                    <div className="text-sm text-green-700 font-medium">
                                                        Annual Yield
                                                    </div>
                                                    <div className="text-xl font-bold text-green-800">
                                                        {(
                                                            Number(
                                                                apyData[pool],
                                                            ) / 100
                                                        ).toFixed(2)}
                                                        %
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                                        <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-2xl p-6 hover:from-gray-100 hover:to-gray-200 transition-all duration-300 group shadow-sm hover:shadow-md">
                                            <div className="flex items-center gap-4 mb-5">
                                                <div className="h-10 w-10 rounded-xl bg-gradient-to-br from-blue-200 to-blue-300 flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300 shadow-sm">
                                                    <svg
                                                        className="w-5 h-5 text-blue-700"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        />
                                                    </svg>
                                                </div>
                                                <h4 className="text-lg font-semibold text-gray-900">
                                                    Pool Info
                                                </h4>
                                            </div>
                                            <PoolInfoCard
                                                info={
                                                    poolData.poolInfos[
                                                        poolIndex
                                                    ]
                                                }
                                                poolAddress={poolAsHex}
                                                adapterName={adapterName}
                                                tokenInfo={tokenInfo}
                                                exchangeRate={exchangeRate}
                                            />
                                        </div>

                                        <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-2xl p-6 hover:from-gray-100 hover:to-gray-200 transition-all duration-300 group shadow-sm hover:shadow-md">
                                            <div className="flex items-center gap-4 mb-5">
                                                <div className="h-10 w-10 rounded-xl bg-gradient-to-br from-purple-200 to-purple-300 flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300 shadow-sm">
                                                    <svg
                                                        className="w-5 h-5 text-purple-700"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                                        />
                                                    </svg>
                                                </div>
                                                <h4 className="text-lg font-semibold text-gray-900">
                                                    Pool Config
                                                </h4>
                                            </div>
                                            <PoolConfigCard
                                                config={poolConfig}
                                                token={tokenInfo}
                                            />
                                        </div>

                                        <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-2xl p-6 hover:from-gray-100 hover:to-gray-200 transition-all duration-300 group shadow-sm hover:shadow-md">
                                            <div className="flex items-center gap-4 mb-5">
                                                <div className="h-10 w-10 rounded-xl bg-gradient-to-br from-green-200 to-green-300 flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300 shadow-sm">
                                                    <svg
                                                        className="w-5 h-5 text-green-700"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        />
                                                    </svg>
                                                </div>
                                                <h4 className="text-lg font-semibold text-gray-900">
                                                    Reward Configs
                                                </h4>
                                            </div>
                                            <RewardConfigCard
                                                configs={
                                                    poolData.rewardConfigs[
                                                        poolIndex
                                                    ]
                                                }
                                                tokens={tokens}
                                            />
                                        </div>

                                        <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-2xl p-6 hover:from-gray-100 hover:to-gray-200 transition-all duration-300 group shadow-sm hover:shadow-md">
                                            <div className="flex items-center justify-between mb-5">
                                                <div className="flex items-center gap-4">
                                                    <div className="h-10 w-10 rounded-xl bg-gradient-to-br from-purple-200 to-purple-300 flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300 shadow-sm">
                                                        <svg
                                                            className="w-5 h-5 text-purple-700"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth={2}
                                                                d="M13 10V3L4 14h7v7l9-11h-7z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-lg font-semibold text-gray-900">
                                                            Incentive Program
                                                        </h4>
                                                        <p className="text-sm text-gray-500">
                                                            Active rewards and
                                                            incentives for
                                                            liquidity providers
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <IncentiveConfigCard
                                                config={
                                                    poolData.incentiveConfigs[
                                                        poolIndex
                                                    ]
                                                }
                                                tokens={tokens}
                                            />
                                        </div>

                                        <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-2xl p-6 hover:from-gray-100 hover:to-gray-200 transition-all duration-300 group shadow-sm hover:shadow-md">
                                            <div className="flex items-center gap-4 mb-5">
                                                <div className="h-10 w-10 rounded-xl bg-gradient-to-br from-blue-200 to-blue-300 flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300 shadow-sm">
                                                    <svg
                                                        className="w-5 h-5 text-blue-700"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                        />
                                                    </svg>
                                                </div>
                                                <h4 className="text-lg font-semibold text-gray-900">
                                                    User Information
                                                </h4>
                                            </div>
                                            <UserInfoCard
                                                poolAddress={
                                                    pool as `0x${string}`
                                                }
                                                adapterAddress={adapter}
                                                token={tokenInfo}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export { formatTokenAmount, getTokenInfo };
