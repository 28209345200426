/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export const MAIN_ABI = [
    {
        inputs: [
            {
                internalType: 'uint96',
                name: 'timelock_',
                type: 'uint96',
            },
            {
                internalType: 'address',
                name: 'feeVault_',
                type: 'address',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        inputs: [],
        name: 'ADDRESS_ALREADY_EXISTS',
        type: 'error',
    },
    {
        inputs: [],
        name: 'ADDRESS_NOT_EXISTS',
        type: 'error',
    },
    {
        inputs: [],
        name: 'INVALID_ADDRESS',
        type: 'error',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'adapter',
                type: 'address',
            },
        ],
        name: 'AdapterAdded',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'adapter',
                type: 'address',
            },
        ],
        name: 'AdapterRemoved',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'adapter',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'expiry',
                type: 'uint256',
            },
        ],
        name: 'AdapterRequested',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'feeVault',
                type: 'address',
            },
        ],
        name: 'FeeVaultSet',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'incentiveVault',
                type: 'address',
            },
        ],
        name: 'IncentiveVaultSet',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
    },
    {
        stateMutability: 'payable',
        type: 'fallback',
    },
    {
        inputs: [],
        name: 'TIMELOCK',
        outputs: [
            {
                internalType: 'uint96',
                name: '',
                type: 'uint96',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'adapter',
                type: 'address',
            },
        ],
        name: 'addAdapter',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'adapter',
                type: 'address',
            },
        ],
        name: 'addRequestDate',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'feeVault',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'incentiveVault',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'adapter',
                type: 'address',
            },
        ],
        name: 'isAdapter',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'listAdapters',
        outputs: [
            {
                internalType: 'address[]',
                name: '',
                type: 'address[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
            },
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]',
            },
            {
                internalType: 'bytes',
                name: '',
                type: 'bytes',
            },
        ],
        name: 'onERC1155BatchReceived',
        outputs: [
            {
                internalType: 'bytes4',
                name: '',
                type: 'bytes4',
            },
        ],
        stateMutability: 'pure',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
            {
                internalType: 'bytes',
                name: '',
                type: 'bytes',
            },
        ],
        name: 'onERC1155Received',
        outputs: [
            {
                internalType: 'bytes4',
                name: '',
                type: 'bytes4',
            },
        ],
        stateMutability: 'pure',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
            {
                internalType: 'bytes',
                name: '',
                type: 'bytes',
            },
        ],
        name: 'onERC721Received',
        outputs: [
            {
                internalType: 'bytes4',
                name: '',
                type: 'bytes4',
            },
        ],
        stateMutability: 'pure',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'adapter',
                type: 'address',
            },
        ],
        name: 'removeAdapter',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'adapter',
                type: 'address',
            },
        ],
        name: 'requestAddAdapter',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'rescue',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newFeeVault',
                type: 'address',
            },
        ],
        name: 'setFeeVault',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'newIncentiveVault',
                type: 'address',
            },
        ],
        name: 'setIncentiveVault',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_owner',
                type: 'address',
            },
        ],
        name: 'setOwner',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'bytes4',
                name: 'interfaceId',
                type: 'bytes4',
            },
        ],
        name: 'supportsInterface',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        stateMutability: 'payable',
        type: 'receive',
    },
] as const;

export const ADAPTER_ABI = [
    {
        inputs: [
            {
                internalType: 'address',
                name: 'claveRegistry_',
                type: 'address',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'rewardAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'incentiveAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'liquidityAdded',
                type: 'uint256',
            },
        ],
        name: 'Compound',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'shares',
                type: 'uint256',
            },
        ],
        name: 'Deposit',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
            },
        ],
        name: 'IncentiveSet',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newStaking',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'oldStaking',
                type: 'address',
            },
        ],
        name: 'Migrated',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint48',
                name: 'performanceFee',
                type: 'uint48',
            },
            {
                indexed: false,
                internalType: 'uint48',
                name: 'nonClaveFee',
                type: 'uint48',
            },
        ],
        name: 'PoolConfigSet',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'reward',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'intermediateToken',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'swapPool1',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'swapPool2',
                        type: 'address',
                    },
                    {
                        internalType: 'uint256',
                        name: 'minAmountOut',
                        type: 'uint256',
                    },
                ],
                indexed: false,
                internalType: 'struct RewardConfig[]',
                name: 'rewardConfigs',
                type: 'tuple[]',
            },
        ],
        name: 'RewardConfigsSet',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'staking',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'otherToken',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'bool',
                name: 'isToken0',
                type: 'bool',
            },
        ],
        name: 'StakingConfigSet',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'shares',
                type: 'uint256',
            },
        ],
        name: 'Withdraw',
        type: 'event',
    },
    {
        inputs: [],
        name: 'claveRegistry',
        outputs: [
            {
                internalType: 'contract IClaveRegistry',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
        ],
        name: 'compound',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'minLiquidity',
                type: 'uint256',
            },
        ],
        name: 'deposit',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
        ],
        name: 'getPoolConfig',
        outputs: [
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'token',
                        type: 'address',
                    },
                    {
                        internalType: 'uint48',
                        name: 'performanceFee',
                        type: 'uint48',
                    },
                    {
                        internalType: 'uint48',
                        name: 'nonClaveFee',
                        type: 'uint48',
                    },
                ],
                internalType: 'struct PoolConfig',
                name: 'poolConfig',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
        ],
        name: 'getPoolIncentive',
        outputs: [
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'token',
                        type: 'address',
                    },
                    {
                        internalType: 'uint256',
                        name: 'remaining',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'lastUpdatedAt',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'expiry',
                        type: 'uint256',
                    },
                    {
                        internalType: 'address',
                        name: 'swapPool',
                        type: 'address',
                    },
                ],
                internalType: 'struct Incentive',
                name: 'poolIncentive',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
        ],
        name: 'getPoolInfo',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'totalLiquidity',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'totalSupply',
                        type: 'uint256',
                    },
                ],
                internalType: 'struct PoolInfo',
                name: 'poolInfo',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
        ],
        name: 'getPoolRewardConfigs',
        outputs: [
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'reward',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'intermediateToken',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'swapPool1',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'swapPool2',
                        type: 'address',
                    },
                    {
                        internalType: 'uint256',
                        name: 'minAmountOut',
                        type: 'uint256',
                    },
                ],
                internalType: 'struct RewardConfig[]',
                name: 'rewardConfigs',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
        ],
        name: 'getPoolTvl',
        outputs: [
            {
                internalType: 'uint256[]',
                name: 'tvl',
                type: 'uint256[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
        ],
        name: 'getStakeLimit',
        outputs: [
            {
                internalType: 'uint256',
                name: 'stakeLimit',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
        ],
        name: 'getStakingConfig',
        outputs: [
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'otherToken',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'staking',
                        type: 'address',
                    },
                    {
                        internalType: 'bool',
                        name: 'isToken0',
                        type: 'bool',
                    },
                ],
                internalType: 'struct StakingConfig',
                name: 'stakingConfig',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
        ],
        name: 'getUserInfo',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'balanceOf',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'deposited',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'withdrawn',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'gained',
                        type: 'uint256',
                    },
                ],
                internalType: 'struct UserInfo',
                name: 'userInfo',
                type: 'tuple',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'shares',
                type: 'uint256',
            },
        ],
        name: 'getWithdrawableAmount',
        outputs: [
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'user',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
        ],
        name: 'getWithdrawableAmountUser',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'newStaking',
                type: 'address',
            },
        ],
        name: 'migrate',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'duration',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: 'swapPool',
                type: 'address',
            },
        ],
        name: 'setIncentive',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                internalType: 'uint48',
                name: 'performanceFee',
                type: 'uint48',
            },
            {
                internalType: 'uint48',
                name: 'nonClaveFee',
                type: 'uint48',
            },
        ],
        name: 'setPoolConfig',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'reward',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'intermediateToken',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'swapPool1',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'swapPool2',
                        type: 'address',
                    },
                    {
                        internalType: 'uint256',
                        name: 'minAmountOut',
                        type: 'uint256',
                    },
                ],
                internalType: 'struct RewardConfig[]',
                name: '_rewardConfigs',
                type: 'tuple[]',
            },
        ],
        name: 'setRewardConfigs',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'staking',
                type: 'address',
            },
        ],
        name: 'setStakingConfig',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'syncRouter',
        outputs: [
            {
                internalType: 'contract ISyncRouter',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'pool',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'shares',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'minReceived',
                type: 'uint256',
            },
        ],
        name: 'withdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
] as const;

export const VTOKEN_ABI = [
    {
        inputs: [
            { internalType: 'bool', name: 'timeBased_', type: 'bool' },
            {
                internalType: 'uint256',
                name: 'blocksPerYear_',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'maxBorrowRateMantissa_',
                type: 'uint256',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'constructor',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'actualAddAmount',
                type: 'uint256',
            },
        ],
        name: 'AddReservesFactorFreshCheck',
        type: 'error',
    },
    { inputs: [], name: 'BorrowCashNotAvailable', type: 'error' },
    { inputs: [], name: 'BorrowFreshnessCheck', type: 'error' },
    { inputs: [], name: 'DelegateNotApproved', type: 'error' },
    { inputs: [], name: 'ForceLiquidateBorrowUnauthorized', type: 'error' },
    { inputs: [], name: 'HealBorrowUnauthorized', type: 'error' },
    { inputs: [], name: 'InvalidBlocksPerYear', type: 'error' },
    { inputs: [], name: 'InvalidTimeBasedConfiguration', type: 'error' },
    {
        inputs: [
            { internalType: 'uint256', name: 'errorCode', type: 'uint256' },
        ],
        name: 'LiquidateAccrueCollateralInterestFailed',
        type: 'error',
    },
    { inputs: [], name: 'LiquidateCloseAmountIsUintMax', type: 'error' },
    { inputs: [], name: 'LiquidateCloseAmountIsZero', type: 'error' },
    { inputs: [], name: 'LiquidateCollateralFreshnessCheck', type: 'error' },
    { inputs: [], name: 'LiquidateFreshnessCheck', type: 'error' },
    { inputs: [], name: 'LiquidateLiquidatorIsBorrower', type: 'error' },
    { inputs: [], name: 'LiquidateSeizeLiquidatorIsBorrower', type: 'error' },
    { inputs: [], name: 'MintFreshnessCheck', type: 'error' },
    { inputs: [], name: 'ProtocolSeizeShareTooBig', type: 'error' },
    { inputs: [], name: 'RedeemFreshnessCheck', type: 'error' },
    { inputs: [], name: 'RedeemTransferOutNotPossible', type: 'error' },
    { inputs: [], name: 'ReduceReservesCashNotAvailable', type: 'error' },
    { inputs: [], name: 'ReduceReservesCashValidation', type: 'error' },
    { inputs: [], name: 'ReduceReservesFreshCheck', type: 'error' },
    { inputs: [], name: 'RepayBorrowFreshnessCheck', type: 'error' },
    { inputs: [], name: 'SetInterestRateModelFreshCheck', type: 'error' },
    { inputs: [], name: 'SetReserveFactorBoundsCheck', type: 'error' },
    { inputs: [], name: 'SetReserveFactorFreshCheck', type: 'error' },
    { inputs: [], name: 'TransferNotAllowed', type: 'error' },
    {
        inputs: [
            { internalType: 'address', name: 'sender', type: 'address' },
            {
                internalType: 'address',
                name: 'calledContract',
                type: 'address',
            },
            { internalType: 'string', name: 'methodSignature', type: 'string' },
        ],
        name: 'Unauthorized',
        type: 'error',
    },
    { inputs: [], name: 'ZeroAddressNotAllowed', type: 'error' },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'cashPrior',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'interestAccumulated',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'borrowIndex',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'totalBorrows',
                type: 'uint256',
            },
        ],
        name: 'AccrueInterest',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'Approval',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'borrower',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'badDebtDelta',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'badDebtOld',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'badDebtNew',
                type: 'uint256',
            },
        ],
        name: 'BadDebtIncreased',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'badDebtOld',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'badDebtNew',
                type: 'uint256',
            },
        ],
        name: 'BadDebtRecovered',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'borrower',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'borrowAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'accountBorrows',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'totalBorrows',
                type: 'uint256',
            },
        ],
        name: 'Borrow',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'payer',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'borrower',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'repayAmount',
                type: 'uint256',
            },
        ],
        name: 'HealBorrow',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint8',
                name: 'version',
                type: 'uint8',
            },
        ],
        name: 'Initialized',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'liquidator',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'borrower',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'repayAmount',
                type: 'uint256',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'vTokenCollateral',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'seizeTokens',
                type: 'uint256',
            },
        ],
        name: 'LiquidateBorrow',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'minter',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'mintAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'mintTokens',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'accountBalance',
                type: 'uint256',
            },
        ],
        name: 'Mint',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'oldAccessControlManager',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'newAccessControlManager',
                type: 'address',
            },
        ],
        name: 'NewAccessControlManager',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'contract ComptrollerInterface',
                name: 'oldComptroller',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'contract ComptrollerInterface',
                name: 'newComptroller',
                type: 'address',
            },
        ],
        name: 'NewComptroller',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'contract InterestRateModel',
                name: 'oldInterestRateModel',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'contract InterestRateModel',
                name: 'newInterestRateModel',
                type: 'address',
            },
        ],
        name: 'NewMarketInterestRateModel',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'oldProtocolSeizeShareMantissa',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'newProtocolSeizeShareMantissa',
                type: 'uint256',
            },
        ],
        name: 'NewProtocolSeizeShare',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'oldProtocolShareReserve',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newProtocolShareReserve',
                type: 'address',
            },
        ],
        name: 'NewProtocolShareReserve',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'oldReduceReservesBlockOrTimestampDelta',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'newReduceReservesBlockOrTimestampDelta',
                type: 'uint256',
            },
        ],
        name: 'NewReduceReservesBlockDelta',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'oldReserveFactorMantissa',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'newReserveFactorMantissa',
                type: 'uint256',
            },
        ],
        name: 'NewReserveFactor',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'oldShortfall',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newShortfall',
                type: 'address',
            },
        ],
        name: 'NewShortfallContract',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnershipTransferStarted',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address',
            },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'ProtocolSeize',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'redeemer',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'redeemAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'redeemTokens',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'accountBalance',
                type: 'uint256',
            },
        ],
        name: 'Redeem',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'payer',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'borrower',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'repayAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'accountBorrows',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'totalBorrows',
                type: 'uint256',
            },
        ],
        name: 'RepayBorrow',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'benefactor',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'addAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'newTotalReserves',
                type: 'uint256',
            },
        ],
        name: 'ReservesAdded',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'protocolShareReserve',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'reduceAmount',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'newTotalReserves',
                type: 'uint256',
            },
        ],
        name: 'SpreadReservesReduced',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
        ],
        name: 'SweepToken',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'Transfer',
        type: 'event',
    },
    {
        inputs: [],
        name: 'NO_ERROR',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'acceptOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'accessControlManager',
        outputs: [
            {
                internalType: 'contract IAccessControlManagerV8',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'accrualBlockNumber',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'accrueInterest',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'addAmount', type: 'uint256' },
        ],
        name: 'addReserves',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'address', name: 'spender', type: 'address' },
        ],
        name: 'allowance',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'badDebt',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'recoveredAmount_',
                type: 'uint256',
            },
        ],
        name: 'badDebtRecovered',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
        name: 'balanceOfUnderlying',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'blocksOrSecondsPerYear',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'borrowAmount', type: 'uint256' },
        ],
        name: 'borrow',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'borrowBalanceCurrent',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'borrowBalanceStored',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'borrower', type: 'address' },
            { internalType: 'uint256', name: 'borrowAmount', type: 'uint256' },
        ],
        name: 'borrowBehalf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'borrowIndex',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'borrowRatePerBlock',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'comptroller',
        outputs: [
            {
                internalType: 'contract ComptrollerInterface',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'decimals',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'spender', type: 'address' },
            {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256',
            },
        ],
        name: 'decreaseAllowance',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'exchangeRateCurrent',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'exchangeRateStored',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'liquidator', type: 'address' },
            { internalType: 'address', name: 'borrower', type: 'address' },
            { internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
            {
                internalType: 'contract VTokenInterface',
                name: 'vTokenCollateral',
                type: 'address',
            },
            { internalType: 'bool', name: 'skipLiquidityCheck', type: 'bool' },
        ],
        name: 'forceLiquidateBorrow',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'getAccountSnapshot',
        outputs: [
            { internalType: 'uint256', name: 'error', type: 'uint256' },
            { internalType: 'uint256', name: 'vTokenBalance', type: 'uint256' },
            { internalType: 'uint256', name: 'borrowBalance', type: 'uint256' },
            { internalType: 'uint256', name: 'exchangeRate', type: 'uint256' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getBlockNumberOrTimestamp',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getCash',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'payer', type: 'address' },
            { internalType: 'address', name: 'borrower', type: 'address' },
            { internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
        ],
        name: 'healBorrow',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
        ],
        name: 'increaseAllowance',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'underlying_', type: 'address' },
            {
                internalType: 'contract ComptrollerInterface',
                name: 'comptroller_',
                type: 'address',
            },
            {
                internalType: 'contract InterestRateModel',
                name: 'interestRateModel_',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'initialExchangeRateMantissa_',
                type: 'uint256',
            },
            { internalType: 'string', name: 'name_', type: 'string' },
            { internalType: 'string', name: 'symbol_', type: 'string' },
            { internalType: 'uint8', name: 'decimals_', type: 'uint8' },
            { internalType: 'address', name: 'admin_', type: 'address' },
            {
                internalType: 'address',
                name: 'accessControlManager_',
                type: 'address',
            },
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'shortfall',
                        type: 'address',
                    },
                    {
                        internalType: 'address payable',
                        name: 'protocolShareReserve',
                        type: 'address',
                    },
                ],
                internalType: 'struct VTokenInterface.RiskManagementInit',
                name: 'riskManagement',
                type: 'tuple',
            },
            {
                internalType: 'uint256',
                name: 'reserveFactorMantissa_',
                type: 'uint256',
            },
        ],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'interestRateModel',
        outputs: [
            {
                internalType: 'contract InterestRateModel',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'isTimeBased',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'isVToken',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'pure',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'borrower', type: 'address' },
            { internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
            {
                internalType: 'contract VTokenInterface',
                name: 'vTokenCollateral',
                type: 'address',
            },
        ],
        name: 'liquidateBorrow',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'mintAmount', type: 'uint256' },
        ],
        name: 'mint',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'minter', type: 'address' },
            { internalType: 'uint256', name: 'mintAmount', type: 'uint256' },
        ],
        name: 'mintBehalf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'pendingOwner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'protocolSeizeShareMantissa',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'protocolShareReserve',
        outputs: [
            { internalType: 'address payable', name: '', type: 'address' },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'redeemTokens', type: 'uint256' },
        ],
        name: 'redeem',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'redeemer', type: 'address' },
            { internalType: 'uint256', name: 'redeemTokens', type: 'uint256' },
        ],
        name: 'redeemBehalf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'redeemAmount', type: 'uint256' },
        ],
        name: 'redeemUnderlying',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'redeemer', type: 'address' },
            { internalType: 'uint256', name: 'redeemAmount', type: 'uint256' },
        ],
        name: 'redeemUnderlyingBehalf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'reduceAmount', type: 'uint256' },
        ],
        name: 'reduceReserves',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'reduceReservesBlockDelta',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'reduceReservesBlockNumber',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
        ],
        name: 'repayBorrow',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'borrower', type: 'address' },
            { internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
        ],
        name: 'repayBorrowBehalf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'reserveFactorMantissa',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'liquidator', type: 'address' },
            { internalType: 'address', name: 'borrower', type: 'address' },
            { internalType: 'uint256', name: 'seizeTokens', type: 'uint256' },
        ],
        name: 'seize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'accessControlManager_',
                type: 'address',
            },
        ],
        name: 'setAccessControlManager',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'contract InterestRateModel',
                name: 'newInterestRateModel',
                type: 'address',
            },
        ],
        name: 'setInterestRateModel',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'newProtocolSeizeShareMantissa_',
                type: 'uint256',
            },
        ],
        name: 'setProtocolSeizeShare',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address payable',
                name: 'protocolShareReserve_',
                type: 'address',
            },
        ],
        name: 'setProtocolShareReserve',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_newReduceReservesBlockOrTimestampDelta',
                type: 'uint256',
            },
        ],
        name: 'setReduceReservesBlockDelta',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'newReserveFactorMantissa',
                type: 'uint256',
            },
        ],
        name: 'setReserveFactor',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'shortfall_', type: 'address' },
        ],
        name: 'setShortfallContract',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'shortfall',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'supplyRatePerBlock',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'contract IERC20Upgradeable',
                name: 'token',
                type: 'address',
            },
        ],
        name: 'sweepToken',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'totalBorrows',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'totalBorrowsCurrent',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'totalReserves',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'dst', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'src', type: 'address' },
            { internalType: 'address', name: 'dst', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'transferFrom',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: 'newOwner', type: 'address' },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'underlying',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
] as const;

export const SYNCPOOL_ABI = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'Approval',
        type: 'event',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256',
            },
        ],
        name: 'Transfer',
        type: 'event',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'spender',
                type: 'address',
            },
        ],
        name: 'allowance',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'spender',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'approve',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'tokenIn',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amountIn',
                type: 'uint256',
            },
            {
                internalType: 'address',
                name: 'sender',
                type: 'address',
            },
        ],
        name: 'getAmountOut',
        outputs: [
            {
                internalType: 'uint256',
                name: '_amountOut',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getProtocolFee',
        outputs: [
            {
                internalType: 'uint24',
                name: '',
                type: 'uint24',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getReserves',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'invariantLast',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'bytes',
                name: '_data',
                type: 'bytes',
            },
            {
                internalType: 'address',
                name: '_sender',
                type: 'address',
            },
            {
                internalType: 'address',
                name: '_callback',
                type: 'address',
            },
            {
                internalType: 'bytes',
                name: '_callbackData',
                type: 'bytes',
            },
        ],
        name: 'swap',
        outputs: [
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'token',
                        type: 'address',
                    },
                    {
                        internalType: 'uint256',
                        name: 'amount',
                        type: 'uint256',
                    },
                ],
                internalType: 'struct ISyncPool.TokenAmount',
                name: '_tokenAmount',
                type: 'tuple',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [],
        name: 'token0',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'token1',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'totalSupply',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'transfer',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'from',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'transferFrom',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
] as const;
