/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export const getSanitizedApiUrl = (): string => {
    const apiUrl = process.env.REACT_APP_API_ROOT!;
    return apiUrl.replace(/\/$/, '');
};
