/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'api/queryKeys';
import type { ApiTokenDto, CustomQueryResult } from 'api/types';
import {
    ADAPTER_ABI,
    MAIN_ADDRESS,
    type PoolInfo,
    SYNCPOOL_ABI,
    VTOKEN_ABI,
    getTokenInfo,
    poolConfigs,
    poolToAdapterSuffix,
} from 'utils/clagg';
import {
    createPublicClient,
    decodeFunctionResult,
    encodeFunctionData,
    formatUnits,
} from 'viem';
import { zksync } from 'viem/zksync';
import { http } from 'wagmi';

export const useUsdPerShare = (
    tokens: Array<ApiTokenDto>,
): CustomQueryResult<Record<string, number>> => {
    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.SHARE_TO_TOKENS],
        queryFn: async () => {
            const usdPerShare: Record<string, number> = {};
            const publicClient = createPublicClient({
                chain: zksync,
                transport: http(),
            });

            for (const [pool, config] of Object.entries(poolConfigs)) {
                if (
                    config.adapter !== 'Aave' &&
                    config.adapter !== 'Venus' &&
                    config.adapter !== 'SyncSwap'
                ) {
                    continue;
                }
                const token = getTokenInfo(config.token, tokens);
                const adapterSuffix = poolToAdapterSuffix[pool].slice(2);

                const poolInfoCalldata =
                    encodeFunctionData({
                        abi: ADAPTER_ABI,
                        functionName: 'getPoolInfo',
                        args: [pool as `0x${string}`],
                    }) + adapterSuffix;

                const poolInfoResponse = await publicClient.call({
                    to: MAIN_ADDRESS as `0x${string}`,
                    data: poolInfoCalldata as `0x${string}`,
                });

                const decodedPoolInfo = decodeFunctionResult({
                    abi: ADAPTER_ABI,
                    functionName: 'getPoolInfo',
                    data: poolInfoResponse.data as `0x${string}`,
                }) as PoolInfo;

                const liquidityPerShare =
                    parseFloat(
                        formatUnits(
                            decodedPoolInfo.totalLiquidity,
                            config.decimals,
                        ),
                    ) /
                    parseFloat(
                        formatUnits(
                            decodedPoolInfo.totalSupply,
                            config.decimals,
                        ),
                    );

                const adapterName = config.adapter;
                switch (adapterName) {
                    case 'Venus': {
                        const exchangeRate = await publicClient.readContract({
                            address: pool as `0x${string}`,
                            abi: VTOKEN_ABI,
                            functionName: 'exchangeRateStored',
                        });

                        usdPerShare[pool] = token?.usd_price
                            ? liquidityPerShare *
                              parseFloat(
                                  formatUnits(
                                      exchangeRate,
                                      18 - (config.decimals - token.decimals),
                                  ),
                              ) *
                              token.usd_price
                            : 0;
                        break;
                    }
                    case 'Aave':
                        usdPerShare[pool] = token?.usd_price
                            ? liquidityPerShare * token.usd_price
                            : 0;
                        break;
                    case 'SyncSwap':
                        if (!config.otherToken) {
                            throw new Error('Other token is not defined');
                        }

                        const otherToken = getTokenInfo(
                            config.otherToken,
                            tokens,
                        );

                        // Get pool data in parallel for better performance
                        const [reserves, totalSupply] = await Promise.all([
                            publicClient.readContract({
                                address: pool as `0x${string}`,
                                abi: SYNCPOOL_ABI,
                                functionName: 'getReserves',
                            }),
                            publicClient.readContract({
                                address: pool as `0x${string}`,
                                abi: SYNCPOOL_ABI,
                                functionName: 'totalSupply',
                            }),
                        ]);

                        const [reserve0, reserve1] = reserves;

                        // Calculate share of each token's reserves
                        const getTokenShare = (reserve: bigint): number =>
                            (parseFloat(formatUnits(reserve, 6)) *
                                liquidityPerShare) /
                            parseFloat(formatUnits(totalSupply, 18));

                        // Calculate USD value for a token's share
                        const getUsdValue = (
                            tokenInfo: typeof token,
                            reserve: bigint,
                        ): number =>
                            tokenInfo?.usd_price
                                ? getTokenShare(reserve) * tokenInfo.usd_price
                                : 0;

                        // Get values based on token position
                        const tokenValue = getUsdValue(
                            token,
                            config.isToken0 ? reserve0 : reserve1,
                        );
                        const otherTokenValue = getUsdValue(
                            otherToken,
                            config.isToken0 ? reserve1 : reserve0,
                        );

                        usdPerShare[pool] = tokenValue + otherTokenValue;

                        break;
                    default:
                        break;
                }
            }

            return usdPerShare;
        },
        gcTime: Infinity,
        staleTime: Infinity,
    });

    return { data: data ?? {}, refetch: refetch, ...rest };
};
