/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Segmented, Spin, type TimeRangePickerProps } from 'antd';
import { useAllSwapsQuery } from 'api';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    type BarChartData,
    type BarChartProps,
    ChartInterval,
    FEE_PCT,
    NO_FEE_TIMESTAMP,
    RangePicker,
    addressToToken,
    dateFormat,
    formatUnits,
} from 'utils';

import { BarChart } from '../charts/BarChart';

export const SwapRevenue = ({
    isLoading: isLoadingProp,
    tokenPrices,
    defaultChartInterval = ChartInterval.Weekly,
    isPreview = false,
}: {
    isLoading: boolean;
    tokenPrices: Record<string, number> | null;
    defaultChartInterval?: ChartInterval;
    isPreview?: boolean;
}): $MixedElement => {
    const [dateRange, setDateRange] = useState<[number, number]>([0, 0]);
    const [chartInterval, setChartInterval] =
        useState<ChartInterval>(defaultChartInterval);
    type Key = 'eth' | 'usdc' | 'usdt' | 'meow' | 'zk' | 'other';

    const {
        data: { inAppSwaps: swaps } = { inAppSwaps: [] },
        isLoading: isLoadingSwaps,
    } = useAllSwapsQuery();
    const isLoading = isLoadingProp || isLoadingSwaps;

    const onDateChange: TimeRangePickerProps['onChange'] = (date) => {
        if (date && date[0] && date[1]) {
            setDateRange([date[0].unix(), date[1].unix()]);
            setChartInterval(ChartInterval.Daily);
        }
    };

    const chartData = useMemo(() => {
        const daily: Array<BarChartData<Key>> = [];
        const weekly: Array<BarChartData<Key>> = [];
        const monthly: Array<BarChartData<Key>> = [];
        const cumulative: Array<BarChartData<Key>> = [];

        if (!swaps || !tokenPrices) {
            return {
                [ChartInterval.Daily]: daily,
                [ChartInterval.Weekly]: weekly,
                [ChartInterval.Monthly]: monthly,
                [ChartInterval.Cumulative]: cumulative,
            };
        }

        // Group swaps by day
        const dailySwaps = new Map<string, Array<(typeof swaps)[0]>>();
        const weeklySwaps = new Map<string, Array<(typeof swaps)[0]>>();
        const monthlySwaps = new Map<string, Array<(typeof swaps)[0]>>();

        swaps.forEach((swap) => {
            const date = dayjs.unix(Number(swap.date));
            const dayKey = date.startOf('day').unix().toString();
            const weekKey = date.startOf('week').unix().toString();
            const monthKey = date.startOf('month').unix().toString();

            if (!dailySwaps.has(dayKey)) dailySwaps.set(dayKey, []);
            if (!weeklySwaps.has(weekKey)) weeklySwaps.set(weekKey, []);
            if (!monthlySwaps.has(monthKey)) monthlySwaps.set(monthKey, []);

            dailySwaps.get(dayKey)?.push(swap);
            weeklySwaps.get(weekKey)?.push(swap);
            monthlySwaps.get(monthKey)?.push(swap);
        });

        // Process daily data
        Array.from(dailySwaps.entries())
            .sort(([a], [b]) => Number(a) - Number(b))
            .forEach(([day, daySwaps]) => {
                if (
                    dateRange[0] !== 0 &&
                    (Number(day) < dateRange[0] || Number(day) > dateRange[1])
                ) {
                    return;
                }

                const dailyData: BarChartData<Key> = {
                    date: dayjs.unix(Number(day)).format('D MMM YYYY'),
                    eth: 0,
                    usdc: 0,
                    usdt: 0,
                    meow: 0,
                    zk: 0,
                    other: 0,
                };

                daySwaps.forEach((swap) => {
                    const tokenInAddress = swap.tokenIn.toLowerCase();
                    const tokenOutAddress = swap.tokenOut.toLowerCase();

                    const tokenIn = addressToToken[tokenInAddress];
                    const tokenOut = addressToToken[tokenOutAddress];
                    if (!tokenIn || !tokenOut) return;

                    const decimals = tokenOut.decimals;
                    const tokenPrice = tokenPrices[tokenOutAddress];
                    if (!tokenPrice) return;

                    // Skip if both tokens have noFee flag and it's after the no-fee timestamp
                    if (
                        Number(day) > NO_FEE_TIMESTAMP &&
                        tokenIn.noFee &&
                        tokenOut.noFee
                    ) {
                        return;
                    }

                    const amountOut = formatUnits(swap.amountOut, decimals);
                    const fee = amountOut * FEE_PCT;
                    const usdValue = fee * tokenPrice;

                    if (tokenOut.symbol === 'ETH') {
                        dailyData.eth += usdValue;
                    } else if (
                        tokenOut.symbol === 'USDC' ||
                        tokenOut.symbol === 'USDC.e'
                    ) {
                        dailyData.usdc += usdValue;
                    } else if (tokenOut.symbol === 'USDT') {
                        dailyData.usdt += usdValue;
                    } else if (tokenOut.symbol === 'MEOW') {
                        dailyData.meow += usdValue;
                    } else if (tokenOut.symbol === 'ZK') {
                        dailyData.zk += usdValue;
                    } else {
                        dailyData.other += usdValue;
                    }
                });

                daily.push(dailyData);
            });

        // Process weekly data
        Array.from(weeklySwaps.entries())
            .sort(([a], [b]) => Number(a) - Number(b))
            .forEach(([week, weekSwaps]) => {
                const weeklyData: BarChartData<Key> = {
                    date: dayjs.unix(Number(week)).format('D MMM YYYY'),
                    eth: 0,
                    usdc: 0,
                    usdt: 0,
                    meow: 0,
                    zk: 0,
                    other: 0,
                };

                const cumulativeData: BarChartData<Key> =
                    cumulative.length > 0
                        ? {
                              ...cumulative[cumulative.length - 1],
                              date: dayjs
                                  .unix(Number(week))
                                  .format('D MMM YYYY'),
                          }
                        : { ...weeklyData };

                weekSwaps.forEach((swap) => {
                    const tokenInAddress = swap.tokenIn.toLowerCase();
                    const tokenOutAddress = swap.tokenOut.toLowerCase();

                    const tokenIn = addressToToken[tokenInAddress];
                    const tokenOut = addressToToken[tokenOutAddress];
                    if (!tokenIn || !tokenOut) return;

                    const decimals = tokenOut.decimals;
                    const tokenPrice = tokenPrices[tokenOutAddress];
                    if (!tokenPrice) return;

                    // Skip if both tokens have noFee flag and it's after the no-fee timestamp
                    if (
                        Number(week) > NO_FEE_TIMESTAMP &&
                        tokenIn.noFee &&
                        tokenOut.noFee
                    ) {
                        return;
                    }

                    const amountOut = formatUnits(swap.amountOut, decimals);
                    const fee = amountOut * FEE_PCT;
                    const usdValue = fee * tokenPrice;

                    if (tokenOut.symbol === 'ETH') {
                        weeklyData.eth += usdValue;
                        cumulativeData.eth += usdValue;
                    } else if (
                        tokenOut.symbol === 'USDC' ||
                        tokenOut.symbol === 'USDC.e'
                    ) {
                        weeklyData.usdc += usdValue;
                        cumulativeData.usdc += usdValue;
                    } else if (tokenOut.symbol === 'USDT') {
                        weeklyData.usdt += usdValue;
                        cumulativeData.usdt += usdValue;
                    } else if (tokenOut.symbol === 'MEOW') {
                        weeklyData.meow += usdValue;
                        cumulativeData.meow += usdValue;
                    } else if (tokenOut.symbol === 'ZK') {
                        weeklyData.zk += usdValue;
                        cumulativeData.zk += usdValue;
                    } else {
                        weeklyData.other += usdValue;
                        cumulativeData.other += usdValue;
                    }
                });

                weekly.push(weeklyData);
                cumulative.push(cumulativeData);
            });

        // Process monthly data
        Array.from(monthlySwaps.entries())
            .sort(([a], [b]) => Number(a) - Number(b))
            .forEach(([month, monthSwaps]) => {
                const monthlyData: BarChartData<Key> = {
                    date: dayjs.unix(Number(month)).format('MMM YYYY'),
                    eth: 0,
                    usdc: 0,
                    usdt: 0,
                    meow: 0,
                    zk: 0,
                    other: 0,
                };

                monthSwaps.forEach((swap) => {
                    const tokenInAddress = swap.tokenIn.toLowerCase();
                    const tokenOutAddress = swap.tokenOut.toLowerCase();

                    const tokenIn = addressToToken[tokenInAddress];
                    const tokenOut = addressToToken[tokenOutAddress];
                    if (!tokenIn || !tokenOut) return;

                    const decimals = tokenOut.decimals;
                    const tokenPrice = tokenPrices[tokenOutAddress];
                    if (!tokenPrice) return;

                    // Skip if both tokens have noFee flag and it's after the no-fee timestamp
                    if (
                        Number(month) > NO_FEE_TIMESTAMP &&
                        tokenIn.noFee &&
                        tokenOut.noFee
                    ) {
                        return;
                    }

                    const amountOut = formatUnits(swap.amountOut, decimals);
                    const fee = amountOut * FEE_PCT;
                    const usdValue = fee * tokenPrice;

                    if (tokenOut.symbol === 'ETH') {
                        monthlyData.eth += usdValue;
                    } else if (
                        tokenOut.symbol === 'USDC' ||
                        tokenOut.symbol === 'USDC.e'
                    ) {
                        monthlyData.usdc += usdValue;
                    } else if (tokenOut.symbol === 'USDT') {
                        monthlyData.usdt += usdValue;
                    } else if (tokenOut.symbol === 'MEOW') {
                        monthlyData.meow += usdValue;
                    } else if (tokenOut.symbol === 'ZK') {
                        monthlyData.zk += usdValue;
                    } else {
                        monthlyData.other += usdValue;
                    }
                });

                monthly.push(monthlyData);
            });

        return {
            [ChartInterval.Daily]: daily,
            [ChartInterval.Weekly]: weekly.slice(-15),
            [ChartInterval.Monthly]: monthly,
            [ChartInterval.Cumulative]: cumulative.slice(-15),
        };
    }, [swaps, tokenPrices, dateRange]);

    const barProps: BarChartProps<Key> = {
        keys: ['eth', 'usdc', 'usdt', 'meow', 'zk', 'other'],
        indexBy: 'date',
        axisBottomLegend: 'Date',
        axisLeftLegend: 'USD',
        enableTotals: !isPreview,
        valueFormat: ' >-$.2f',
    };

    if (isLoading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }

    return (
        <div className="w-[100%] min-h-[40vh] max-h-[100vh]">
            {!isPreview && (
                <>
                    <Segmented
                        options={[
                            ChartInterval.Daily,
                            ChartInterval.Weekly,
                            ChartInterval.Monthly,
                            ChartInterval.Cumulative,
                        ]}
                        value={chartInterval}
                        defaultValue={defaultChartInterval}
                        onChange={(value): void => {
                            if (
                                value === ChartInterval.Daily &&
                                dateRange[0] === 0
                            ) {
                                setDateRange([
                                    dayjs().subtract(1, 'week').unix() - 80000,
                                    dayjs().unix(),
                                ]);
                            }
                            setChartInterval(value as ChartInterval);
                        }}
                        style={{ marginBottom: 12, marginLeft: 6 }}
                    />
                    <RangePicker
                        onChange={onDateChange}
                        value={
                            dateRange[0] === 0
                                ? undefined
                                : [
                                      dayjs.unix(dateRange[0]),
                                      dayjs.unix(dateRange[1]),
                                  ]
                        }
                        minDate={dayjs('2024-01-21', dateFormat)}
                        maxDate={dayjs()}
                        style={{ marginLeft: 24 }}
                    />
                </>
            )}
            <BarChart data={chartData[chartInterval]} props={barProps} />
        </div>
    );
};
