/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { CustomQueryResult } from 'api/types';
import { useEffect, useState } from 'react';
import { GraphQLClient } from 'utils/graphql';
import { claggSharesQuery } from 'utils/graphql/queries';

export type AccountPosition = {
    id: string;
    account: string;
    pool: string;
    shares: string;
    deposited: bigint;
    withdrawn: bigint;
};

type ClaggSharesResponse = {
    AccountPosition: Array<AccountPosition>;
};

export type ClaggSharesResult = {
    positions: Record<string, Record<string, string>>;
    gains: Record<string, Record<string, bigint>>;
};

const defaultData: ClaggSharesResult = {
    positions: {},
    gains: {},
};

const client = new GraphQLClient<ClaggSharesResponse>({
    endpoint: 'https://indexer.dev.hyperindex.xyz/3dea3d1/v1/graphql',
    pagination: {
        enabled: true,
    },
    defaultData: { AccountPosition: [] },
});

export const useClaggShares = (): CustomQueryResult<ClaggSharesResult> => {
    const [result, setResult] = useState<ClaggSharesResult>(defaultData);

    const queryResult = client.useQuery({
        gql: claggSharesQuery,
        gqlVariables: {},
        queryKey: ['clagg-shares'],
    });

    useEffect(() => {
        if (queryResult.data) {
            const positions = (queryResult.data.AccountPosition ?? []).reduce(
                (acc, position) => {
                    const account = position.account.toLowerCase();
                    acc[account] = acc[account] || {};
                    acc[account][position.pool] = position.shares;
                    return acc;
                },
                {} as Record<string, Record<string, string>>,
            );

            const gains = (queryResult.data.AccountPosition ?? []).reduce(
                (acc, position) => {
                    const account = position.account.toLowerCase();
                    acc[account] = acc[account] || {};
                    acc[account][position.pool] =
                        position.withdrawn - position.deposited > 0
                            ? position.withdrawn - position.deposited
                            : 0n;
                    return acc;
                },
                {} as Record<string, Record<string, bigint>>,
            );

            setResult({ positions, gains });
        }
    }, [queryResult.data]);

    return {
        ...queryResult,
        data: result,
        promise: queryResult.promise.then(() => result),
    };
};
