/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { ClaveData, TokenInfo } from 'api/types';

const formatAddress = (address: string, username: string | null): string => {
    return username || `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const formatAmount = (
    amount: string,
    tokenInfo: TokenInfo,
): {
    tokenAmount: string;
    usdAmount: string;
} => {
    const value = Number(amount) / Math.pow(10, tokenInfo.decimals);
    const usdValue = value * tokenInfo.usdPrice;

    return {
        tokenAmount: value.toLocaleString(undefined, {
            maximumFractionDigits: 4,
        }),
        usdAmount: usdValue.toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
        }),
    };
};

export const formatTransactionMessage = (
    data: ClaveData,
): {
    message: string;
    type: string;
    leftAvatar?: string | null;
    rightAvatar?: string | null;
} => {
    switch (data.type) {
        case 'transfer': {
            const { sender, recipient, amount, tokenInfo } = data.payload;
            const senderName = formatAddress(sender.address, sender.username);
            const recipientName = formatAddress(
                recipient.address,
                recipient.username,
            );
            const { tokenAmount, usdAmount } = formatAmount(amount, tokenInfo);

            const type =
                recipient.id && sender.id
                    ? 'transfer'
                    : sender.id
                    ? 'transfer_out'
                    : 'transfer_in';

            return {
                message: `${senderName} sent ${tokenAmount} ${tokenInfo.symbol} (${usdAmount}) to ${recipientName}`,
                type,
                leftAvatar:
                    sender.profilePicture || senderName.charAt(0).toUpperCase(),
                rightAvatar:
                    recipient.profilePicture ||
                    recipientName.charAt(0).toUpperCase(),
            };
        }

        case 'invest': {
            const { sender, amount, tokenInfo, type, protocol } = data.payload;
            const userName = formatAddress(sender.address, sender.username);
            const { tokenAmount, usdAmount } = formatAmount(amount, tokenInfo);

            if (type === 'claim') {
                return {
                    message: `${userName} claimed ${tokenAmount} ${tokenInfo.symbol} (${usdAmount}) from ${protocol}`,
                    type: 'invest',
                    leftAvatar:
                        sender.profilePicture ||
                        userName.charAt(0).toUpperCase(),
                    rightAvatar: `/protocols/${protocol.toLowerCase()}.png`,
                };
            }

            const action = type === 'withdraw' ? 'withdrew' : 'deposited';
            const preposition = type === 'withdraw' ? 'from' : 'into';

            return {
                message: `${userName} ${action} ${tokenAmount} ${tokenInfo.symbol} (${usdAmount}) ${preposition} ${protocol}`,
                type: 'invest',
                leftAvatar:
                    sender.profilePicture || userName.charAt(0).toUpperCase(),
                rightAvatar: `/protocols/${protocol.toLowerCase()}.png`,
            };
        }

        case 'swap': {
            const {
                sender,
                inputAmount,
                outputAmount,
                inputTokenInfo,
                outputTokenInfo,
            } = data.payload;
            const userName = formatAddress(sender.address, sender.username);

            const { usdAmount: inputUsdAmount } = formatAmount(
                inputAmount,
                inputTokenInfo,
            );
            const { usdAmount: outputUsdAmount } = formatAmount(
                outputAmount,
                outputTokenInfo,
            );

            return {
                message: `${userName} swapped ${inputUsdAmount} worth of ${inputTokenInfo.symbol} for ${outputUsdAmount} worth of ${outputTokenInfo.symbol}`,
                type: 'swap',
                leftAvatar:
                    sender.profilePicture || userName.charAt(0).toUpperCase(),
            };
        }

        case 'layerswap': {
            const { userAddress, amount, tokenInfo, type } = data.payload;
            const userName = formatAddress(
                userAddress.address,
                userAddress.username,
            );
            const { usdAmount } = formatAmount(amount, tokenInfo);

            const action = type === 'RECEIVE' ? 'received' : 'sent';

            return {
                message: `${userName} ${action} ${usdAmount} via Layerswap`,
                type: 'layerswap',
                leftAvatar:
                    userAddress.profilePicture ||
                    userName.charAt(0).toUpperCase(),
                rightAvatar:
                    userAddress.profilePicture ||
                    userName.charAt(0).toUpperCase(),
            };
        }

        case 'guardian_change': {
            const { wallet, guardian, operation } = data.payload;
            const userName = formatAddress(wallet.address, wallet.username);
            const guardianName = formatAddress(
                guardian.address,
                guardian.username,
            );

            const action = operation === 'ADD' ? 'added' : 'removed';

            return {
                message: `${userName} ${action} ${guardianName} as guardian`,
                type: 'guardian_change',
                leftAvatar:
                    wallet.profilePicture || userName.charAt(0).toUpperCase(),
                rightAvatar:
                    guardian.profilePicture ||
                    guardianName.charAt(0).toUpperCase(),
            };
        }

        case 'onramp': {
            const { userAddress, amount, tokenInfo } = data.payload;
            const userName = formatAddress(
                userAddress.address,
                userAddress.username,
            );
            const { usdAmount } = formatAmount(amount, tokenInfo);

            return {
                message: `${userName} bought ${usdAmount} via fiat onramp`,
                type: 'onramp',
                leftAvatar:
                    userAddress.profilePicture ||
                    userName.charAt(0).toUpperCase(),
            };
        }

        case 'peanut_deposit': {
            {
                const { depositAddress, amount, tokenInfo } = data.payload;
                const userName = formatAddress(
                    depositAddress.address,
                    depositAddress.username,
                );
                const { usdAmount } = formatAmount(amount, tokenInfo);

                return {
                    message: `${userName} created a Peanut Link worth ${usdAmount}`,
                    type: 'peanut',
                    leftAvatar:
                        depositAddress.profilePicture ||
                        userName.charAt(0).toUpperCase(),
                };
            }
        }

        case 'peanut_withdraw': {
            const { withdrawAddress, amount, tokenInfo } = data.payload;
            const userName = formatAddress(
                withdrawAddress.address,
                withdrawAddress.username,
            );
            const { usdAmount } = formatAmount(amount, tokenInfo);

            return {
                message: `${userName} claimed a Peanut Link worth ${usdAmount}`,
                type: 'peanut',
                leftAvatar:
                    withdrawAddress.profilePicture ||
                    userName.charAt(0).toUpperCase(),
            };
        }

        case 'offramp': {
            const { userAddress, amount, tokenInfo } = data.payload;
            const userName = formatAddress(
                userAddress.address,
                userAddress.username,
            );
            const { usdAmount } = formatAmount(amount, tokenInfo);

            return {
                message: `${userName} sold ${usdAmount} via fiat offramp`,
                type: 'offramp',
                leftAvatar:
                    userAddress.profilePicture ||
                    userName.charAt(0).toUpperCase(),
                rightAvatar: '/protocols/bank.png',
            };
        }

        case 'referral': {
            const { referred, referrer } = data.payload;
            const referredName = formatAddress(
                referred.address,
                referred.username,
            );
            const referrerName = formatAddress(referrer, referrer);

            return {
                message: `referrer: ${referrerName}`,
                type: 'referral',
                leftAvatar:
                    referred.profilePicture ||
                    referredName.charAt(0).toUpperCase(),
            };
        }

        default:
            return {
                message: `Unknown transaction type: ${
                    (data as { type: string }).type
                }`,
                type: 'unknown',
                leftAvatar: null,
                rightAvatar: null,
            };
    }
};
