/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { CustomQueryResult } from 'api/types';
import { GraphQLClient, allSwapsQuery } from 'utils/graphql';

type InAppSwap = {
    id: string;
    account: {
        id: string;
    };
    amountOut: string;
    tokenIn: string;
    tokenOut: string;
    date: string;
};

export type AllSwapsResponse = {
    inAppSwaps: Array<InAppSwap>;
};

const defaultData: AllSwapsResponse = {
    inAppSwaps: [],
};

const client = new GraphQLClient<AllSwapsResponse>({
    endpoint:
        'https://gateway-arbitrum.network.thegraph.com/api/f1a21c41eacde9cd3df99c25564d9a93/subgraphs/id/7JwxT3cEzAMyimELqYsnprJMFBqDjBaaEa9Mk39e7Fsk',
    pagination: {
        enabled: true,
    },
    sizeHint: 19000,
    defaultData,
});

export const useAllSwapsQuery = (): CustomQueryResult<AllSwapsResponse> => {
    const queryResult = client.useQuery({
        gql: allSwapsQuery,
        gqlVariables: {},
        queryKey: ['all-swaps'],
    });

    return queryResult;
};
