/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Editor } from '@monaco-editor/react';
import { Button, Row, Typography } from 'antd';
import { useGetEarnConfigMutation } from 'api/mutations/useGetEarnConfigMutation';
import { useUpdateEarnConfigMutation } from 'api/mutations/useUpdateEarnConfigMutation';
import { Layout, Sidebar } from 'components';
import { useNotification } from 'hooks';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';

export const EarnConfig = (): $MixedElement => {
    const [config, setConfig, isLoading] = useGetEarnConfigMutation();
    const mutation = useUpdateEarnConfigMutation();
    const [hasError, setHasError] = useState(false);
    const [show, contextHolder] = useNotification();

    const editorOptions = {
        height: '75vh',
        width: '100%',
        options: {
            fontSize: 14,
        },
    };

    const parsedJson = useMemo(() => {
        try {
            // Replace enum references with their string values
            const processedString = config
                // Remove semicolons
                .replace(/;/g, '')
                // Replace enum references with string literals
                .replace(/ClaveInvestPoolId\./g, '"')
                .replace(/ClaveInvestPoolType\./g, '"')
                .replace(/ClaveInvestProtocolName\./g, '"')
                .replace(/ClaveInvestPoolState\./g, '"')
                .replace(/ClaveInvestPoolOptionSize\./g, '"')
                .replace(/ClaveInvestPoolVisibility\./g, '"')
                .replace(/ClaveInvestPoolCategory\./g, '"')
                .replace(/ClaveInvestPoolGroup\./g, '"')
                .replace(/ClaveInvestApyCalculatorName\./g, '"')
                // Add closing quotes for enum values
                .replace(/([A-Z][A-Z0-9_]+)(,|\n|})/g, '$1"$2')
                // Replace getTokenAddress calls with empty strings (or you could map them to actual addresses)
                .replace(/getTokenAddress\(["'](.+?)["']\)/g, '"$1"')
                // Handle numeric expressions
                .replace(/(\d+)\s*\/\s*(\d+)/g, (_, num, den) =>
                    (Number(num) / Number(den)).toString(),
                );

            const jsonString = processedString
                .replace(/'/g, '"') // Replace all single quotes with double quotes
                .replace(/([a-zA-Z0-9_]+): /g, ' "$1":') // Add quotes around property names
                .replace(/,(\s*[}\]])/g, '$1'); // Remove trailing commas

            return jsonString;
        } catch (error) {
            return '';
        }
    }, [config]);

    return (
        <Row wrap={false}>
            {contextHolder}
            <Sidebar />
            <Layout>
                <Typography.Title level={1}>Earn Config</Typography.Title>
                <Row>
                    <div className="w-[50%] flex-col">
                        <Typography.Title level={4} className="mb-4">
                            Javascript
                        </Typography.Title>
                        <Editor
                            {...editorOptions}
                            value={config}
                            language="javascript"
                            onChange={(e): void => {
                                if (e != null) {
                                    setConfig(e);
                                }
                            }}
                            options={{
                                ...editorOptions.options,
                                colorDecorators: false,
                            }}
                        />
                    </div>
                    <div
                        className={`w-[50%] flex-col ${
                            hasError
                                ? 'border-6 border-red-500 border-dotted'
                                : ''
                        }`}
                    >
                        <Typography.Title level={4} className="mb-4">
                            JSON
                        </Typography.Title>
                        <Editor
                            {...editorOptions}
                            value={parsedJson}
                            language="json"
                            options={{
                                ...editorOptions.options,
                                readOnly: true,
                                renderValidationDecorations: 'on',
                            }}
                            onValidate={(e): void => {
                                if (e.length > 0) {
                                    setHasError(true);
                                } else {
                                    setHasError(false);
                                }
                            }}
                        />
                    </div>
                </Row>
                <Button
                    className="w-[200px] m-auto mt-4"
                    type="primary"
                    disabled={hasError || isLoading}
                    loading={mutation.isPending}
                    onClick={async (): Promise<void> => {
                        try {
                            const data = await mutation.mutateAsync({ config });
                            setConfig(data);
                            show(
                                'Config saved. It will take a few minutes to be applied.',
                            );
                        } catch (error) {
                            show('Something went wrong');
                        }
                    }}
                >
                    Save
                </Button>
            </Layout>
        </Row>
    );
};
