/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { ApiTokenDto } from 'api/types';

import { type AdapterName, adapterToName } from './constants';
import type { AdapterAddress } from './types';

export const getAdapterName = (address: `0x${string}`): AdapterName => {
    return adapterToName[address as AdapterAddress] ?? 'Unknown';
};

export const getTokenInfo = (
    address: string,
    tokens: Array<ApiTokenDto> = [],
): ApiTokenDto | undefined => {
    return tokens.find(
        (token) => token.address.toLowerCase() === address.toLowerCase(),
    );
};

export * from './constants';
export * from './abi';
export * from './types';
