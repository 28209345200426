/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createConfig, http } from 'wagmi';
import { zksync } from 'wagmi/chains';
import { walletConnect } from 'wagmi/connectors';

export const config = createConfig({
    chains: [zksync],
    transports: {
        [zksync.id]: http(),
    },
    connectors: [
        walletConnect({ projectId: 'cba0a4e4126c9d4b57792cec5a5ef41f' }),
    ],
    ssr: true,
});
