/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { apiGetDexScreener } from 'api';
import type { ApiTokenDto } from 'api/types';
import { useEffect, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    ADAPTER_ABI,
    type AdapterName,
    MAIN_ADDRESS,
    poolDecimals,
} from 'utils/clagg';
import type {
    DexScreenerResponse,
    IncentiveConfig,
    UserInfo,
} from 'utils/clagg/types';
import {
    decodeFunctionResult,
    encodeFunctionData,
    formatUnits,
    isAddress,
    zeroAddress,
} from 'viem';
import { usePublicClient } from 'wagmi';

import type {
    PoolConfig,
    PoolInfo,
    RewardConfig,
} from '../../../utils/clagg/types';
import { formatTokenAmount, getTokenInfo } from './GetPools';

function formatAddress(address: string): string {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

function formatUSD(value: number, decimals = 0): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: decimals,
    }).format(value);
}

function SwapPoolCard({
    poolAddress,
    label,
}: {
    poolAddress: string;
    label: string;
}): $MixedElement {
    const [data, setData] = useState<DexScreenerResponse | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        async function fetchData(): Promise<void> {
            if (!poolAddress || poolAddress === zeroAddress) return;

            try {
                setIsLoading(true);
                setError(null);
                const response = await apiGetDexScreener(poolAddress);
                if (response.data.pair) {
                    setData(response.data);
                }
            } catch (err) {
                console.error('Error fetching DEX data:', err);
                setError('Failed to fetch pool liquidity data');
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [poolAddress]);

    return (
        <div className="bg-white rounded-lg p-3">
            <div className="flex items-center justify-between mb-2">
                <div className="text-xs font-medium text-gray-500 uppercase tracking-wide">
                    {label}
                </div>
                {isLoading ? (
                    <div className="text-xs text-gray-400">Loading...</div>
                ) : error ? (
                    <div className="text-xs text-red-500">{error}</div>
                ) : data?.pair?.liquidity?.usd ? (
                    <div className="text-xs font-medium text-gray-900">
                        {'Liquidity: '}
                        {formatUSD(data.pair.liquidity.usd)}
                    </div>
                ) : null}
            </div>
            <div className="flex items-center gap-2">
                <div className="min-w-0 flex-1">
                    <div className="mt-1 flex items-center gap-2">
                        <code
                            className="text-xs bg-gray-50 px-2 py-1 rounded font-mono truncate flex-1"
                            title={poolAddress}
                        >
                            {formatAddress(poolAddress)}
                        </code>
                        <button
                            className="text-xs text-blue-600 hover:text-blue-700 flex-shrink-0"
                            onClick={async () =>
                                navigator.clipboard.writeText(poolAddress)
                            }
                            title="Copy address"
                        >
                            Copy
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function PoolInfoCard({
    info,
    poolAddress,
    adapterName,
    tokenInfo,
    exchangeRate,
}: {
    info: PoolInfo;
    poolAddress: string;
    adapterName: AdapterName;
    tokenInfo?: ApiTokenDto;
    exchangeRate: bigint;
}): $MixedElement {
    const decimals = poolDecimals[poolAddress] ?? 18;

    let usdValue = 0;

    switch (adapterName) {
        case 'Venus':
            usdValue = tokenInfo?.usd_price
                ? parseFloat(
                      formatUnits(info.totalLiquidity, tokenInfo.decimals),
                  ) *
                  parseFloat(formatUnits(exchangeRate, 18)) *
                  tokenInfo.usd_price
                : 0;
            break;
        case 'Aave':
            usdValue = tokenInfo?.usd_price
                ? parseFloat(formatUnits(info.totalLiquidity, decimals)) *
                  tokenInfo.usd_price
                : 0;
            break;
        case 'SyncSwap':
            usdValue = tokenInfo?.usd_price
                ? parseFloat(
                      formatUnits(info.totalLiquidity, tokenInfo.decimals),
                  ) *
                  parseFloat(formatUnits(exchangeRate, 18)) *
                  tokenInfo.usd_price
                : 0;
            break;
        default:
            break;
    }

    const formattedTotalSupply = parseFloat(
        formatUnits(info.totalSupply, decimals),
    );
    const usdValuePerShare =
        formattedTotalSupply === 0 ? 0 : usdValue / formattedTotalSupply;

    return (
        <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1">
                        Total Liquidity
                    </div>
                    <div className="text-lg font-semibold text-gray-900">
                        {formatTokenAmount(info.totalLiquidity, decimals)}
                    </div>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1">
                        Total Shares
                    </div>
                    <div className="text-lg font-semibold text-gray-900">
                        {formatTokenAmount(info.totalSupply, decimals)}
                    </div>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1">
                        Total USD Value
                    </div>
                    <div className="text-lg font-semibold text-gray-900">
                        {formatUSD(usdValue)}
                    </div>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1">
                        USD Value Per Share
                    </div>
                    <div className="text-lg font-semibold text-gray-900">
                        {formatUSD(usdValuePerShare, 2)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function PoolConfigCard({
    config,
    token,
}: {
    config: PoolConfig;
    token?: ApiTokenDto;
}): $MixedElement {
    return (
        <div className="space-y-4">
            <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-3">
                    Token Information
                </div>
                <div className="flex items-center gap-3">
                    <div
                        className={`h-10 w-10 flex-shrink-0 rounded-full ${
                            token?.icon ? '' : 'bg-blue-100'
                        } flex items-center justify-center`}
                    >
                        {token?.icon ? (
                            <img
                                src={token.icon}
                                alt={token.symbol}
                                className="w-8 h-8 rounded-full"
                            />
                        ) : (
                            <svg
                                className="w-5 h-5 text-blue-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                                />
                            </svg>
                        )}
                    </div>
                    <div className="min-w-0 flex-1">
                        <div className="font-medium text-gray-900 truncate">
                            {token?.name || 'Unknown Token'}
                            {token?.symbol && (
                                <span className="ml-1 text-gray-500">
                                    ({token.symbol})
                                </span>
                            )}
                        </div>
                        <div className="mt-1 flex items-center gap-2">
                            <code
                                className="text-xs bg-white px-2 py-1 rounded font-mono truncate flex-1"
                                title={config.token}
                            >
                                {formatAddress(config.token)}
                            </code>
                            <button
                                className="text-xs text-blue-600 hover:text-blue-700 flex-shrink-0"
                                onClick={async () =>
                                    navigator.clipboard.writeText(config.token)
                                }
                                title="Copy address"
                            >
                                Copy
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-2">
                        Performance Fee
                    </div>
                    <div className="flex items-center">
                        <span className="text-lg font-semibold text-gray-900">
                            {config.performanceFee / 100}%
                        </span>
                        <svg
                            className="w-4 h-4 text-gray-400 ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                            />
                        </svg>
                    </div>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-2">
                        Non-Clave Fee
                    </div>
                    <div className="flex items-center">
                        <span className="text-lg font-semibold text-gray-900">
                            {config.nonClaveFee / 100}%
                        </span>
                        <svg
                            className="w-4 h-4 text-gray-400 ml-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function RewardConfigCard({
    configs,
    tokens,
}: {
    configs: Array<RewardConfig>;
    tokens: Array<ApiTokenDto>;
}): $MixedElement {
    if (configs.length === 0) {
        return (
            <div className="text-center py-8 bg-gray-50 rounded-lg">
                <div className="h-12 w-12 mx-auto mb-3 rounded-full bg-gray-200 flex items-center justify-center">
                    <svg
                        className="w-6 h-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                </div>
                <h3 className="text-sm font-medium text-gray-900 mb-1">
                    No Reward Configurations
                </h3>
                <p className="text-sm text-gray-500">
                    This pool currently has no reward settings.
                </p>
            </div>
        );
    }

    return (
        <div className="space-y-4">
            {configs.map((config, index) => {
                const rewardToken = getTokenInfo(config.reward, tokens);
                const intermediateToken =
                    config.intermediateToken !== zeroAddress
                        ? getTokenInfo(config.intermediateToken, tokens)
                        : undefined;

                return (
                    <div
                        key={index}
                        className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors duration-200"
                    >
                        <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center gap-3">
                                <div
                                    className={`h-10 w-10 rounded-full ${
                                        rewardToken?.icon ? '' : 'bg-green-100'
                                    } flex items-center justify-center`}
                                >
                                    {rewardToken?.icon ? (
                                        <img
                                            src={rewardToken.icon}
                                            alt={rewardToken.symbol}
                                            className="w-8 h-8 rounded-full"
                                        />
                                    ) : (
                                        <svg
                                            className="w-5 h-5 text-green-600"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    )}
                                </div>
                                <div>
                                    <div className="text-sm font-medium text-gray-900">
                                        Reward Config #{index + 1}
                                    </div>
                                    <div className="text-xs text-gray-500">
                                        Min Amount:{' '}
                                        <span className="font-medium">
                                            {formatTokenAmount(
                                                config.minAmountOut,
                                                rewardToken?.decimals,
                                                rewardToken?.symbol,
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-3">
                            <div className="bg-white rounded-lg p-3">
                                <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-2">
                                    Reward Token
                                </div>
                                <div className="flex items-center gap-2">
                                    {rewardToken?.icon && (
                                        <img
                                            src={rewardToken.icon}
                                            alt={rewardToken.symbol}
                                            className="w-6 h-6 rounded-full"
                                        />
                                    )}
                                    <div className="min-w-0 flex-1">
                                        <div className="text-sm font-medium text-gray-900 truncate">
                                            {rewardToken?.name ||
                                                'Unknown Token'}
                                            {rewardToken?.symbol && (
                                                <span className="ml-1 text-gray-500">
                                                    ({rewardToken.symbol})
                                                </span>
                                            )}
                                        </div>
                                        <div className="mt-1 flex items-center gap-2">
                                            <code
                                                className="text-xs bg-gray-50 px-2 py-1 rounded font-mono truncate flex-1"
                                                title={config.reward}
                                            >
                                                {formatAddress(config.reward)}
                                            </code>
                                            <button
                                                className="text-xs text-blue-600 hover:text-blue-700 flex-shrink-0"
                                                onClick={async () =>
                                                    navigator.clipboard.writeText(
                                                        config.reward,
                                                    )
                                                }
                                                title="Copy address"
                                            >
                                                Copy
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {intermediateToken && (
                                <div className="bg-white rounded-lg p-3">
                                    <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-2">
                                        Intermediate Token
                                    </div>
                                    <div className="flex items-center gap-2">
                                        {intermediateToken.icon && (
                                            <img
                                                src={intermediateToken.icon}
                                                alt={intermediateToken.symbol}
                                                className="w-6 h-6 rounded-full"
                                            />
                                        )}
                                        <div className="min-w-0 flex-1">
                                            <div className="text-sm font-medium text-gray-900 truncate">
                                                {intermediateToken.name ||
                                                    'Unknown Token'}
                                                {intermediateToken.symbol && (
                                                    <span className="ml-1 text-gray-500">
                                                        (
                                                        {
                                                            intermediateToken.symbol
                                                        }
                                                        )
                                                    </span>
                                                )}
                                            </div>
                                            <div className="mt-1 flex items-center gap-2">
                                                <code
                                                    className="text-xs bg-gray-50 px-2 py-1 rounded font-mono truncate flex-1"
                                                    title={
                                                        config.intermediateToken
                                                    }
                                                >
                                                    {formatAddress(
                                                        config.intermediateToken,
                                                    )}
                                                </code>
                                                <button
                                                    className="text-xs text-blue-600 hover:text-blue-700 flex-shrink-0"
                                                    onClick={async () =>
                                                        navigator.clipboard.writeText(
                                                            config.intermediateToken,
                                                        )
                                                    }
                                                    title="Copy address"
                                                >
                                                    Copy
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {config.swapPool1 !== zeroAddress && (
                                <SwapPoolCard
                                    poolAddress={config.swapPool1}
                                    label="Swap Pool 1"
                                />
                            )}

                            {config.swapPool2 !== zeroAddress && (
                                <SwapPoolCard
                                    poolAddress={config.swapPool2}
                                    label="Swap Pool 2"
                                />
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export function IncentiveConfigCard({
    config,
    tokens,
}: {
    config: IncentiveConfig;
    tokens: Array<ApiTokenDto>;
}): $MixedElement {
    if (config.remaining === 0n) {
        return (
            <div className="text-center py-8 bg-gradient-to-br from-gray-50 to-gray-100 rounded-xl border border-gray-200">
                <div className="h-14 w-14 mx-auto mb-4 rounded-xl bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center shadow-sm">
                    <svg
                        className="w-7 h-7 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                    </svg>
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    No Active Incentives
                </h3>
                <p className="text-sm text-gray-500 max-w-sm mx-auto">
                    This pool currently has no active incentive rewards. Check
                    back later for new incentive programs.
                </p>
            </div>
        );
    }

    const tokenInfo = getTokenInfo(config.token, tokens);
    const now = Date.now();
    const expiryDate = Number(config.expiry) * 1000;
    const isExpired = now > expiryDate;
    const timeLeft = expiryDate - now;
    const daysLeft = Math.max(0, Math.floor(timeLeft / (1000 * 60 * 60 * 24)));
    const hoursLeft = Math.max(
        0,
        Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    );

    return (
        <div className="space-y-4">
            <div className="bg-white rounded-xl p-4 shadow-sm">
                <div className="flex items-center gap-3">
                    <div
                        className={`h-12 w-12 rounded-xl ${
                            tokenInfo?.icon
                                ? ''
                                : 'bg-gradient-to-br from-purple-100 to-purple-200'
                        } flex items-center justify-center shadow-sm`}
                    >
                        {tokenInfo?.icon ? (
                            <img
                                src={tokenInfo.icon}
                                alt={tokenInfo.symbol}
                                className="w-10 h-10 rounded-lg"
                            />
                        ) : (
                            <svg
                                className="w-6 h-6 text-purple-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M13 10V3L4 14h7v7l9-11h-7z"
                                />
                            </svg>
                        )}
                    </div>
                    <div>
                        <div className="text-sm text-gray-500">
                            Remaining Rewards
                        </div>
                        <div className="text-xl font-bold text-gray-900">
                            {formatTokenAmount(
                                config.remaining,
                                tokenInfo?.decimals,
                                tokenInfo?.symbol,
                                tokenInfo?.symbol === 'ETH' ? 6 : 2,
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div className="bg-white p-4 rounded-xl shadow-sm">
                    <div className="text-sm text-gray-500 mb-1">
                        Time Remaining
                    </div>
                    <div
                        className={`text-lg font-semibold ${
                            isExpired ? 'text-red-600' : 'text-gray-900'
                        }`}
                    >
                        {isExpired ? (
                            'Expired'
                        ) : (
                            <>
                                {daysLeft}d {hoursLeft}h
                            </>
                        )}
                    </div>
                    <div className="text-xs text-gray-400 mt-1">
                        Expires: {new Date(expiryDate).toLocaleDateString()}
                    </div>
                </div>
                <div className="bg-white p-4 rounded-xl shadow-sm">
                    <div className="text-sm text-gray-500 mb-1">
                        Last Updated
                    </div>
                    <div className="text-lg font-semibold text-gray-900">
                        {new Date(
                            Number(config.lastUpdatedAt) * 1000,
                        ).toLocaleDateString()}
                    </div>
                    <div className="text-xs text-gray-400 mt-1">
                        {new Date(
                            Number(config.lastUpdatedAt) * 1000,
                        ).toLocaleTimeString()}
                    </div>
                </div>
            </div>

            <div className="bg-white rounded-xl p-4 shadow-sm">
                <div className="space-y-3">
                    <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
                        {tokenInfo?.icon && (
                            <img
                                src={tokenInfo.icon}
                                alt={tokenInfo.symbol}
                                className="w-6 h-6 rounded-full"
                            />
                        )}
                        <div className="min-w-0 flex-1">
                            <div className="text-sm font-medium text-gray-900 truncate">
                                {tokenInfo?.name || 'Unknown Token'}
                                {tokenInfo?.symbol && (
                                    <span className="ml-1 text-gray-500">
                                        ({tokenInfo.symbol})
                                    </span>
                                )}
                            </div>
                            <div className="mt-1 flex items-center gap-2">
                                <code
                                    className="text-xs bg-white px-2 py-1 rounded font-mono truncate flex-1"
                                    title={config.token}
                                >
                                    {formatAddress(config.token)}
                                </code>
                                <button
                                    className="text-xs text-purple-600 hover:text-purple-700 flex-shrink-0 transition-colors"
                                    onClick={async () =>
                                        navigator.clipboard.writeText(
                                            config.token,
                                        )
                                    }
                                    title="Copy address"
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                    </div>

                    {config.swapPool !== zeroAddress && (
                        <div className="p-3 bg-gray-50 rounded-lg">
                            <div className="text-sm font-medium text-gray-900 mb-2">
                                Swap Pool
                            </div>
                            <div className="flex items-center gap-2">
                                <code
                                    className="text-xs bg-white px-2 py-1 rounded font-mono truncate flex-1"
                                    title={config.swapPool}
                                >
                                    {formatAddress(config.swapPool)}
                                </code>
                                <button
                                    className="text-xs text-purple-600 hover:text-purple-700 flex-shrink-0 transition-colors"
                                    onClick={async () =>
                                        navigator.clipboard.writeText(
                                            config.swapPool,
                                        )
                                    }
                                    title="Copy address"
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export function UserInfoCard({
    poolAddress,
    adapterAddress,
    token,
}: {
    poolAddress: `0x${string}`;
    adapterAddress: `0x${string}`;
    token?: ApiTokenDto;
}): $MixedElement {
    const publicClient = usePublicClient();
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [userAddress, setUserAddress] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isValidAddress, setIsValidAddress] = useState(false);
    const tokenDecimal = token?.decimals ?? 18;
    const poolDecimal = poolDecimals[poolAddress] ?? 18;

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        const value = e.target.value;
        setUserAddress(value);
        setError(null);
        setIsValidAddress(isAddress(value));
    };

    const fetchUserInfo = async (): Promise<void> => {
        if (!poolAddress || !publicClient || !isValidAddress) return;

        try {
            setIsLoading(true);
            setError(null);

            const userInfoCalldata =
                encodeFunctionData({
                    abi: ADAPTER_ABI,
                    functionName: 'getUserInfo',
                    args: [userAddress as `0x${string}`, poolAddress],
                }) + adapterAddress.slice(2);

            const userInfoResponse = await publicClient.call({
                to: MAIN_ADDRESS as `0x${string}`,
                data: userInfoCalldata as `0x${string}`,
            });

            const decodedUserInfo = decodeFunctionResult({
                abi: ADAPTER_ABI,
                functionName: 'getUserInfo',
                data: userInfoResponse.data as `0x${string}`,
            }) as UserInfo;

            setUserInfo(decodedUserInfo);
        } catch (err) {
            console.error('Error fetching user info:', err);
            setError('Failed to fetch user information');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="space-y-4">
            <div className="bg-white rounded-lg p-4 border border-gray-200">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        fetchUserInfo();
                    }}
                    className="space-y-4"
                >
                    <div className="relative group">
                        <input
                            type="text"
                            value={userAddress}
                            onChange={handleInputChange}
                            placeholder="Enter user address (0x...)"
                            className={`w-full pl-10 pr-4 py-2.5 border rounded-lg transition-all duration-200 ${
                                error
                                    ? 'border-red-300 bg-red-50 focus:ring-red-500 focus:border-red-500'
                                    : isValidAddress
                                    ? 'border-green-300 bg-green-50 focus:ring-green-500 focus:border-green-500'
                                    : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                            } text-sm`}
                        />
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <svg
                                className={`h-5 w-5 transition-colors duration-200 ${
                                    error
                                        ? 'text-red-400'
                                        : isValidAddress
                                        ? 'text-green-400'
                                        : 'text-gray-400'
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                            </svg>
                        </div>
                        {userAddress && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                {error ? (
                                    <svg
                                        className="h-5 w-5 text-red-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                ) : isValidAddress ? (
                                    <svg
                                        className="h-5 w-5 text-green-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                ) : null}
                            </div>
                        )}
                    </div>

                    <button
                        type="submit"
                        disabled={!isValidAddress || isLoading}
                        className={`w-full flex items-center justify-center px-4 py-2 rounded-lg text-white transition-all duration-200 ${
                            !isValidAddress || isLoading
                                ? 'bg-blue-400 cursor-not-allowed'
                                : 'bg-blue-600 hover:bg-blue-700 transform hover:scale-[1.02]'
                        } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                    >
                        {isLoading ? (
                            <>
                                <svg
                                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                </svg>
                                Fetching Info...
                            </>
                        ) : (
                            'Get User Info'
                        )}
                    </button>
                </form>
            </div>

            {error && (
                <div className="p-4 bg-red-50 text-red-700 rounded-lg text-sm">
                    <div className="flex items-center">
                        <svg
                            className="w-5 h-5 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                        {error}
                    </div>
                </div>
            )}

            {userInfo && (
                <div className="grid grid-cols-2 gap-4">
                    <div className="bg-gray-50 p-4 rounded-lg">
                        <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1">
                            Balance
                        </div>
                        <div className="text-lg font-semibold text-gray-900">
                            {formatTokenAmount(userInfo.balanceOf, poolDecimal)}
                        </div>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                        <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1">
                            Deposited
                        </div>
                        <div className="text-lg font-semibold text-gray-900">
                            {formatTokenAmount(
                                userInfo.deposited,
                                tokenDecimal,
                            )}
                        </div>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                        <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1">
                            Withdrawn
                        </div>
                        <div className="text-lg font-semibold text-gray-900">
                            {formatTokenAmount(
                                userInfo.withdrawn,
                                tokenDecimal,
                            )}
                        </div>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg">
                        <div className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1">
                            Gained
                        </div>
                        <div className="text-lg font-semibold text-gray-900">
                            {formatTokenAmount(userInfo.gained, tokenDecimal)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
