/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { CustomQueryResult } from 'api/types';
import { GraphQLClient, statsQuery } from 'utils/graphql';

type SwapData = {
    erc20: string;
    amount: string;
};

type InvestFlowData = {
    erc20: string;
    protocol: string;
    amountIn: string;
    amountOut: string;
    claimedGain: string;
};

export type StatsData = {
    id: string;
    createdAccounts: number;
    deployedAccounts: number;
    activeAccounts: number;
    transactions: number;
    gasSponsored: string;
    swappedTo: Array<SwapData>;
    investFlow: Array<InvestFlowData>;
};

type TotalStats = {
    createdAccounts: number;
    deployedAccounts: number;
    gasSponsored: string;
    transactions: number;
    backedUp: number;
};

export type StatsResponse = {
    days: Array<StatsData>;
    weeks: Array<StatsData>;
    months: Array<StatsData>;
    total: TotalStats | null;
};

const defaultData: StatsResponse = {
    days: [],
    weeks: [],
    months: [],
    total: null,
};

const client = new GraphQLClient<StatsResponse>({
    endpoint:
        'https://gateway-arbitrum.network.thegraph.com/api/f1a21c41eacde9cd3df99c25564d9a93/subgraphs/id/7JwxT3cEzAMyimELqYsnprJMFBqDjBaaEa9Mk39e7Fsk',
    pagination: {
        enabled: true,
    },
    defaultData,
});

export const useStatsQuery = (): CustomQueryResult<StatsResponse> => {
    const queryResult = client.useQuery({
        gql: statsQuery,
        gqlVariables: {},
        queryKey: ['stats'],
    });

    return queryResult;
};
