/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
    Col,
    InputNumber,
    Row,
    Segmented,
    Slider,
    Spin,
    type TimeRangePickerProps,
} from 'antd';
import { useClaggTxs } from 'api';
import type { ClaggTx, ClaggTxsResult } from 'api/query/envio/useClaggTxs';
import type { StatsResponse } from 'api/query/graph/useStatsQuery';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    type BarChartData,
    type BarChartProps,
    ChartInterval,
    ETH_ADDRESS,
    GRAPH_ETH_ADDRESS,
    RangePicker,
    bytesToDate,
    dateFormat,
    formatUnits,
    getDays,
    symbolToAddress,
    tokenAddressToDecimal,
} from 'utils';
import { poolToAdapterName, poolToToken } from 'utils/clagg';

import { BarChart } from '../charts/BarChart';

const poolToProtocol = (pool: string): string => {
    const adapter = poolToAdapterName[pool];
    switch (adapter) {
        case 'Aave':
            return 'Clagg_Aave';
        case 'Venus':
            return 'Clagg_Venus';
        default:
            return 'Unknown';
    }
};

type Key =
    | 'koi'
    | 'clave'
    | 'syncswap'
    | 'zerolend'
    | 'venus'
    | 'meow'
    | 'clagg_venus'
    | 'clagg_aave';

type ChartDataType = Record<ChartInterval, Array<BarChartData<Key>>>;

const CHART_KEYS: Array<Key> = [
    'koi',
    'clave',
    'syncswap',
    'zerolend',
    'meow',
    'venus',
    'clagg_venus',
    'clagg_aave',
];

// Memoize static chart props
const getBaseBarProps = (scale: number): BarChartProps<Key> => ({
    keys: CHART_KEYS,
    indexBy: 'date',
    colors: { scheme: 'set3' },
    axisBottomLegend: 'Date',
    axisLeftLegend: 'USD',
    enableTotals: true,
    valueFormat: (value) =>
        `$${value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })}`,
    groupMode: 'stacked' as const,
    logScale: {
        type: 'symlog',
        constant: scale,
    },
    legends: [
        {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemOpacity: 1,
                    },
                },
            ],
        },
    ],
});

// Memoize initial data structure
const getInitialChartData = (): ChartDataType => ({
    [ChartInterval.Daily]: [],
    [ChartInterval.Weekly]: [],
    [ChartInterval.Monthly]: [],
    [ChartInterval.Cumulative]: [],
});

interface InvestFlow {
    erc20: string;
    amountIn: string;
    amountOut: string;
    protocol: string;
}

const processInvestFlow = (
    flow: InvestFlow,
    tokenPrices: Record<string, number>,
): { tokenAddress: string; value: number } => {
    let tokenAddress = flow.erc20.toLowerCase();
    if (tokenAddress === GRAPH_ETH_ADDRESS) tokenAddress = ETH_ADDRESS;
    const decimal = tokenAddressToDecimal[tokenAddress] ?? 18;
    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
    const inflow = formatUnits(flow.amountIn, decimal);
    const inflowUsdc = inflow * tokenPrice;
    const outflow = formatUnits(flow.amountOut, decimal);
    const outflowUsdc = outflow * tokenPrice;
    return { tokenAddress, value: inflowUsdc - outflowUsdc };
};

const processClaggTx = (
    tx: ClaggTx,
    tokenPrices: Record<string, number>,
): {
    tokenAddress: string;
    protocol: string;
    value: number;
    timestamp: number;
} => {
    let tokenAddress = poolToToken[tx.pool].toLowerCase();
    if (tokenAddress === GRAPH_ETH_ADDRESS) tokenAddress = ETH_ADDRESS;
    const protocol = poolToProtocol(tx.pool);
    const decimal = tokenAddressToDecimal[tokenAddress] ?? 18;
    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
    const value = formatUnits(tx.amount, decimal);
    const valueUsdc = value * tokenPrice * (tx.type === 'deposit' ? 1 : -1);
    return {
        tokenAddress,
        protocol,
        value: valueUsdc,
        timestamp: Number(tx.timestamp),
    };
};

const createEmptyData = (date: string): BarChartData<Key> => ({
    date: bytesToDate(date),
    koi: 0,
    clave: 0,
    zerolend: 0,
    syncswap: 0,
    meow: 0,
    venus: 0,
    clagg_venus: 0,
    clagg_aave: 0,
});

const processProtocolData = (
    data: BarChartData<Key>,
    protocol: string,
    tokenAddress: string,
    value: number,
): void => {
    switch (protocol) {
        case 'Koi':
            data.koi += value;
            break;
        case 'Clave':
            data.clave += value;
            break;
        case 'ZeroLend':
            switch (tokenAddress) {
                case symbolToAddress['DAI']:
                    data.zerolend += value;
                    break;
                case symbolToAddress['USDT']:
                    data.zerolend += value;
                    break;
                case symbolToAddress['USDC.e']:
                    data.zerolend += value;
                    break;
                default:
                    data.zerolend += value;
                    break;
            }
            break;
        case 'SyncSwap':
            data.syncswap += value;
            break;
        case 'Meow':
            data.meow += value;
            break;
        case 'Venus':
            switch (tokenAddress) {
                case symbolToAddress['USDT']:
                    data.venus += value;
                    break;
                case symbolToAddress['USDC.e']:
                    data.venus += value;
                    break;
            }
            break;
        case 'Clagg_Aave':
            data.clagg_aave += value;
            break;
        case 'Clagg_Venus':
            data.clagg_venus += value;
            break;
    }
};

const processClaggData = (
    data: BarChartData<Key>,
    claggTxs: ClaggTxsResult | undefined,
    tokenPrices: Record<string, number>,
    dataInterval: number,
): void => {
    if (!claggTxs) return;

    const dateTimestamp = new Date(data.date).getTime();
    const intervalEnd = dateTimestamp + dataInterval * 1000; // Convert interval seconds to ms

    claggTxs.transactions.forEach((tx) => {
        const txTimestamp = Number(tx.timestamp) * 1000; // Convert seconds to ms
        if (txTimestamp >= dateTimestamp && txTimestamp < intervalEnd) {
            const { tokenAddress, value, protocol } = processClaggTx(
                tx,
                tokenPrices,
            );

            processProtocolData(data, protocol, tokenAddress, value);
        }
    });
};

export const EarnStats = ({
    data,
    isLoading,
    tokenPrices,
    defaultChartInterval = ChartInterval.Weekly,
    isPreview = false,
}: {
    data: StatsResponse;
    isLoading: boolean;
    tokenPrices: Record<string, number> | null;
    defaultChartInterval?: ChartInterval;
    isPreview?: boolean;
}): $MixedElement => {
    const { data: claggTxs } = useClaggTxs();
    const [dateRange, setDateRange] = useState<[number, number]>([0, 0]);
    const [chartInterval, setChartInterval] =
        useState<ChartInterval>(defaultChartInterval);
    const [scale, setScale] = useState(10000);

    const handleIntervalChange = useCallback(
        (value: ChartInterval | string) => {
            if (value === ChartInterval.Daily && dateRange[0] === 0) {
                setDateRange([
                    dayjs().subtract(1, 'week').unix() - 80000,
                    dayjs().unix(),
                ]);
            }
            setChartInterval(value as ChartInterval);
        },
        [dateRange],
    );

    const onChange = useCallback((newValue: number | null) => {
        if (typeof newValue === 'number') setScale(newValue);
    }, []);

    const onDateChange = useCallback((dates: TimeRangePickerProps['value']) => {
        if (dates?.[0] && dates?.[1]) {
            setDateRange([dates[0].unix(), dates[1].unix()]);
            setChartInterval(ChartInterval.Daily);
        }
    }, []);

    const barProps = useMemo(() => getBaseBarProps(scale), [scale]);

    const chartData = useMemo(() => {
        if (!tokenPrices || !data) {
            return getInitialChartData();
        }

        const result = getInitialChartData();
        const days = getDays(data.days, dateRange);

        // Process daily data
        days.forEach((day) => {
            const dailyData = createEmptyData(day.id);

            day.investFlow.forEach((flow) => {
                const { tokenAddress, value } = processInvestFlow(
                    flow,
                    tokenPrices,
                );
                processProtocolData(
                    dailyData,
                    flow.protocol,
                    tokenAddress,
                    value,
                );
            });

            processClaggData(dailyData, claggTxs, tokenPrices, 86400);
            result[ChartInterval.Daily].push(dailyData);
        });

        // Process weekly data with windowing
        const weeklyWindow = data.weeks.slice(-15);
        weeklyWindow.forEach((week) => {
            const weeklyData = createEmptyData(week.id);

            week.investFlow.forEach((flow) => {
                const { tokenAddress, value } = processInvestFlow(
                    flow,
                    tokenPrices,
                );
                processProtocolData(
                    weeklyData,
                    flow.protocol,
                    tokenAddress,
                    value,
                );
            });

            processClaggData(weeklyData, claggTxs, tokenPrices, 604800);
            result[ChartInterval.Weekly].push(weeklyData);

            // Calculate cumulative data
            const prevCumulative =
                result[ChartInterval.Cumulative].length > 0
                    ? result[ChartInterval.Cumulative][
                          result[ChartInterval.Cumulative].length - 1
                      ]
                    : undefined;

            const cumulativeData: BarChartData<Key> = {
                date: bytesToDate(week.id),
                koi: (prevCumulative?.koi ?? 0) + weeklyData.koi,
                clave: (prevCumulative?.clave ?? 0) + weeklyData.clave,
                syncswap: (prevCumulative?.syncswap ?? 0) + weeklyData.syncswap,
                zerolend: (prevCumulative?.zerolend ?? 0) + weeklyData.zerolend,
                meow: (prevCumulative?.meow ?? 0) + weeklyData.meow,
                venus: (prevCumulative?.venus ?? 0) + weeklyData.venus,
                clagg_venus:
                    (prevCumulative?.clagg_venus ?? 0) + weeklyData.clagg_venus,
                clagg_aave:
                    (prevCumulative?.clagg_aave ?? 0) + weeklyData.clagg_aave,
            };

            result[ChartInterval.Cumulative].push(cumulativeData);
        });

        const monthlyWindow = data.months.slice(-2);
        monthlyWindow.forEach((month) => {
            const monthlyData = createEmptyData(month.id);

            month.investFlow.forEach((flow) => {
                const { tokenAddress, value } = processInvestFlow(
                    flow,
                    tokenPrices,
                );
                processProtocolData(
                    monthlyData,
                    flow.protocol,
                    tokenAddress,
                    value,
                );
            });

            // Process Clagg data after all invest flows
            processClaggData(monthlyData, claggTxs, tokenPrices, 2592000);
            result[ChartInterval.Monthly].push(monthlyData);
        });

        return result;
    }, [data, tokenPrices, dateRange, claggTxs]);

    if (isLoading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }

    return (
        <div className="w-[100%] min-h-[40vh] max-h-[100vh]">
            {!isPreview && (
                <Row>
                    <Col span={7}>
                        <Segmented
                            options={[
                                ChartInterval.Daily,
                                ChartInterval.Weekly,
                                ChartInterval.Monthly,
                                ChartInterval.Cumulative,
                            ]}
                            value={chartInterval}
                            defaultValue={defaultChartInterval}
                            onChange={handleIntervalChange}
                        />
                    </Col>
                    <Col span={7}>
                        <RangePicker
                            onChange={onDateChange}
                            value={
                                dateRange[0] === 0
                                    ? undefined
                                    : [
                                          dayjs.unix(dateRange[0]),
                                          dayjs.unix(dateRange[1]),
                                      ]
                            }
                            minDate={dayjs('2024-01-21', dateFormat)}
                            maxDate={dayjs()}
                        />
                    </Col>
                    <Col span={4}>
                        <Slider
                            min={1000}
                            max={100000}
                            onChange={onChange}
                            value={typeof scale === 'number' ? scale : 1}
                        />
                    </Col>
                    <Col span={2}>
                        <InputNumber
                            min={1}
                            max={1000000}
                            style={{ margin: '0 16px' }}
                            value={typeof scale === 'number' ? scale : 1}
                            onChange={onChange}
                        />
                    </Col>
                </Row>
            )}
            <BarChart data={chartData[chartInterval]} props={barProps} />
        </div>
    );
};
