/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { CustomQueryResult } from 'api/types';
import { GraphQLClient, allUsersStatsQuery } from 'utils/graphql';

type EarnPosition = {
    pool: string;
    token: string;
    protocol: string;
    invested: string;
    compoundGain: string;
    normalGain: string;
};

type TokenAmount = {
    erc20: string;
    amount: string;
};

type Transaction = {
    gasCost: string;
};

type ActivePeriod = {
    id: string;
};

type ClaveAccount = {
    id: string;
    creationDate: string;
    deployDate: string;
    hasRecovery: boolean;
    txCount: string;
    transactions: Array<Transaction>;
    activeDays: Array<ActivePeriod>;
    activeWeeks: Array<ActivePeriod>;
    activeMonths: Array<ActivePeriod>;
    earnPositions: Array<EarnPosition>;
    referralFees: Array<TokenAmount>;
    cashbacks: Array<TokenAmount>;
};

export type AllUsersStatsResponse = {
    claveAccounts: Array<ClaveAccount>;
};

const defaultData: AllUsersStatsResponse = {
    claveAccounts: [],
};

const client = new GraphQLClient<AllUsersStatsResponse>({
    endpoint:
        'https://gateway-arbitrum.network.thegraph.com/api/f1a21c41eacde9cd3df99c25564d9a93/subgraphs/id/7JwxT3cEzAMyimELqYsnprJMFBqDjBaaEa9Mk39e7Fsk',
    pagination: {
        enabled: true,
    },
    sizeHint: 23000,
    defaultData,
});

export const useAllUsersStatsQuery =
    (): CustomQueryResult<AllUsersStatsResponse> => {
        const queryResult = client.useQuery({
            gql: allUsersStatsQuery,
            gqlVariables: {},
            queryKey: ['all-users-stats'],
        });

        return queryResult;
    };
