/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useQuery } from '@tanstack/react-query';
import { apiGetTokens } from 'api';
import { queryKeys } from 'api/queryKeys';
import type { ApiTokenDto, CustomQueryResult } from 'api/types';

const emptyStats: Array<ApiTokenDto> = [];

export const useGetTokensQuery = (): CustomQueryResult<Array<ApiTokenDto>> => {
    const { data, refetch, ...rest } = useQuery({
        queryKey: [queryKeys.TOKENS],
        queryFn: async () => {
            const tokens = await apiGetTokens();
            return tokens.data;
        },
        gcTime: Infinity,
        staleTime: Infinity,
    });

    return { data: data ?? emptyStats, refetch: refetch, ...rest };
};
