/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export const queryKeys = {
    SCHEDULED_NOTIFICATIONS: 'scheduled-notifications',
    USER_STATS: 'user-stats',
    TOKEN_PRICES: 'token-prices',
    STATS: 'stats',
    ALL_SWAPS: 'all-swaps',
    BALANCES: 'balances',
    ALL_STATS: 'all-stats',
    IS_UPGRADED: 'is-upgraded',
    RAFFLES: 'raffles',
    ALL_STATS_DB: 'all-stats-db',
    ALL_TXS: 'all-txs',
    GATEKEEPERS: 'gatekeepers',
    GATEKEEPERS_BY_NAME: 'gatekeeper-by-name',
    ONRAMP_STATS_BY_DATE: 'onramp-stats-by-date',
    SNAPSHOTS: 'snapshots',
    TOKENS: 'tokens',
    SHARE_TO_TOKENS: 'share-to-tokens',
};
