/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { $MixedElement } from 'types';
import { useAccount, useConnect, useDisconnect } from 'wagmi';

export function Account(): $MixedElement {
    const { address, isConnected } = useAccount();
    const { disconnect } = useDisconnect();
    const { connectors, connect } = useConnect();

    return (
        <div className="bg-white border-b border-gray-200">
            <div className="max-w-[90rem] mx-auto px-8 py-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                        <div className="h-10 w-10 rounded-xl bg-gradient-to-br from-blue-100 to-blue-200 flex items-center justify-center">
                            <svg
                                className="w-5 h-5 text-blue-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                                />
                            </svg>
                        </div>
                        <div>
                            <h2 className="text-sm font-medium text-gray-600">
                                Wallet Status
                            </h2>
                            <div className="flex items-center gap-2 mt-1">
                                <div
                                    className={`w-2 h-2 rounded-full ${
                                        isConnected
                                            ? 'bg-green-500'
                                            : 'bg-gray-400'
                                    }`}
                                />
                                <span
                                    className={`text-sm font-medium ${
                                        isConnected
                                            ? 'text-green-600'
                                            : 'text-gray-500'
                                    }`}
                                >
                                    {isConnected
                                        ? 'Connected'
                                        : 'Not Connected'}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center gap-4">
                        {isConnected && address ? (
                            <>
                                <div className="flex items-center gap-3 px-4 py-2 bg-gray-50 rounded-lg">
                                    <div className="flex flex-col">
                                        <span className="text-xs text-gray-500">
                                            Address
                                        </span>
                                        <code className="text-sm font-mono text-gray-900">
                                            {`${address.slice(
                                                0,
                                                6,
                                            )}...${address.slice(-4)}`}
                                        </code>
                                    </div>
                                    <button
                                        onClick={async () =>
                                            navigator.clipboard.writeText(
                                                address,
                                            )
                                        }
                                        className="p-1.5 text-gray-400 hover:text-gray-600 transition-colors"
                                        title="Copy Address"
                                    >
                                        <svg
                                            className="w-4 h-4"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <button
                                    onClick={() => disconnect()}
                                    className="flex items-center gap-2 px-4 py-2 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg transition-all duration-200"
                                    title="Disconnect Wallet"
                                >
                                    <span className="text-sm font-medium">
                                        Disconnect
                                    </span>
                                    <svg
                                        className="w-4 h-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                        />
                                    </svg>
                                </button>
                            </>
                        ) : (
                            <div className="flex gap-2">
                                {connectors.map((connector) => (
                                    <button
                                        key={connector.uid}
                                        onClick={() => connect({ connector })}
                                        className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-200 font-medium text-sm"
                                    >
                                        <svg
                                            className="w-4 h-4"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M13 10V3L4 14h7v7l9-11h-7z"
                                            />
                                        </svg>
                                        Connect {connector.name}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
