/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Button, Row, Tabs, Typography } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import {
    useGetTokenPricesQuery,
    useGetTokensQuery,
    useIdleBalances,
    useStatsQuery,
} from 'api';
import {
    ActiveAccounts,
    AllStats,
    ChartPickerModal,
    EarnStats,
    ExportChartsModal,
    GasSponsored,
    Layout,
    NewAccounts,
    OnrampStats,
    PieCharts,
    RevenuePerActiveUser,
    Sidebar,
    SwapRevenue,
    TxCount,
} from 'components';
import { useModalController } from 'hooks/useModalController';
import { useEffect, useMemo, useState } from 'react';
import { useSetIsSidebarOpen } from 'store/SidebarStore';
import type { $MixedElement } from 'types';
import { type UserBalances, bytesToNumber, formatUnits, tabItems } from 'utils';
import { getTokenInfo } from 'utils/clagg';

type Tab =
    | 'newAccounts'
    | 'activeAccounts'
    | 'swapRevenue'
    | 'rpau'
    | 'txCount'
    | 'gasSponsored'
    | 'earnStats'
    | 'onrampStats'
    | 'pieCharts'
    | 'allStats';

export const DashboardV2 = (): $MixedElement => {
    const setIsSideBarOpen = useSetIsSidebarOpen();
    useEffect(() => {
        setIsSideBarOpen(false);
    }, []);

    const [tab, setTab] = useState<Tab>('newAccounts');
    const { data, isLoading } = useStatsQuery();
    const {
        data: { tokenPrices },
    } = useGetTokenPricesQuery();
    const { data: tokens } = useGetTokensQuery();
    const { data: idleBalances } = useIdleBalances();

    const pickModalController = useModalController();
    const exportModalController = useModalController();
    const [checkedCharts, setCheckedCharts] = useState<
        Array<CheckboxValueType>
    >([]);

    const onTabChange = (key: string): void => {
        setTab(key as Tab);
    };

    const orderedData = useMemo(() => {
        if (data == null) {
            return {
                days: [],
                weeks: [],
                months: [],
                total: null,
            };
        }

        const sortedDays = [...data.days].sort((a, b) => {
            return bytesToNumber(a.id) < bytesToNumber(b.id) ? -1 : 1;
        });
        const sortedWeeks = [...data.weeks].sort((a, b) => {
            return bytesToNumber(a.id) < bytesToNumber(b.id) ? -1 : 1;
        });
        const sortedMonths = [...data.months].sort((a, b) => {
            return bytesToNumber(a.id) < bytesToNumber(b.id) ? -1 : 1;
        });
        return {
            ...data,
            days: sortedDays,
            weeks: sortedWeeks,
            months: sortedMonths,
        };
    }, [data]);

    const orderedPrices = useMemo(() => {
        if (tokenPrices == null) {
            return null;
        }
        Object.keys(tokenPrices).forEach((key) => {
            const lowerKey = key.toLowerCase();
            if (lowerKey !== key) {
                tokenPrices[lowerKey] = tokenPrices[key];
                delete tokenPrices[key];
            }
        });
        return tokenPrices;
    }, [tokenPrices]);

    const [userBalances, userBalancesUSD] = useMemo(() => {
        if (!idleBalances || !tokenPrices) {
            return [null, null];
        }

        const userBalances: UserBalances = {};
        idleBalances.balances.forEach((balance) => {
            const address = balance.address;
            const tokenAddress = balance.token.id;
            const token = getTokenInfo(tokenAddress, tokens);
            if (!userBalances[address]) {
                userBalances[address] = {};
            }
            userBalances[address][tokenAddress] = formatUnits(
                balance.balance,
                token?.decimals ?? 18,
            );
        });

        const userBalancesUSD: UserBalances = {};
        Object.entries(userBalances).forEach(([address, balances]) => {
            userBalancesUSD[address] = {};
            Object.entries(balances).forEach(([token, balance]) => {
                const tokenInfo = getTokenInfo(token, tokens);
                const price = tokenInfo?.usd_price ?? 0;
                userBalancesUSD[address][token] = balance * price;
            });
        });

        return [userBalances, userBalancesUSD];
    }, [idleBalances, tokens]);

    return (
        <Row wrap={false}>
            <ChartPickerModal
                modalController={pickModalController}
                exportModalController={exportModalController}
                setCharts={setCheckedCharts}
            />
            <ExportChartsModal
                modalController={exportModalController}
                charts={checkedCharts}
                data={orderedData}
                isLoading={isLoading}
                tokenPrices={orderedPrices}
            />
            <Sidebar />
            <Layout>
                <Row className="items-center" justify="space-between">
                    <Typography.Title className="mb-0" level={1}>
                        Clave Data Dashboard
                    </Typography.Title>
                    <Button
                        type="primary"
                        onClick={(): void => {
                            pickModalController.open();
                        }}
                    >
                        Export Charts
                    </Button>
                </Row>
                <>
                    <Tabs onChange={onTabChange} items={tabItems} />
                    {tab === 'newAccounts' ? (
                        <NewAccounts data={orderedData} isLoading={isLoading} />
                    ) : tab === 'activeAccounts' ? (
                        <ActiveAccounts
                            data={orderedData}
                            isLoading={isLoading}
                        />
                    ) : tab === 'swapRevenue' ? (
                        <SwapRevenue
                            isLoading={isLoading}
                            tokenPrices={orderedPrices}
                        />
                    ) : tab === 'rpau' ? (
                        <RevenuePerActiveUser
                            data={orderedData}
                            isLoading={isLoading}
                            tokenPrices={orderedPrices}
                        />
                    ) : tab === 'txCount' ? (
                        <TxCount data={orderedData} isLoading={isLoading} />
                    ) : tab === 'gasSponsored' ? (
                        <GasSponsored
                            data={orderedData}
                            isLoading={isLoading}
                            tokenPrices={orderedPrices}
                        />
                    ) : tab === 'earnStats' ? (
                        <EarnStats
                            data={orderedData}
                            isLoading={isLoading}
                            tokenPrices={orderedPrices}
                        />
                    ) : tab === 'onrampStats' ? (
                        <OnrampStats />
                    ) : tab === 'pieCharts' ? (
                        <PieCharts
                            data={orderedData}
                            isLoading={isLoading}
                            balancesUSD={userBalancesUSD}
                            tokenPrices={orderedPrices}
                        />
                    ) : (
                        <AllStats
                            tokenPrices={orderedPrices}
                            balancesUSD={userBalancesUSD}
                            balances={userBalances}
                            tokens={tokens}
                        />
                    )}
                </>
            </Layout>
        </Row>
    );
};
