/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMutation } from '@tanstack/react-query';
import { apiPostEarnConfig } from 'api';
import type { CustomAxiosError, CustomMutationResult } from 'api/types';

export const useUpdateEarnConfigMutation = (): CustomMutationResult<
    string,
    { config: string }
> => {
    const mutation = useMutation({
        mutationFn: async (data: { config: string }) => {
            const response = await apiPostEarnConfig(data);
            return response.data;
        },
        onError: (e: CustomAxiosError) => {
            console.log?.(e.response?.data.message ?? 'Something went wrong');
        },
    });

    return mutation;
};
